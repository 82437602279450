/* LESS Document => SCSS Document fgoriginal_h_f_r */

/************************************************
モバイルのブレイクポイント
************************************************ */
$breakpoint : 767px;

/************************************************
 各ページコンテンツ用
************************************************ */
.pc {
  @media only screen and (max-width: $breakpoint) {
    display: none !important;
  }
  @media only screen and (min-width: $breakpoint) {
    display: block !important;
  }
}
.sp {
  @media only screen and (max-width: $breakpoint) {
    display: block !important;
  }

  @media only screen and (min-width: $breakpoint) {
    display: none !important;
  }
}
.pc-inb {
  @media only screen and (max-width: $breakpoint) {
    display: none !important;
  }

  @media only screen and (min-width: $breakpoint) {
    display: inline-block !important;
  }
}
.sp-inb {
  @media only screen and (max-width: $breakpoint) {
    display: inline-block !important;
  }

  @media only screen and (min-width: $breakpoint) {
    display: none !important;
  }
}
.font12 {
    font-size: 12px !important;
}

.font14 {
    font-size: 14px !important;
}

.font16 {
    font-size: 16px !important;
}

.font18 {
    font-size: 18px !important;
}

.font20 {
    font-size: 20px !important;
}

.font22 {
    font-size: 22px !important;
}


.bold {
    font-weight: bold;
}

.text_l {
    text-align: left;
}

.text_c {
    text-align: center;
}

.text_r {
    text-align: right;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.ov-v {
    overflow: visible;
}

.i_b_reset {
    font-size: 0;
    letter-spacing: 0;
}

.flexbox {
    display: flex;
}
.line15em {
    line-height: 1.5em !important;
}
.line2em {
    line-height: 2em !important;
}
/* Margin
--------------------------------------------- */
.no-margin {
    margin: 0 !important;
}
@media only screen and (max-width: $breakpoint) {
  .spmb0 {
    margin-bottom: 0 !important;
  }
  .spmb10 {
    margin-bottom: 10px !important;
  }
  .spmb20 {
    margin-bottom: 20px !important;
  }
  .spmb30 {
    margin-bottom: 30px !important;
  }
  .spmb40 {
    margin-bottom: 40px !important;
  }
  .spmb45 {
    margin-bottom: 45px !important;
  }
  .spmb70 {
    margin-bottom: 70px !important;
  }
  .spmb60 {
    margin-bottom: 60px !important;
  }
  .spmt30{
    margin-top: 30px !important;
  }
  .spmt70 {
      margin-top: 70px !important;
  }
}
@media only screen and (min-width: $breakpoint) {
  .pcmt0 {
    margin-top: 0px !important;
  }
  .pcmt10 {
    margin-top: 10px !important;
  }
  .pcmt30 {
    margin-top: 30px !important;
  }
  .pcmt50 {
    margin-top: 50px !important;
  }
  .pcmt60 {
    margin-top: 60px !important;
  }
  .pcmt70 {
    margin-top: 70px !important;
  }
  .pcmt90 {
    margin-top: 90px !important;
  }
  
  .pcmb10 {
    margin-bottom: 10px !important;
  }
  .pcmb20 {
    margin-bottom: 20px !important;
  }
  .pcmb30 {
    margin-bottom: 30px !important;
  }
  .pcmb40 {
    margin-bottom: 40px !important;
  }
  .pcmb45 {
    margin-bottom: 45px !important;
  }
  .pcmb50 {
    margin-bottom: 50px !important;
  }
  .pcmb60 {
    margin-bottom: 60px !important;
  }
  .pcmb70 {
    margin-bottom: 70px !important;
  }
  .pcmb80{
    margin-bottom: 80px !important;
  }
  .pcmb90{
    margin-bottom: 90px !important;
  }
  .pcmb100{
    margin-bottom: 100px !important;
  }
  .pcmb110{
    margin-bottom: 110px !important;
  }
  .pcmb150{
    margin-bottom: 150px !important;
  }
  .pcmr30{
      margin-right: 30px !important;
  }
  .pcpl60 {
    padding-left: 60px !important;
  }
  .pcdynapad {
    padding: 60px 30px !important;
  }
}

.mt00 {
    margin-top: 0 !important;
}

.mt05 {
    margin-top: 5px !important;
}

.mt10 {
    margin-top: 10px !important;
}

.mt12 {
    margin-top: 12px !important;
}

.mt15 {
    margin-top: 15px !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt25 {
    margin-top: 25px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt35 {
    margin-top: 35px !important;
}

.mt40 {
    margin-top: 40px !important;
}

.mt45 {
    margin-top: 45px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mt60 {
    margin-top: 60px !important;
}

.mt70 {
    margin-top: 70px !important;
}

.mt80 {
    margin-top: 80px !important;
}

.mt90 {
    margin-top: 90px !important;
}

.mr00 {
    margin-right: 0 !important;
}

.mr05 {
    margin-right: 5px !important;
}

.mr10 {
    margin-right: 10px !important;
}

.mr12 {
    margin-right: 12px !important;
}

.mr15 {
    margin-right: 15px !important;
}

.mr20 {
    margin-right: 20px !important;
}

.mr25 {
    margin-right: 25px !important;
}

.mr30 {
    margin-right: 30px !important;
}

.mr35 {
    margin-right: 35px !important;
}

.mr40 {
    margin-right: 40px !important;
}

.mr45 {
    margin-right: 45px !important;
}

.mr50 {
    margin-right: 50px !important;
}

.mb2em{
    margin-bottom: 2em;
}

.mb00 {
    margin-bottom: 0 !important;
}

.mb05 {
    margin-bottom: 5px !important;
}

.mb10 {
    margin-bottom: 10px !important;
}

.mb12 {
    margin-bottom: 12px !important;
}

.mb15 {
    margin-bottom: 15px !important;
}

.mb20 {
    margin-bottom: 20px !important;
}

.mb25 {
    margin-bottom: 25px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb35 {
    margin-bottom: 35px !important;
}

.mb40 {
    margin-bottom: 40px !important;
}

.mb45 {
    margin-bottom: 45px !important;
}

.mb50 {
    margin-bottom: 50px !important;
}

.mb55 {
    margin-bottom: 55px !important;
}

.mb60 {
    margin-bottom: 60px !important;
}

.mb65 {
    margin-bottom: 65px !important;
}

.mb70 {
    margin-bottom: 70px !important;
}

.mb75 {
    margin-bottom: 75px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.mb85 {
    margin-bottom: 85px !important;
}

.mb90 {
    margin-bottom: 90px !important;
}

.mb95 {
    margin-bottom: 95px !important;
}

.mb100 {
    margin-bottom: 100px !important;
}

.mb105 {
    margin-bottom: 105px !important;
}

.mb110 {
    margin-bottom: 110px !important;
}
.mb115 {
    margin-bottom: 115px !important;
}
.mb120 {
    margin-bottom: 120px !important;
}
.mb130 {

}


.ml00 {
    margin-left: 0 !important;
}

.ml05 {
    margin-left: 5px !important;
}

.ml10 {
    margin-left: 10px !important;
}

.ml12 {
    margin-left: 12px !important;
}

.ml15 {
    margin-left: 15px !important;
}

.ml20 {
    margin-left: 20px !important;
}

.ml25 {
    margin-left: 25px !important;
}

.ml30 {
    margin-left: 30px !important;
}

.ml35 {
    margin-left: 35px !important;
}

.ml40 {
    margin-left: 40px !important;
}

.ml45 {
    margin-left: 45px !important;
}

.ml50 {
    margin-left: 50px !important;
}


/* Padding
--------------------------------------------- */
.no-padding {
    padding: 0 !important;
}

.pt00 {
    padding-top: 0 !important;
}

.pt05 {
    padding-top: 5px !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pt12 {
    padding-top: 12px !important;
}

.pt15 {
    padding-top: 15px !important;
}

.pt20 {
    padding-top: 20px !important;
}

.pt25 {
    padding-top: 25px !important;
}

.pt30 {
    padding-top: 30px !important;
}

.pt35 {
    padding-top: 35px !important;
}

.pt40 {
    padding-top: 40px !important;
}

.pt45 {
    padding-top: 45px !important;
}

.pt50 {
    padding-top: 50px !important;
}

.pr00 {
    padding-right: 0 !important;
}

.pr05 {
    padding-right: 5px !important;
}

.pr10 {
    padding-right: 10px !important;
}

.pr12 {
    padding-right: 12px !important;
}

.pr15 {
    padding-right: 15px !important;
}

.pr20 {
    padding-right: 20px !important;
}

.pr25 {
    padding-right: 25px !important;
}

.pr30 {
    padding-right: 30px !important;
}

.pr35 {
    padding-right: 35px !important;
}

.pr40 {
    padding-right: 40px !important;
}

.pr45 {
    padding-right: 45px !important;
}

.pr50 {
    padding-right: 50px !important;
}

.pb00 {
    padding-bottom: 0 !important;
}

.pb05 {
    padding-bottom: 5px !important;
}

.pb10 {
    padding-bottom: 10px !important;
}

.pb12 {
    padding-bottom: 12px !important;
}

.pb15 {
    padding-bottom: 15px !important;
}

.pb20 {
    padding-bottom: 20px !important;
}

.pb25 {
    padding-bottom: 25px !important;
}

.pb30 {
    padding-bottom: 30px !important;
}

.pb35 {
    padding-bottom: 35px !important;
}

.pb40 {
    padding-bottom: 40px !important;
}

.pb45 {
    padding-bottom: 45px !important;
}

.pb50 {
    padding-bottom: 50px !important;
}

.pl00 {
    padding-left: 0 !important;
}

.pl05 {
    padding-left: 5px !important;
}

.pl10 {
    padding-left: 10px !important;
}

.pl12 {
    padding-left: 12px !important;
}

.pl15 {
    padding-left: 15px !important;
}

.pl20 {
    padding-left: 20px !important;
}

.pl25 {
    padding-left: 25px !important;
}

.pl30 {
    padding-left: 30px !important;
}

.pl35 {
    padding-left: 35px !important;
}

.pl40 {
    padding-left: 40px !important;
}

.pl45 {
    padding-left: 45px !important;
}

.pl50 {
    padding-left: 50px !important;
}

#svgicon {
    display: block;
    width: 0;
    height: 0;
    overflow: hidden;
}

.cb {
    display: inline-block;
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
}

.cb-images {
    width: 1.125em;
}

.icon-bg {
    fill: #d4d4d5;
}

.icon-with-bg {
    fill: #fff;
}

.container-fluid {
    @media only screen and (max-width: $breakpoint) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 8px;
        padding-right: 8px;
    }

    @media only screen and (min-width: $breakpoint) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 8px;
        padding-right: 8px;
    }

}

.row {
    @media only screen and (max-width: $breakpoint) {
        margin-left: -8px;
        margin-right: -8px;
    //    padding-bottom: 10px;
    }

    @media only screen and (min-width: $breakpoint) {
        margin-left: -8px;
        margin-right: -8px;
    //    padding-bottom: 10px;
    }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    @media only screen and (max-width: $breakpoint) {}

   // margin-left: -8px !important;
   // margin-right: -8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;

    @media only screen and (min-width: $breakpoint) {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

}

/* モバイルできるまでとりあえず処理
.col-sm-6 {
    width: 50% !important;
    float: left !important;
}
　*/
/* 共通　フォームのナビ色とフォーカス時文字を消す　*/
input::-webkit-input-placeholder {
    color: #999;
}

input:-moz-placeholder {
    color: #999;
}

input::-moz-placeholder {
    color: #999;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

/* --------------------------------
基本
-------------------------------- */
.gotoTop {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 30px;
  @media only screen and (max-width: $breakpoint) {
    right: 10px;
    bottom: 10px;
  }
  width: 43px;
  height: 43px;
  border: 2px solid #ffcc00;
  border-radius: 10px;
  background-color: #fff;
  line-height: 43px;
  text-align: center;
  font-size: 23px;
  color: #ffcc00;
  cursor: pointer;
  opacity: 0.777;
  &:hover {
    opacity: 1;
  }
}
.cam-fbnr {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 30px;
  transform: translateY(160px);
  transition: 1s;
  z-index: 10000;
  @media only screen and (max-width: $breakpoint) {
  right: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  background: #fff;
  }
  .cam-fclose-btn {
    position: absolute;
    right: -15px;
    top: -15px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: #444444;
    display: inline-block;
    border-radius: 30px;
    color: #fff;
    cursor:pointer;
    @media only screen and (max-width: $breakpoint) {
      right: 0px;
      top: -15px;
    }
    &:hover {
      background-color: #666;
    }
  }
  a {
    display: block;
    border: 5px solid #fc0;
  }
}
html {
    font-size: 62.5%;

    /* 62.5%を指定すると「1.0 rem = 10px」 */
    body {
        @media only screen and (max-width: $breakpoint) {
            min-width: 100%;
        }

        @media only screen and (min-width: $breakpoint) {
            min-width: 1216px;
        }

        font-size: 12px;
        font-size: 1.2rem;
        font-family: "Heebo",
        "Sawarabi Gothic",
        "ヒラギノ角ゴPro W3",
        "Hiragino Kaku Gothic Pro",
        "游ゴシック",
        "Yu Gothic",
        "游ゴシック体",
        "YuGothic",
        "メイリオ",
        "Meiryo",
        "sans-serif";
        color: #444;

        br {
            letter-spacing: normal;
            /* ← IE6、IE7対策 */
        }

        a {
            color: #35aae7;
            text-decoration: none;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
                color: #35aae7;
                text-decoration: none;
                outline: none;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
        }

        button {

            &:focus,
            &:active:focus {
                outline: none;
            }
        }

        .btn {

            &:focus,
            &:active:focus {
                outline: none;
            }
        }

        textarea {
            /* for chrome fontsize bug */
            font-family: sans-serif;
        }

        ul,
        ol {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        dl,
        dt,
        dd,
        li {
            margin: 0;
            padding: 0;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        .price {
            display: inline-block;
            font-size: 30px;
            font-weight: bold;
            margin-left: 5px;
            margin-right: 5px;

            span {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }
}

/* --------------------------------
	ヘッダーブロック
-------------------------------- */
#header {
    /* -------------- */
    @media only screen and (max-width: $breakpoint) {
        min-width: 100%;
        overflow: hidden;
    }

    @media only screen and (min-width: $breakpoint) {
        min-width: 1216px;
    }

    background-color: #fff;
    font-family: "Heebo",
    "Sawarabi Gothic",
    "ヒラギノ角ゴPro W3",
    "Hiragino Kaku Gothic Pro",
    "游ゴシック",
    "Yu Gothic",
    "游ゴシック体",
    "YuGothic",
    "メイリオ",
    "Meiryo",
    "sans-serif";
    color: #444;

    a {
        color: #35aae7;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            color: #0099e8;
            text-decoration: none;
            outline: none;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
    }

    button {

        &:focus,
        &:active:focus {
            outline: none;
        }
    }

    .btn {

        &:focus,
        &:active:focus {
            outline: none;
        }
    }

    textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif;
    }

    .inner {
        width: 100%;
        max-width: 100%
    }

    /* -------------- */

    .row {
        padding-bottom: 0px;
    }

    .memver_navi {
        background-color: #444;
        color: #ddd;
        height: 37px;
        line-height: 37px;
        font-size: 12px;
        font-size: 1.2rem;

        p {
            width: auto;
            margin: 0;
            padding-left: 50px;
            display: inline-block;
            vertical-align: top;

            .name {
                font-weight: bold;
                margin-left: 1em;
                margin-right: 0.5em;
            }
        }

        .member_btnlist {
            display: inline-block;
            width: auto;
            float: right;
            vertical-align: top;

            &:before {
                clear: both;
            }

            ul {
                font-size: 0;
                letter-spacing: 0;
                height: 37px;

                li {
                    display: inline-block;
                    font-size: 12px;
                    border-left: 1px solid #6d6d6d;

                    a {
                        display: block;
                        padding: 0 50px;
                        font-size: 12px;
                        color: #ddd;

                        &:hover {
                            color: #999;
                        }
                    }

                }

                .reg {
                    background-color: #ffcc00;

                    a {
                        color: #444;
                    }
                }
            }
        }
    }

    //memver_navi
    .navi {
        @media only screen and (max-width: $breakpoint) {
            padding-bottom: 0px;
            padding: 8px 0;
            border-bottom: 1px solid #ddd;
            height: 64px;
            padding-right: 56px;
            position: relative;
        }

        @media only screen and (min-width: $breakpoint) {
            height: 107px;
            padding-bottom: 0px;
            height: 107px;
            padding: 15px 0;
        }


        .col-sm-6 {
            height: 100%;
            display: block;
            padding: 0;
            font-size: 0;
            letter-spacing: 0;

            .header_logo_area {
                height: 100%;
                width: 100%;
                display: block;
                font-size: 0;
                letter-spacing: 0;

                h1 {
                    @media only screen and (max-width: $breakpoint) {
                        margin: 0;
                        display: inline-block;
                        margin-left: 8px;
                    }

                    @media only screen and (min-width: $breakpoint) {
                      margin: 0;
                      line-height: 48px;
                      display: inline-block;
                      font-size: 24px;
                      text-align: center;
                      margin-left: 43px;
                    }

                }

                .desctext {
                    @media only screen and (max-width: $breakpoint) {
                        display: block;
                        font-size: 10px;
                        font-size: 1rem;
                        padding-top: 3px;
                        vertical-align: top;
                        -webkit-font-smoothing: auto;
                        transform: rotate(0.05deg);
                        text-shadow: 0 0 0.1px rgba(51, 51, 51, 0.5);
                        span {
                          display: inline-block;
                          overflow: hidden;
                          height: 28px;
                          max-width: 120px;
                          vertical-align: top;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                          text-align: left;
                        }
                    }

                    @media only screen and (min-width: $breakpoint) {
                      display: inline-block;
                      margin-left: 20px;
                      font-size: 12px;
                      font-size: 1.2rem;
                      vertical-align: top;
                      font-weight: normal;
                      span {
                        display: inline-block;
                        overflow: hidden;
                        height: 28px;
                        max-width: 200px;
                        vertical-align: top;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        text-align: left;
                      }
                    }

                }
            }

            .header_right_area {
                text-align: right;
            }

            .header_bottom_area {
                display: inline-block;
                text-align: left;
            }

            .search {
                form {
                    .search_inner {
                        width: 254px;
                        height: 48px;
                        background: #f2f2f2;
                        border-radius: 48px;
                        font-size: 0;
                        letter-spacing: 0;

                        input {
                            border-radius: 48px 0 0 48px;
                            width: 180px;
                            font-size: 12px;
                            height: 48px;
                            background: #f2f2f2;
                            border: 0;
                            margin: 0 0 0 20px;
                            padding: 0 2px;
                            font-size: 12px;
                            vertical-align: top;
                            display: inline-block;
                            box-shadow: none !important;

                            &:focus {
                                outline: none;
                                box-shadow: none !important;
                            }

                            &:-webkit-autofill {
                                -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
                            }
                        }

                        button {
                            width: 50px;
                            height: 48px;
                            border-radius: 0 48px 48px 0;
                            border: none;
                            background: #f2f2f2;
                            padding: 0;
                            margin: 0;
                            font-size: 15px;
                            color: #999;

                            &:focus {
                                outline: none;
                            }

                            &:hover {
                                color: #444;
                                -webkit-transition: all 0.3s ease;
                                -moz-transition: all 0.3s ease;
                                -o-transition: all 0.3s ease;
                                transition: all 0.3s ease;
                            }
                        }

                    }
                }
            }

            //searchend
            .support_btn {
                display: inline-block;
                position: relative;
                width: 146px;
                height: 48px;
                margin-left: 20px;
                padding: 8px 0;
                vertical-align: top;
                color: #444;

                i {
                    font-size: 22px;
                    position: absolute;
                    top: 9px;
                    left: 0;
                }

                span:nth-of-type(1) {
                    font-size: 10px;
                    display: block;
                }

                span:nth-of-type(2) {
                    font-size: 14px;
                    display: block;
                    letter-spacing: 0.13em;
                }

            }

            ul {
                display: inline-block;
                text-align: left;
                margin-right: 20px;
                height: 48px;
                vertical-align: top;
                padding: 9px 0;

                li {
                    display: inline-block;
                    text-align: left;
                    line-height: 30px;
                    height: 30px;

                    &:first-child {
                        border-right: 1px solid #999;
                    }

                    a {
                        padding: 0 30px;
                        font-size: 22px;
                        color: #999;

                        &:hover {
                            color: #444;
                        }

                        .cart_icon {
                            position: relative;
                        }

                    }

                    .badge {
                        background: #e22d1f;
                        width: 18px;
                        height: 18px;
                        font-size: 10px;
                        border-radius: 15px;
                        color: #fff;
                        display: block;
                        position: absolute;
                        top: -7px;
                        right: -7px;
                        text-align: center;
                        line-height: 14px;
                        overflow: hidden;
                        animation: show 0.3s both;
                    }

                    @keyframes show {
                        0% {
                            top: 0;
                            right: 0;
                            width: 0;
                            height: 0;
                        }

                        100% {
                            top: -8px;
                            right: -8px;
                            width: 18px;
                            height: 18px;
                        }
                    }
                }
            }
        }

        //col-sm-6end

    }

    .main_menu {
        height: 45px;
        border-bottom: 1px solid #ddd;

        nav {
            width: 1200px;
            margin: auto;
            display: block;
            height: 100%;

            .category_wrap {
                width: 100%;
                height: 100%;

                ul {
                    display: table;
                    table-layout: fixed;
                    text-align: center;
                    width: 100%;
                    height: 100%;

                    li {
                        display: table-cell;
                        position: relative;
                        height: 100%;

                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            display: inline-block;
                            width: 1px;
                            height: 30px;
                            background-color: #ddd;
                        }

                        &:last-child:after {
                            content: '';
                            position: absolute;
                            right: 0;
                            top: 0;
                            display: inline-block;
                            width: 1px;
                            height: 30px;
                            background-color: #ddd;
                        }

                        a {
                            font-size: 13px;
                            font-weight: bold;
                            color: #999;
                            display: block;
                            line-height: 30px;
                            height: 44px;

                            &:after {
                                content: '';
                                z-index: 100;
                                position: absolute;
                                right: 51%;
                                left: 51%;
                                bottom: -1px;
                                display: inline-block;
                                height: 7px;
                                background-color: #ffcc00;
                                -webkit-transition-property: left, right;
                                transition-property: left, right;
                                -webkit-transition-duration: 0.3s;
                                transition-duration: 0.3s;
                                -webkit-transition-timing-function: ease-out;
                                transition-timing-function: ease-out;
                            }

                            &:hover {
                                color: #444;

                                &:after {
                                    right: 0;
                                    left: 0;
                                }
                            }

                            &.active {
                                color: #444;

                                &:after {
                                    right: 0;
                                    left: 0;
                                    -webkit-transition-property: left, right;
                                    transition-property: left, right;
                                    -webkit-transition-duration: 0s;
                                    transition-duration: 0s;
                                    -webkit-transition-timing-function: ease-out;
                                    transition-timing-function: ease-out;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/* --------------------------------
	フッター
-------------------------------- */
#footer {

    #listcan_link{
        height: unset;
        line-height: unset;
        border-color: unset;
        background-color: unset;
        border: unset;
        color: #999;
        position: relative;
        &:hover{
            color: #444;
        }
    }

    /* -------------- */
    @media only screen and (max-width: $breakpoint) {
        min-width: 100%;
    }

    @media only screen and (min-width: $breakpoint) {
        min-width: 1216px;
    }

    font-size: 12px;
    font-size: 1.2rem;
    font-family: "Heebo",
    "Sawarabi Gothic",
    "ヒラギノ角ゴPro W3",
    "Hiragino Kaku Gothic Pro",
    "游ゴシック",
    "Yu Gothic",
    "游ゴシック体",
    "YuGothic",
    "メイリオ",
    "Meiryo",
    "sans-serif";
    color: #444;

    a {
        color: #35aae7;
        text-decoration: none;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            color: #0099e8;
            text-decoration: none;
            outline: none;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
        }
    }

    button {

        &:focus,
        &:active:focus {
            outline: none;
        }
    }

    .btn {

        &:focus,
        &:active:focus {
            outline: none;
        }
    }

    textarea {
        /* for chrome fontsize bug */
        font-family: sans-serif;
    }

    /* -------------- */
    .inner {
        @media only screen and (max-width: $breakpoint) {
            width: 100%;
            max-width: 100%;
        }

        @media only screen and (min-width: $breakpoint) {
            width: 1200px;
            max-width: 1216px;
        }

        padding: 0;
        margin: 0 auto;

        .footer_sns_logo_area {
          .fa-facebook {
            color: #3C5A99;
          }
          .fa-twitter {
            color: #1DA1F2;
          }
          @media only screen and (max-width: $breakpoint) {
            margin: 0;
            height: 30px;
            font-size: 32px;
            margin: 10px;
            text-align: center;
            .fa-facebook {
              margin-right: 45px;
            }
          }

          @media only screen and (min-width: $breakpoint) {
            margin: 0;
            height: 30px;
            font-size: 32px;
            line-height: 48px;
            margin: 40px 105px 40px 0;
            text-align: right;
            .fa-facebook {
              margin-right: 10px;
            }
          }
        }

        .footer_logo_area {
            height: 100%;
            width: 100%;
            display: block;
            font-size: 0;
            letter-spacing: 0;

            h2 {
                @media only screen and (max-width: $breakpoint) {
                    margin: 0;
                    line-height: 48px;
                    display: block;
                    font-size: 24px;
                    text-align: center;
                    margin: 20px auto 0;
                    background: none;
                    a{
                      width: 156px;
display: inline-block;
                    }
                }

                @media only screen and (min-width: $breakpoint) {
                  margin: 0;
                  height: 30px;
                  line-height: 48px;
                  display: inline-block;
                  font-size: 24px;
                  text-align: center;
                  margin: 40px 0;
                  background: none;
                  a{
                    width: 156px;
display: inline-block;
                  }
                }
            }

            .desctext {
                @media only screen and (max-width: $breakpoint) {
                    display: block;
                    font-size: 10px;
                    font-size: 1rem;
                    vertical-align: top;
                    text-align: center;
                    -webkit-font-smoothing: auto;
                    transform: rotate(0.05deg);
                    text-shadow: 0 0 0.1px rgba(51, 51, 51, 0.5);
                }

                @media only screen and (min-width: $breakpoint) {
                  display: inline-block;
                      margin-left: 20px;
                      font-size: 12px;
                      font-size: 1.2rem;
                      vertical-align: top;
                      font-weight: normal;
                }
            }
        }

        .footerbox {
            @media only screen and (max-width: $breakpoint) {
                display: inline-block;
                width: 100%;
                padding: 0;
                vertical-align: top;

                &:nth-of-type(1) {
                    padding: 20px 0;
                    border-left: none;
                }

                &:nth-of-type(4) {
                    padding: 0 0 0 0;

                }

                .level01 {
                    text-align: left;
                    margin: 0;
                    height: 50px;
                    line-height: 50px;
                    border-bottom: 1px solid #ddd;
                    font-weight: bold;
                }
            }

            @media only screen and (min-width: $breakpoint) {
                display: inline-block;
                width: 25%;
                padding: 0 20px;
                border-left: 1px solid #ddd;
                vertical-align: top;

                &:nth-of-type(1) {
                    padding: 0 20px 0 0;
                    border-left: none;
                }

                &:nth-of-type(4) {
                    padding: 0 0 0 20px;

                }

                .level01 {
                    margin: 0 0 20px 0;
                    font-size: 12px;
                    font-weight: bold;
                }

            }
            &__2 {
              @media only screen and (max-width: $breakpoint) {
                  display: inline-block;
                  width: 100%;
                  padding: 0;
                  vertical-align: top;

                  &:nth-of-type(1) {
                      padding: 20px 0;
                      border-left: none;
                  }

                  &:nth-of-type(4) {
                      padding: 0 0 0 0;

                  }

                  .level01 {
                      text-align: left;
                      margin: 0;
                      height: 50px;
                      line-height: 50px;
                      border-bottom: 1px solid #ddd;
                      font-weight: bold;

                  }
              }
              @media only screen and (min-width: $breakpoint) {
                display: inline-block;
                width: 100%;
                padding: 0;
                vertical-align: top;
                margin: 30px 0 47px;
                border-left: 1px solid #ddd;

                  &:nth-of-type(1) {
                      padding: 0 20px 0 0;
                      border-left: none;
                  }

                  &:nth-of-type(4) {
                      padding: 0 0 0 20px;

                  }

                  .level01 {
                      margin: 0 0 0 20px;
                      font-size: 12px;
                      font-weight: bold;
                  }

              }
            }

            &__2 .btn_list , .btn_list {
                li {
                    font-size: 14px;
                    color: #444;

                    @media only screen and (max-width: $breakpoint) {
                        width: 100%;
                    }

                    @media only screen and (min-width: $breakpoint) {
                        width: 220px;
                    }

                    height: 50px;
                    display: block;
                    text-align: center;
                    line-height: 50px;
                    border-bottom: 1px solid #ddd;
                    font-weight: bold;
                    position: relative;

                    &:last-child {
                        border-bottom: none;
                    }

                    a {
                        color: #444;
                        display: block;
                        background: #fff;
                        height: 50px;
                        border: 1px solid #ddd;
                        line-height: 50px;

                        &:hover {
                            background-color: #999;
                            color: #fff;
                            -webkit-transition: all 0.3s ease;
                            -moz-transition: all 0.3s ease;
                            -o-transition: all 0.3s ease;
                            transition: all 0.3s ease;
                        }
                    }

                    i {
                        color: #999;
                        font-size: 20px;
                        position: absolute;
                        left: 20px;
                        top: 0;
                        font-weight: 300;
                    }
                }
            }

            &__2 .btn_list2 {
              @media only screen and (max-width: $breakpoint) {
                li:first-child {
                }
                a{
                  width: calc(100% - 50px);
                  display: inline-block !important;
                }
                .ac_btn {
                  width: 50px;
                  height: 50px;
                  vertical-align: top;
                  font-size: 20px;
                  color: #999;
                  line-height: 50px;
                  text-align: center;
                  cursor: pointer;
                }
                .active .fa-plus:before {
                  content: "\f068";
                }
                ul {
                  display: none;
                  li {
                    a {
                      width: 100%;
                      position: relative;
                      padding-left: 30px;
                      i {
                        left: 14px;
                      }
                    }
                  }
                }
              }
              @media only screen and (min-width: $breakpoint) {
                a {
                    margin-left: 20px;
                    margin-top: 20px;
                    position: relative;
                }
              }
            }
          &__2 .btn_list2 , .btn_list2 {
                li {
                    @media only screen and (max-width: $breakpoint) {
                        position: relative;
                        padding-left: 0px;

                        a {
                            color: #999;
                            font-weight: bold;
                            display: block;
                            padding-left: 14px;
                            height: 50px;
                            line-height: 50px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            border-bottom: 1px dotted #ddd;

                            &:hover {
                                color: #444;
                            }

                            i {
                                font-weight: 900;
                                margin-right: 0.3em;
                                font-size: 14px;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                            ul {
                              li {

                              }
                            }
                        }
                    }

                    @media only screen and (min-width: $breakpoint) {
                        position: relative;

                        a {
                            line-height: 2em;
                            color: #999;
                            font-weight: bold;
                            display: inline-block;
                            padding-left: 14px;

                            &:hover {
                                color: #444;
                            }

                            i {
                                font-weight: 900;
                                margin-right: 0.3em;
                                font-size: 14px;
                                position: absolute;
                                left: 0;
                                top: 0;
                            }
                        }
                        ul {
                          li {
                            display: inline-block;
                            width: 25%;
                            a {
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                              display: inline-block;
                              max-width: 100%;
                              position: relative;
                              font-weight: normal;
                              margin-top: 0;
                              i {
                                font-weight: 300;
                                font-size: 12px;
                              }
                            }
                          }
                          }
                    }
                }
            }
        }

        //footerbox

    }

    //inner
    .footer_boder {
        border-top: 2px solid #e4e4e4;
        border-bottom: 2px solid #e4e4e4;

        @media only screen and (max-width: $breakpoint) {
            padding: 5px 0;
        }

        @media only screen and (min-width: $breakpoint) {
            padding: 10px 0;
        }

        .footerbn {
            padding: 0 8px;

            li {
                @media only screen and (max-width: $breakpoint) {
                    display: inline-block;
                    width: 50%;
                    height: 54px;
                    text-align: center;
                    margin: 5px 0;
                }

                @media only screen and (min-width: $breakpoint) {
                    display: inline-block;
                    //width: 160px;
                    height: 54px;
                    margin-right: 10px;
                    text-align: center;
                    background: #f7f7f7;
                    margin-right: 10px;
                }


                a {
                    display: block;
                    height: 54px;
                    //width: 160px;

                    img {
                        height: 100%;
                        width: auto;
                        text-align: center;
                        line-height: 54px;
                    }
                }
            }
        }

        .footer_pmark {
            display: flex; 
            justify-content: space-between;
            align-items: end;
            @media only screen and (max-width: $breakpoint) {
                display: block;
                text-align: center;
                img {
                    margin-bottom: 10px;
                }
            }
        }

    }

    .copyright {
        display: block;
        text-align: center;
        padding: 10px 0 0 0;
        color: #999;
    }

    background: #f2f2f2;

    p {
        background: #efefef;
        margin: 0;
    }
}

@media only screen and (max-width: $breakpoint) {
    .sp {
        display: block !important;
    }

    .pc {
        display: none !important;
    }

    .pc-inl {
        display: none !important;
    }

    .sp-inl {
        display: inline !important;
    }

    span.sp {
        display: inline !important;
    }
}

@media only screen and (min-width: $breakpoint) {
    .sp {
        display: none !important;
    }

    .sp-inl {
        display: none !important;
    }

    .pc {
        display: block !important;
    }

    .pc-inl {
        display: inline-block !important;
    }
}

/* --------------------------------
	どうするか　ページトップボタン
-------------------------------- */
#page-top {
    display: none !important;
}

/* --------------------------------
	追記部分
-------------------------------- */
#main {
    margin-bottom: 15px;
}

/************************************************
モバイル専用があれば
************************************************ */
@media only screen and (max-width: $breakpoint) {
    .inner {
        overflow: hidden;
    }



    /****** Base Layout Setting ********/

    #contents,
    #header,
    #footer,
    .pagetop {
        /* Force Hardware Acceleration in WebKit
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        will-change: transform; */
    }

    #contents,
    #header,
    #footer,
    .pagetop {
        position: relative;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;
    }

    .drawer-open #contents,
    .drawer-open #header,
    .drawer-open #footer,
    .drawer-open .pagetop {
        -webkit-transform: translateX(-260px);
        -moz-transform: translateX(-260px);
        -ms-transform: translateX(-260px);
        -o-transform: translateX(-260px);
        transform: translateX(-260px);
    }

    .drawer {
        height: 0;
        visibility: hidden;
        -webkit-transition: visibility 0s 0.3s;
        -moz-transition: visibility 0s 0.3s;
        transition: visibility 0s 0.3s;
    }

    .drawer-open .drawer {
        height: 100%;
        visibility: visible;
        -webkit-transition: visibility 0s 0.3s;
        -moz-transition: visibility 0s 0.3s;
        transition: visibility 0s 0.3s;
    }

    .overlay.is-visible {
        -webkit-transform: translateX(-260px);
        -moz-transform: translateX(-260px);
        -ms-transform: translateX(-260px);
        -o-transform: translateX(-260px);
        transform: translateX(-260px);
    }

    .overlay.is-visible.cart-is-visible {
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0);
    }

    .drawer {
        position: fixed;
        top: 0;
        right: 0;
        height: 100%;
        width: 260px;
        background: #fff;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
        -webkit-transition: -webkit-transform 0.3s;
        -moz-transition: -moz-transform 0.3s;
        transition: transform 0.3s;

        .input_search {
            height: 50px;
            padding: 0 50px 0 1em;
            border-bottom: 1px solid #ddd;
            position: relative;
            margin-top: 14px;
            margin-left: 1%;
            margin-right: 1%;
        }

        button {
            border: 0;
            background: none;
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            width: 44px;
            height: 43px;
            overflow: hidden;
            white-space: nowrap;
            z-index: 1;
            font-size: 20px;
        }

        li {
            list-style: none;

            a {
                border-bottom: 1px solid #ddd;
                color: #999;
                display: block;
                font-size: 14px;
                font-size: 1.4rem;
                height: 50px;
                line-height: 50px;
                overflow: hidden;
                padding: 0 10px;
                text-overflow: ellipsis;
                transform: translateZ(0px);
                transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
                white-space: nowrap;

                &:hover {
                    color: #444;
                    -webkit-transition: background 0.3s 0.3s;
                    -moz-transition: background 0.3s 0.3s;
                    transition: background 0.3s 0.3s;
                }

                i {
                    font-size: 20px;
                    margin-right: 1rem;
                    border-right: 1px dotted #ddd;
                    padding-right: 1rem;
                    width: 35px !important;
                    display: inline-block !important;
                }

                .cart_icon {
                    .badge {
                        background: #e22d1f;
                        width: 18px;
                        height: 18px;
                        font-size: 10px;
                        border-radius: 15px;
                        color: #fff;
                        display: block;
                        position: absolute;
                        top: 4px;
                        left: 22px;
                        text-align: center;
                        line-height: 14px;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    #drawer .category-nav {
        text-align: left;
        width: 100%;
    }

    #drawer .member_link li:first-child,
    #drawer .member_link li:nth-of-type(2) {}

    #drawer .member_link li:first-child a,
    #drawer .member_link li:nth-of-type(2) a {}

    #drawer .member_link li:nth-child(n + 3) a {}

    #drawer .member_link li:nth-of-type(3) a:before {
        content: "\f004";
        font-family: FontAwesome;
        font-size: 18px;
    }

    #drawer .member_link li:nth-of-type(4) a:before {
        content: "\f07a";
        font-family: FontAwesome;
        font-size: 18px;
    }

    #drawer .member_link li i {
        font-size: 20px;
    }

    #drawer .member_link li a .price {
        padding-left: 5px;
    }

    #drawer .search input {
        height: 43px;
        width: 100%;
        font-size: 16px;
        font-size: 1.6rem;
        border: 0 none;
        padding: 0.5em 0;
        box-shadow: none;
        background: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        &:focus {
            outline: none;
        }
    }

    #drawer .search .bt_search {
        right: 0;
    }

    #drawer .category-nav a {
        border-right: none;
    }

    .drawer-open .nav-trigger span {
        /* hide line in the center */
        background: rgba(46, 50, 51, 0);
    }

    .drawer-open .nav-trigger span::before,
    .drawer-open .nav-trigger span::after {
        /* keep visible other 2 lines */
        background: #444;
    }

    .drawer-open .nav-trigger span::before {
        -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
        transform: translateX(4px) translateY(-3px) rotate(45deg);
    }

    .drawer-open .nav-trigger span::after {
        -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform: translateX(4px) translateY(2px) rotate(-45deg);
    }

    #btn_menu {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 10px;
        z-index: 50;
        width: 40px;
        height: 40px;
        transition: 0.3s ease;
    }

    #btn_menu {
        display: inline-block;
        width: 40px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 17px;
        transition: 0.3s ease;

        a {
            width: 40px;
            height: 30px;
            display: block;
        }
    }

    .drawer-open #btn_menu {}

    .nav-trigger {
        width: 100%;
        height: 100%;
        color: transparent !important;
    }

    .nav-trigger span,
    .nav-trigger span::before,
    .nav-trigger span::after {
        /* hamburger icon in CSS */
        position: absolute;
        display: inline-block;
        height: 3px;
        display: block;

        width: 30px;
        background: #444;
    }

    .nav-trigger span {
        /* line in the center */
        position: absolute;
        width: 30px;
        top: 50%;
        right: 5px;
        margin-top: -2px;
        -webkit-transition: background 0.3s 0.3s;
        -moz-transition: background 0.3s 0.3s;
        transition: background 0.3s 0.3s;
    }

    .nav-trigger span::before,
    .nav-trigger span::after {
        /* other 2 lines */
        content: '';
        right: 0;
        /* Force Hardware Acceleration in WebKit */
        -webkit-transform: translateZ(0);
        -moz-transform: translateZ(0);
        -ms-transform: translateZ(0);
        -o-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
        -o-transform-origin: 0% 50%;
        transform-origin: 0% 50%;
        -webkit-transition: -webkit-transform 0.3s 0.3s;
        -moz-transition: -moz-transform 0.3s 0.3s;
        transition: transform 0.3s 0.3s;
    }

    .nav-trigger span::before {
        /* menu icon top line */
        top: -8px;
    }

    .nav-trigger span::after {
        /* menu icon bottom line */
        top: 8px;
    }

    .drawer-open .nav-trigger span {
        /* hide line in the center */
        background: rgba(46, 50, 51, 0);
    }

    .drawer-open .nav-trigger span::before,
    .drawer-open .nav-trigger span::after {
        /* keep visible other 2 lines */
        background: #444;
    }

    .drawer-open .nav-trigger span::before {
        -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
        -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
        transform: translateX(4px) translateY(-3px) rotate(45deg);
    }

    .drawer-open .nav-trigger span::after {
        -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
        -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
        transform: translateX(4px) translateY(2px) rotate(-45deg);
    }

    .overlay {
        /* shadow layer visible when navigation is active */
        position: fixed;
        z-index: 2;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.5);
        visibility: hidden;
        opacity: 0;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
        -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
        transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
    }

    .overlay.is-visible {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
        -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
        transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
    }

    .main_btn {
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        padding: 10px 0;
        text-decoration: none;
        background-color: #F4A93C;
        color: #FFF !important;
       // border-bottom: solid 2px #ad7203;
        border-radius: 5px;
        outline: none !important;
    }

    .main_btn:active {
       // border-bottom: 2px solid transparent !important;
        margin-bottom: 0px;
    }

    .text-primary {
        color: #F4A93C;
    }

    .text-danger {
        color: #d8430e;
    }


}

//end sp
//キャッシュバックバナ
.cambana-cash {
    background: #e61212;
}
//ヘッダーキャンペーン一覧
#h_cam_box {
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
.cam_toggle_btn {
  background-color: #ffcc00;
  min-height: 50px;
  display: block;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  @media only screen and (max-width: $breakpoint) {
    padding: 14px;
    font-size: 14px;
  }
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  span {
    background: red;
    display: inline-block;
    padding: 5px 7px;
    border-radius: 5px;
    margin-right: 7px;
    height: 27px;
    line-height: 20px;
    color: #ffcc00;
  }
//  box-shadow: 0px 1px 3px #444;
  i{
    margin-left: 1em;
  }
  &.active {
    color: #999;
    i:before {
        content: "\f056";
    }
  }
}

.cam_toggle_detail {
  background-color: #414140;
  display: none;
  padding: 27px 0;
  #main {
    width: 1230px;
    margin: auto;
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
    }
    .col-sm-4 {
      padding-left: 15px !important;
      padding-right: 15px !important;
      &:last-of-type {
        @media only screen and (max-width: $breakpoint) {
          padding-bottom: 0;
        }
      }
      @media only screen and (max-width: $breakpoint) {
        padding-bottom: 27px;
        text-align: center;
      }
    }
  }
}
}//h_cam_box


.messe111{
    position: relative;
    div {
        position: absolute;
        display: block;
        width: 300px;
        background: #ffed2dad;
        padding: 5px;
        z-index: 10000;
    }
}