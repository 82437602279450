.pricebox_jfont {
    display: block;
    margin: 0 auto;
    border: 1px solid #ddd;
    position: relative;
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      max-width: 360px;
      margin-bottom: 20px;
    }
    .pricebox_top {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 90px;
      position: relative;
      //height: 50%;
      //padding: 19px 20px 17px;
      .obi01 {
        color: #fff;
        width: 0;
        height: 0;
        border-left: 100px solid #e54b4b;
        border-top: 0 solid transparent;
        border-bottom: 100px solid transparent;
        position: absolute;
        left: -4px;
        top: -4px;
      }

      .obititle {
        color: #fff;
        font-weight: bold;
        font-size: 15px;
        position: absolute;
        top: 5px;
        left: 4px;
        .font28 {
          font-size: 28px;
        }
        .font14 {
          font-size: 14px;
        }
      } //obititle
    } //pricebox_top
    .pricebox_bottom {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 50%;
      position: relative;
      padding: 30px;
    }
    .price_title {
      display: block;
      width: 83px;
      height: 38px;
      text-align: center;
      color: #111;
      font-size: 22px;
      font-weight: bold;
      line-height: 38px;
    }
    .price {
      display: block;
      font-size: 18px;
      font-weight: normal;
      text-align: center;
      line-height: 1.7;
      span {
        display: inline;
        font-size: 34px;
      }
    }
    .price_desc {
      font-size: 12px;
      line-height: 1.8em;
      text-align: center;
      &__red {
        color: #e54b4b;
        &__bold {
          color: #e54b4b;
          font-weight: bold;
          font-size: 14px;
        }
      }
    }
    .navi_btn {
      display: block;
      width: 240px;
      height: 40px;
      background: #4d4d4d;
      color: #fff;
      margin: 0 auto;
      text-align: center;
      line-height: 40px;
      border-radius: 2px;
      position: relative;
      margin-top: 10px;
      &:hover {
        background-color: #818181;
      }
      i {
        position: absolute;
        display: block;
        top: 0;
        right: 10px;
        font-size: 24px;
        color: #777;
      }
    }
    p {
      width: 280px;
      display: block;
    }
  } //pricebox_jfont

  .jdont_bg_c_price_01 {
    background-color: #E7F6FE;
  }
  .jdont_bg_c_price_02 {
    background-color: #080404;
  }
  .jdont_bg_c_price_03 {
    background-color: #ECECEC;
  }
  .jdont_bg_c_price_04 {
    background-color: #FFF0E6;
  }

  .jfont_op {
    background-color: #F5F5F5;
    padding: 30px;
    .navi_btn {
        display: block;
        width: 240px;
        height: 40px;
        background: #4d4d4d;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        position: relative;
        margin-top: 10px;
        &:hover {
          background-color: #818181;
        }
        i {
          position: absolute;
          display: block;
          top: 0;
          right: 10px;
          font-size: 24px;
          color: #777;
        }
      }
  }