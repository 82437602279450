/***********************************************
モバイルのブレイクポイント
************************************************ */
$breakpoint: 767px;
/*
共通コンポーネント

Styleguide 11.0
*/

/*
トップページ

Styleguide 12.0
*/

/*
商品一覧

Styleguide 13.0
*/
.product_page{
    .h_maker_link{
        background-color: #eff2f0;
        padding: 10px;
        text-align: right;
        @media only screen and (max-width: $breakpoint) {
            margin-bottom: 20px;
        }
        a {
            color: #35aae7;
            font-size: 14px;
            display: table;
            min-width: 100%;
            .to_maker_link {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
            .angle_maker_link {
                display: table-cell;
                vertical-align: middle;
                margin-left: 8px;
                font-size: 20px;
            }
            &:hover,
            &:focus {
                color: #0099e8;
                text-decoration: underline;
            }
        }
    }
}

/*
商品詳細

Styleguide 14.0
*/

/*
注文

Styleguide 15.0
*/

/*
注文履歴

Styleguide 16.0
*/

/*
住所一覧

Styleguide 17.0
*/

/*
パスワード再発行

Styleguide 18.0
*/

/*
フォーム

Styleguide 19.0
*/

/*
404ページ

Styleguide 20.0
*/

/*
退会手続き

Styleguide 21.0
*/

/*
会員情報編集完了

Styleguide 22.0
*/
