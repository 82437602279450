.maar {
    .maar_line{
        border: 1px solid #ddd;
    }
    .price2{
        font-size: 28px;
        display: block;
        span{
            font-size: 18px;
        }
    }
    .navi_btn {
        display: block;
        width: 240px;
        height: 40px;
        background: #4d4d4d;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        position: relative;
        margin-top: 10px;
        &:hover {
          background-color: #818181;
        }
        i {
          position: absolute;
          display: block;
          top: 0;
          right: 10px;
          font-size: 24px;
          color: #777;
        }
      }
      hr {
        margin: 2em 0;
      }
}