/* LESS Document => SCSS Document fgoriginal */

/*ec-cube4用調整用*/
.ec-layoutRole .ec-layoutRole__contents {
  max-width: inherit !important;
}

/***********************************************
モバイルのブレイクポイント
************************************************ */
$breakpoint: 767px;
/* --------------------------------
Common style
-------------------------------- */

/* --------------------------------
ボタン系
-------------------------------- */
@media only screen and (max-width: $breakpoint) {
  .cart_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    margin-top: 0 !important;

    &:hover,
    &:focus {
      background-color: #818181;
      color: #fff;
    }

    i {
      position: absolute;
      left: 8px;
      color: #818181;
      font-size: 18px;
    }
  }

  .sold_out_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #fff;
    color: #444 !important;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #444;
    }
  }

  .next_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    position: relative;

    &:hover,
    &:focus {
      background-color: #818181;
      color: #fff;
    }

    i {
      position: absolute;
      right: 8px;
      color: #818181;
      font-size: 18px;
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .cart_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    margin-top: 0 !important;

    &:hover,
    &:focus {
      background-color: #818181;
      color: #fff;
    }

    i {
      position: absolute;
      left: 8px;
      color: #818181;
      font-size: 18px;
    }
  }

  .sold_out_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #fff;
    color: #444;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;

    &:hover,
    &:focus {
      background-color: #fff;
      color: #444;
    }
  }

  .next_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    position: relative;

    &:hover,
    &:focus {
      background-color: #818181;
      color: #fff;
    }

    i {
      position: absolute;
      right: 8px;
      color: #818181;
      font-size: 18px;
    }
  }
}

.like_btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #f2f2f2;
  padding: 0;
  margin-right: 20px;
  font-size: 18px;
  line-height: 18px;
  color: #999;
  @media only screen and (max-width: $breakpoint) {
    margin-right: 18px;
    margin-bottom: 20px;
  }

  &:focus,
  &:active:focus {
    outline: none;
  }
}
.mypage .download_btn {
  display: inline-block;
  width: 180px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  background: #4d4d4d;
  color: #fff;
  border-radius: 3px;
  padding: 0;
  margin: 1em 0;
  position: relative;
  text-align: center;
  line-height: 35px;
  &:hover,
  &:focus {
    background-color: #818181;
    color: #fff;
  }
  i {
    font-size: 18px;
    margin-right: 0.5em;
  }
}

/* ここから下はまだ整理できてない*/
/* svg icon */

/* --------------------------------
商品スライダーブロック
-------------------------------- */

.slider-nav {
  display: none;
}

.slider-nav.slick-initialized {
  display: block;
}

#main_w860 {
  .slider-nav {
    @media only screen and (max-width: $breakpoint) {
      overflow: hidden;
      margin-top: -7px;
      margin-bottom: 40px;
    }

    @media only screen and (min-width: $breakpoint) {
      overflow: hidden;
      margin-top: -7px;
      margin-bottom: 70px;
    }

    .slick-list {
      overflow: visible;

      .slick-slide {
        @media only screen and (max-width: $breakpoint) {
          //    padding: 27px 4px 20px;
          //    transform: scale(1);
        }

        @media only screen and (min-width: $breakpoint) {
          //    padding: 27px 20px 20px;
          //    transform: scale(1);
        }
      }

      .inner img {
        @media only screen and (max-width: $breakpoint) {
          display: block;
          //    width: 860px;
          //    border-radius: 10px;
          //    opacity: 1;
          //    transform: scale(1);
        }

        @media only screen and (min-width: $breakpoint) {
          display: block;
          //    height: 300px;
          //    width: 860px;
          //    border-radius: 10px;
          //    opacity: 1;
          //    transform: scale(1);
        }

        &:hover {
          opacity: 0.777;
        }
      }

      .slick-active {
        transform: scale(1);
      }
    }

    .btnbox_pre {
      left: 20px;
    }

    .btnbox_nex {
      right: 20px;
    }

    .slide-dots {
      display: block;
      visibility: visible;
    }
  }
}

.slider-nav {
  .slick-list {
    .slick-center {
      transform: scale(1);
      //filter: blur(0);
    }
  }

  .slick-slide {
    @media only screen and (max-width: $breakpoint) {
      display: block;
      //    padding: 27px 0 20px;
      //    transform: scale(0.935);
      //filter: blur(1px);
      position: relative !important;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
      //    width: 320px;
    }

    @media only screen and (min-width: $breakpoint) {
      display: block;
      //    padding: 27px 0 20px;
      //    transform: scale(0.935);
      //filter: blur(1px);
      position: relative !important;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    .price_d {
      position: absolute;
      top: 11px;
      left: 0px;
      z-index: 1;
    }

    .detail {
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        position: absolute;
        bottom: 25px;
        right: 0;
        z-index: 1;
        text-align: center;
        font-size: 12px;
        color: #444;
        display: block;
      }

      @media only screen and (min-width: $breakpoint) {
        position: absolute;
        bottom: 40px;
        right: 40px;
        z-index: 1;
        text-align: right;
        font-size: 12px;
        color: #444;
      }

      &:hover {
        color: #444;
      }

      .tag {
        font-size: 10px;
        font-weight: bold;
        color: #dc1e1e;
      }

      .weight {
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
      }

      .price {
        display: inline-block;

        @media only screen and (max-width: $breakpoint) {
          font-size: 18px;
        }

        @media only screen and (min-width: $breakpoint) {
          font-size: 30px;
        }

        font-weight: bold;
        margin-left: 10px;
        margin-right: 5px;

        span {
          font-size: 18px;
          margin-right: 5px;
        }
      }
    }

    .inner {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 1;
      overflow: hidden;

      &:hover {
        opacity: 0.777;
      }

      &__01 {
        background: #c4c4c4;
      }

      &__02 {
        background: #c4c4c4;
      }

      &__03 {
        background: #c4c4c4;
      }

      &__04 {
        background: #c4c4c4;
      }
    }
  }

  .btnbox_nex {
    @media only screen and (max-width: $breakpoint) {
      display: none !important;
    }

    @media only screen and (min-width: $breakpoint) {
      display: block;
      position: absolute;
      top: 158px;
      right: 50px;
      height: 34px;
      width: 28px;
      margin-top: 0;
      padding: 5px 0;
      cursor: pointer;
      opacity: 0.7;
    }

    &:hover {
      opacity: 1;
    }

    .nexArrow {
      display: block;
      content: "";
      width: 22px;
      height: 22px;
      border: 0px;
      border-top: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      filter: drop-shadow(2px 2px 1px #444);
    }
  }

  .btnbox_pre {
    @media only screen and (max-width: $breakpoint) {
      display: none !important;
    }

    @media only screen and (min-width: $breakpoint) {
      display: block;
      position: absolute;
      top: 158px;
      left: 50px;
      height: 34px;
      width: 28px;
      margin-top: 0;
      padding: 5px 5px;
      cursor: pointer;
      opacity: 0.7;
      z-index: 1;
    }

    &:hover {
      opacity: 1;
    }

    .preArrow {
      display: block;
      content: "";
      width: 22px;
      height: 22px;
      border: 0px;
      border-top: solid 2px #ffffff;
      border-right: solid 2px #ffffff;
      -ms-transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      filter: drop-shadow(-2px -2px 1px #444);
    }
  }

  .slide-dots {
    //  display: none;
    //  visibility: hidden;
    text-align: center;
    position: absolute;
    bottom: 20px;
    right: 40px;
    @media only screen and (max-width: $breakpoint) {
      bottom: 8px;
      right: 8px;
    }

    li {
      display: inline-block;
      border: 2px solid #fff;
      width: 8px;
      border-radius: 8px;
      height: 8px;
      margin-left: 10px;
      @media only screen and (max-width: $breakpoint) {
        margin-left: 8px;
      }
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      button {
        display: none;
      }
    }

    .slick-active {
      background: #ffcb01;
      border: 2px solid #ffcb01;
    }
  }
}
/* スライダーその他のキャンペーンバナーなど */
#cam_main {
  max-width: 1335px;
  .cam_list {
    background-color: #e4e4e1;
    h2 {
      font-size: 26px;
      font-weight: bold;
      margin-top: 0;
      margin-bottom: 36px;
      @media only screen and (max-width: $breakpoint) {
        font-size: 18px;
      }
    }
    .main {
      max-width: 1216px;
      padding: 50px 0 0px;
      .row {
        margin-left: -15px;
        margin-right: -15px;
        @media only screen and (max-width: $breakpoint) {
          margin-left: 0;
          margin-right: 0;
        }
        .col-sm-6,
        .col-sm-12 {
          padding-left: 15px !important;
          padding-right: 15px !important;
        }
        .col-sm-6 {
          margin-bottom: 50px;
        }
        .cam_list_label {
          position: absolute;
          z-index: 1;
          text-align: center;
          margin: 0 auto;
          height: 34px;
          font-weight: bold;
          font-size: 16px;
          padding: 6px 28px;
          top: -17px;
          left: 26px;
          &::before {
            transform: skewX(-30deg);
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
            background-color: #ffcb01;
          }
        }
      }
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        padding: 50px 8px 70px;
      }
    }
  }
}

/* --------------------------------
mainコンテンツ
-------------------------------- */
#main {
  @media only screen and (max-width: $breakpoint) {
    width: 100%;
    margin: auto;

    /*         .row {
    margin-left: 8px;
    margin-right: 8px;
    padding-bottom: 10px;
    }*/
  }

  @media only screen and (min-width: $breakpoint) {
    width: 1200px;
    margin: auto;

    /*        .row {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 10px;
    }*/
  }

  #topicpath {
    #list_header_menu {
      display: block;
      padding: 15px 0;

      li {
        display: inline;
        font-size: 10px;
        font-weight: bold;

        a {
          color: #999;

          &:hover {
            color: #444;
          }
        }

        &:after {
          content: "\f105";
          font-family: "Font Awesome 6 Pro";
          padding: 0 10px;
          color: #999;
        }

        &:first-child a {
          color: #999;
        }

        &:last-child a {
          color: #444;
        }

        &:last-child:after {
          content: "";
          padding: 0;
        }
      }
    }
  }

  #main_w860 {
    @media only screen and (max-width: $breakpoint) {
    }

    @media only screen and (min-width: $breakpoint) {
      width: 860px;
      float: left;
      padding-bottom: 100px;
    }
  }

  .sidebar {
    @media only screen and (max-width: $breakpoint) {
      display: block;
      width: 100%;
      margin-bottom: 70px;

      img {
        width: 321px;
        height: auto;
        margin: 0 auto;
        display: block;
      }
    }

    @media only screen and (min-width: $breakpoint) {
      display: inline-block;
      vertical-align: top;
      width: 300px;
      margin-left: 40px;
    }
  }
}

/* --------------------------------
  新着コンテンツ+お買い物ガイドバナー
  -------------------------------- */
#news_area {
  @media only screen and (max-width: $breakpoint) {
    h3 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      display: block;
      border-bottom: 1px solid #ddd;
      vertical-align: top;
      padding-bottom: 17px;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    margin-right: 30px;
    font-size: 0;
    letter-spacing: 0;
    margin-top: 37px;

    h3 {
      margin: 0;
      font-size: 20px;
      font-weight: bold;
      width: 100px;
      height: 100px;
      display: inline-block;
      border-right: 1px solid #ddd;
      vertical-align: top;
    }
  }

  .newslist {
    @media only screen and (max-width: $breakpoint) {
      display: block;
      width: 100%;
      margin-top: 0px;
    }

    @media only screen and (min-width: $breakpoint) {
      display: inline-block;
      width: 760px;
    }
  }

  dl {
    vertical-align: top;
    margin-bottom: 15px;

    dt {
      @media only screen and (max-width: $breakpoint) {
      }

      color: #999;
      font-size: 12px;
      display: inline-block;
      width: 25%;
      vertical-align: top;
      margin-bottom: 14px;
      margin-top: 14px;

      @media only screen and (min-width: $breakpoint) {
        color: #999;
        font-size: 12px;
        display: inline-block;
        width: 100px;
        vertical-align: top;
        margin-bottom: 15px;
        margin-top: 0;
        text-align: right;
      }

      .morelink {
        font-size: 12px;
        letter-spacing: -2px;
        text-decoration: underline;
        color: #999;

        &:hover {
          color: #444;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;
        }

        i {
          letter-spacing: 0;
          margin-left: 8px;
        }
      }
    }

    dd {
      @media only screen and (max-width: $breakpoint) {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 73%;
        vertical-align: top;

        a {
          color: #444;
          width: 100%;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: 15px 0;

          &:hover {
            text-decoration: underline;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
      }

      @media only screen and (min-width: $breakpoint) {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        width: 660px;
        padding-left: 20px;
        vertical-align: top;
        margin-bottom: 15px;

        a {
          color: #444;

          &:hover {
            text-decoration: underline;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}

.guide_bana {
  @media only screen and (max-width: $breakpoint) {
    text-align: center;
  }

  @media only screen and (min-width: $breakpoint) {
    margin-top: 37px;
  }

  h3 {
    margin: 0;
  }
}

/* --------------------------------
    共通ボックス　おすすめ商品グループプラグイン
    -------------------------------- */
.box_line {
  @media only screen and (max-width: $breakpoint) {
    display: block;
    border-bottom: 3px solid #444;
    width: 100% !important;
    margin: 0 0 40px 0 !important;

    h2,
    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 17px;
      margin-top: 0;
      color: #111;
    }
    .icon_dl {
      display: inline-block;
      background-color: #a89754;
      color: #fff;
      height: 26px;
      vertical-align: middle;
      margin-bottom: 10px;
      line-height: 26px;
      padding: 0px 12px;
      border-radius: 12px;
      margin-left: 1em;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    display: block;
    border-bottom: 3px solid #444;
    margin-bottom: 25px;

    h2,
    h3 {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 16px;
      margin-top: 0;
      display: inline-block;
      color: #111;
    }
    .icon_dl {
      display: inline-block;
      background-color: #a89754;
      color: #fff;
      height: 26px;
      vertical-align: middle;
      margin-bottom: 10px;
      line-height: 26px;
      padding: 0px 12px;
      border-radius: 12px;
      margin-left: 1em;
    }
  }

  .link_navi {
    display: table-cell;
    float: right;
    height: 37px;
    vertical-align: bottom;
    line-height: 37px;
    font-size: 14px;
    color: #444;
    &:hover {
      text-decoration: underline;
    }

    i {
      margin-left: 8px;
    }
  }
}
.box_line2 {
  @media only screen and (max-width: $breakpoint) {
    display: block;
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100% !important;
    margin: 0 0 40px 0 !important;

    h2,
    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 17px;
      margin-top: 0;
      color: #444;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    display: block;
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 25px;

    h2,
    h3 {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 16px;
      margin-top: 0;
      display: inline-block;
      color: #444;
    }
  }
}
.box_maker_h3 {
  @media only screen and (max-width: $breakpoint) {
    display: block;
    background-color: #f9ce24;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100% !important;
    margin: 0 0 40px 0 !important;

    h2,
    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 17px;
      margin-top: 0;
      color: #444;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    display: block;
    background-color: #f9ce24;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 25px;

    h2,
    h3 {
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 16px;
      margin-top: 0;
      display: inline-block;
      color: #444;
    }
  }
}
.box_g {
  @media only screen and (max-width: $breakpoint) {
    display: block;
    font-size: 0;
    letter-spacing: 0;
    //  margin: 0 -10px;
    //  width: calc(~'100% + 16px');
  }

  @media only screen and (min-width: $breakpoint) {
    display: block;
    font-size: 0;
    letter-spacing: 0;
    //margin: 0 -10px;
    width: calc(100% + 20px);
  }

  .pickup_item {
    @media only screen and (max-width: $breakpoint) {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      margin-bottom: 30px;

      .item_photo {
        width: 50%;
        height: 138px;
        overflow: hidden;
        display: inline-block;
        position: relative;
        text-align: center;

        img {
          height: 98%;
          width: auto;
          padding: 4%;
        }
      }

      dl {
        width: 50%;
        height: 100%;
        display: inline-block;
        vertical-align: top;

        dt {
          font-size: 14px;
          font-weight: bold;
          color: #444;
          padding: 20px 10px 0px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 68px;
        }

        dd {
          font-size: 12px;
          // height: 70px;
          padding: 0 10px 20px 10px;
          color: #444;

          .price {
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            margin-right: 5px;
            margin-left: 0;
            color: #444;

            span {
              font-size: 10px;
              font-weight: bold;
            }
          }
        }
      }
    }

    @media only screen and (min-width: $breakpoint) {
      display: inline-block;
      width: 386px;
      height: 140px;
      margin: 0px 5px;
      overflow: hidden;

      margin-bottom: 30px;

      .item_photo {
        width: 193px;
        height: 138px;
        overflow: hidden;
        display: inline-block;
        position: relative;
        text-align: center;

        img {
          height: 98%;
          width: auto;
          padding: 4%;
        }
      }

      dl {
        width: 191px;
        height: 100%;
        display: inline-block;
        vertical-align: top;

        dt {
          font-size: 14px;
          font-weight: bold;
          color: #444;
          padding: 20px 10px 0px 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 68px;
        }

        dd {
          font-size: 12px;
          height: 70px;
          padding: 0 10px 20px 10px;
          color: #444;

          .price {
            display: inline-block;
            font-size: 30px;
            font-weight: bold;
            margin-right: 5px;
            margin-left: 0;
            color: #444;

            span {
              font-size: 18px;
              font-weight: bold;
            }
          }
        }
      }
    }

    a {
      display: block;
      box-shadow: 0 0 0 3px #f2f2f2 inset;

      &:hover {
        box-shadow: 0 0 0 5px #ddd inset;

        .a_inner {
          display: block;
        }
      }

      .item_comment {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 12px;
        margin: 0;
        font-weight: bold;
        margin-bottom: 6px;
        color: #dc1e1e;
      }
    }
  }
}

.pack_item {
  .pickup_item:nth-of-type(1),
  .pickup_item:nth-of-type(2) {
    @media only screen and (max-width: $breakpoint) {
    }

    @media only screen and (min-width: $breakpoint) {
      height: 180px !important;
      width: 590px !important;

      .item_photo {
        width: 295px;
        height: 178px;
      }

      dl {
        width: 293px;

        dt {
          padding: 30px 10px 0 10px;
          height: 108px;
        }
      }
    }
  }
}

.wp_pickup {
  @media only screen and (max-width: $breakpoint) {
    dd {
      .item_comment {
        font-size: 12px !important;
        color: #444 !important;
        height: auto !important;
        text-overflow: unset !important;
        white-space: normal !important;
        font-weight: normal !important;
      }
    }
  }

  @media only screen and (min-width: $breakpoint) {
    dt {
      height: 55px !important;
    }

    dd {
      height: 83px !important;

      .item_comment {
        font-size: 12px !important;
        color: #444 !important;
        height: auto !important;
        text-overflow: unset !important;
        white-space: normal !important;
        font-weight: normal !important;
      }
    }
  }

  .pickup_item:nth-of-type(1),
  .pickup_item:nth-of-type(2) {
    @media only screen and (max-width: $breakpoint) {
    }

    @media only screen and (min-width: $breakpoint) {
      height: 180px !important;
      width: 590px !important;

      .item_photo {
        width: 295px;
        height: 178px;
      }

      dl {
        width: 293px;

        dt {
          padding: 30px 10px 0 10px;
          height: 70px !important;
        }

        dd {
          height: 78px !important;
          padding: 0 10px 0 10px;
          margin-bottom: 30px;
          overflow: hidden;

          .item_comment {
            font-size: 12px;
            color: #444;
            height: auto;
            text-overflow: unset;
            white-space: normal;
            font-weight: normal;
          }
        }
      }
    }
  }
}

/* --------------------------------
        人気フォントランキングブロック
        -------------------------------- */
.p_ranking {
  @media only screen and (max-width: $breakpoint) {
  }

  @media only screen and (min-width: $breakpoint) {
    .pl26 {
      padding-left: 26px !important;
    }
  }

  h2 {
    margin-top: 0;
  }

  h3 {
    margin: 0 0 35px 0;
    font-size: 16px;
    font-weight: bold;

    i {
      margin-right: 6px;
      font-size: 21px;
    }
  }

  .list {
    @media only screen and (max-width: $breakpoint) {
      height: 120px;
      margin-bottom: 30px;
      position: relative;
      width: 100%;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }

    @media only screen and (min-width: $breakpoint) {
      height: 147px;
      margin-right: 26px;
      margin-bottom: 30px;
      position: relative;
    }

    &__right {
      margin-right: 0;
    }

    &__r1 {
      background-color: rgb(209, 190, 165);

      &:hover {
        background-color: rgba(209, 190, 165, 0.777);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      .rank_num {
        color: #d3b182;
      }
    }

    &__r2 {
      background-color: rgb(183, 178, 171);

      &:hover {
        background-color: rgba(183, 178, 171, 0.777);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      .rank_num {
        color: #b7b2ab;
      }
    }

    &__r3 {
      background-color: rgb(192, 153, 146);

      &:hover {
        background-color: rgba(192, 153, 146, 0.777);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }

      .rank_num {
        color: #cda098;
      }
    }

    a {
      display: block;
      font-size: 0;
      letter-spacing: 0;

      @media only screen and (max-width: $breakpoint) {
        padding: 5px;
      }

      @media only screen and (min-width: $breakpoint) {
        padding: 20px 20px 20px 20px;
      }

      color: #444;

      &:hover {
        color: #444;
      }

      .img {
        display: inline-block;
        width: calc(50% - 20px);
        height: 107px;
        margin-right: 20px;
        font-size: 19px;
        color: #ffffffad;
        line-height: 107px;
        text-align: center;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        &__dlfont_1 {
          background-image: url(../img/parts/p_rank/dlfont_hpnw6.png);
          opacity: 0.3;
        }

        &__dlfont_1_1 {
          background-image: url(../img/parts/p_rank/olfont_ranking01_hpnw8.png);
          opacity: 0.3;
        }

        &__dlfont_1_2 {
          background-image: url(../img/parts/p_rank/dlfont_ranking01_hsnw6.png);
          opacity: 0.3;
        }

        &__dlfont_2 {
          background-image: url(../img/parts/p_rank/dlfont_hsnw8.png);
          opacity: 0.3;
        }

        &__dlfont_3 {
          background-image: url(../img/parts/p_rank/dlfont_ar_kanteiryuh.png);
          opacity: 0.3;
        }
        &__dlfont_3_2 {
          background-image: url(../img/parts/p_rank/dlfont_Rodin_Pro_M.png);
          opacity: 0.3;
        }

        &__olfont_1 {
          background-image: url(../img/parts/p_rank/olfont_hakusyutensyo.png);
          opacity: 0.3;
        }

        &__olfont_2 {
          background-image: url(../img/parts/p_rank/olfont_tomiten.png);
          opacity: 0.3;
        }

        &__olfont_3 {
          background-image: url(../img/parts/p_rank/olfont_oohige113.png);
          opacity: 0.3;
        }
        &__olfont_3_2 {
          background-image: url(../img/parts/p_rank/olfont_iwata_kanteiryu.png);
          opacity: 0.3;
        }
      }

      .detail {
        display: inline-block;
        width: 50%;
        height: 107px;
        padding: 10px 0 10px 21px;
        vertical-align: top;
        border-left: 1px solid #ffffff42;

        @media only screen and (max-width: $breakpoint) {
          font-size: 10px;
        }

        @media only screen and (min-width: $breakpoint) {
          font-size: 12px;
        }

        overflow: hidden;

        .name {
          font-size: 12px;
          color: #fff;
          margin-bottom: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .tag {
          font-size: 10px;
          font-weight: bold;
          color: #dc1e1e;
          height: 14px;
          overflow: hidden;
        }

        .moji {
          @media only screen and (max-width: $breakpoint) {
            display: inline-block;
            font-size: 10px;
            font-weight: bold;
            margin-right: 0px;
          }

          @media only screen and (min-width: $breakpoint) {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin-right: 0px;
          }
        }

        .price {
          @media only screen and (max-width: $breakpoint) {
            display: inline-block;
            font-size: 18px;
            font-weight: bold;
            margin-right: 5px;

            span {
              font-size: 12px;
              margin-right: 5px;
            }
          }

          @media only screen and (min-width: $breakpoint) {
            display: inline-block;
            font-size: 30px;
            font-weight: bold;
            margin-right: 5px;

            span {
              font-size: 18px;
              margin-right: 5px;
            }
          }
        }

        .cam_color {
          color: #dc1e1e;
        }
      }
    }

    .rank_num {
      position: absolute;
      top: -12px;
      left: -7px;
      width: 40px;
      height: 40px;
      font-size: 20px;
      background: #444;
      border-radius: 40px;
      text-align: center;
      line-height: 40px;
      @media only screen and (max-width: $breakpoint) {
        left: -3px;
      }
    }
  }
}

/************************************************
        商品一覧、商品詳細、サイドバー用
        ************************************************ */
.cat_desc {
  @media only screen and (max-width: $breakpoint) {
    display: block;
    background: #ffcc00;
    margin: 20px 0 40px;
    padding: 20px;
    h2 {
      margin: 0;
      vertical-align: top;
      border-left: 5px solid #fff;
      padding-left: 12px;
    }
    p {
      display: inline-block;
      vertical-align: top;
      margin: 0;
      margin-top: 20px;
      a {
        color: #111;
        text-decoration: underline;
        &:hover {
          color: #111;
          text-decoration: underline;
        }
      }
    }
  }
  @media only screen and (min-width: $breakpoint) {
    display: block;
    background: #ffcc00;
    margin: 20px 0 40px;
    padding: 20px;
    h2 {
      display: inline-block;
      font-size: 20px;
      width: 300px;
      margin: 0;
      vertical-align: top;
      border-left: 5px solid #fff;
      padding-left: 12px;
    }
    p {
      display: inline-block;
      width: 520px;
      vertical-align: top;
      margin: 0;
      line-height: 1.8em;
      a {
        color: #111;
        text-decoration: underline;
        &:hover {
          color: #111;
          text-decoration: underline;
        }
      }
    }
  }
}
#item_list {
  .flexbox {
    @media only screen and (max-width: $breakpoint) {
      display: block !important;
    }

    @media only screen and (min-width: $breakpoint) {
    }
  }
}
#item_list {
  .flexbox {
    @media only screen and (max-width: $breakpoint) {
      display: block !important;
    }

    @media only screen and (min-width: $breakpoint) {
    }
  }
}

#tabbox {
  &:last-of-type {
    margin-bottom: 40px;
  }

  .active {
    background-color: #fff;
    color: inherit;

    i:before {
      content: "\f068";
    }
  }

  dt {
    display: block;
    border-bottom: 5px solid #444;

    @media only screen and (max-width: $breakpoint) {
      padding: 20px 8px;
    }

    @media only screen and (min-width: $breakpoint) {
      padding: 20px;
    }

    background-color: #4d4d4d;
    color: #fff;
    cursor: pointer;

    h2 {
      @media only screen and (max-width: $breakpoint) {
        font-size: 18px;
      }

      @media only screen and (min-width: $breakpoint) {
        font-size: 20px;
      }

      font-weight: bold;
      margin: 0;
      padding: 0;
      display: inline-block;
    }

    i {
      float: right;
      font-size: 20px;
      line-height: 26px;
      color: #818181;
    }
  }

  dd {
    @media only screen and (max-width: $breakpoint) {
      display: none;
      padding: 40px 0px;
    }

    @media only screen and (min-width: $breakpoint) {
      display: none;
      padding: 40px 20px;
    }
  }
}

.list_cate {
  @media only screen and (max-width: $breakpoint) {
    text-align: center;
  }

  @media only screen and (min-width: $breakpoint) {
    margin: 0px -10px;
  }

  ul {
    li {
      font-size: 10px;
      width: 66px;
      display: inline-block;
      vertical-align: top;
      margin: 0 9px 30px 9px;

      a {
        display: block;
        color: #999;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .font_icon {
          display: flex;
          display: -webkit-flex;
          display: -moz-flex;
          display: -ms-flex;
          display: -ms-flexbox;
          display: -o-flex;
          width: 66px;
          height: 66px;
          background-color: #f2f2f2;
          margin-bottom: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          padding: 13px;

          // SCSS ループ
          @for $i from 0 to 40 {
            .catimg-#{$i} {
              width: 40px;
              height: 40px;
              background-image: url("../img/parts/font_cat/font_cat_img#{$i}.png");
              background-repeat: no-repeat;
            }
          }
        }

        &:hover {
          color: #444;
          -webkit-transition: all 0.3s ease;
          -moz-transition: all 0.3s ease;
          -o-transition: all 0.3s ease;
          transition: all 0.3s ease;

          .font_icon {
            background-color: #fff;
            box-shadow: 0 0 0 2px #b3b3b3 inset;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          // SCSS ループ
          @for $i from 0 to 40 {
            .catimg-#{$i} {
              width: 40px;
              height: 40px;
              background-image: url("../img/parts/font_cat/font_cat_img#{$i}_on.png");
              background-repeat: no-repeat;
            }
          }
        }
      }
    }
  }
}

.list_maker {
  @media only screen and (max-width: $breakpoint) {
    ul {
      li {
        font-size: 12px;
        display: block;
        width: 100%;
        vertical-align: top;
        padding-left: 8px;
        position: relative;
        border-bottom: 1px dotted #ddd;
        height: 50px;
        line-height: 50px;

        a {
          display: block;

          i {
            margin-right: 7px;
          }

          color: #999;

          &:hover {
            color: #444;
          }
        }
      }
    }
  }

  @media only screen and (min-width: $breakpoint) {
    margin: 0 -10px -20px;

    ul {
      li {
        font-size: 12px;
        display: inline-block;
        margin: 0 10px 20px;
        width: 190px;
        vertical-align: top;
        padding-left: 14px;
        position: relative;

        a {
          i {
            position: absolute;
            top: 2px;
            left: 0;
          }

          color: #999;

          &:hover {
            color: #444;
          }
        }
      }
    }
  }
}

.result_list {
  @media only screen and (max-width: $breakpoint) {
    &:nth-of-type(1) {
      margin-bottom: 0;
    }

    &:nth-of-type(2) {
      border-bottom: 1px solid #ddd;
      margin-bottom: 40px;
      padding-bottom: 40px !important;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    &:nth-of-type(1) {
      margin-bottom: 0;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    &:nth-of-type(2) {
      border-bottom: 1px solid #ddd;
      margin-bottom: 40px;
      margin-left: 20px !important;
      margin-right: 20px !important;
      padding-bottom: 40px !important;
      padding-top: 20px;
    }
  }

  @media only screen and (max-width: $breakpoint) {
    .title_area {
      flex-basis: auto;
      display: inline-block;
      width: 100%;

      h2 {
        margin-top: 0;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
        a {
          color: #444;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .maker {
        color: #818181;
        font-size: 12px;
      }
    }
  }

  @media only screen and (min-width: $breakpoint) {
    .title_area {
      flex-basis: auto;
      display: inline-block;

      h2 {
        margin-top: 0;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 2px;
        a {
          color: #444;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .maker {
        color: #818181;
        font-size: 12px;
      }
    }
  }

  .tag_area {
    @media only screen and (max-width: $breakpoint) {
      display: inline-block;
      vertical-align: top;
      padding-left: 20px;
      min-width: 313px;
      text-align: right;
      float: right;
      flex-basis: auto;
      margin-right: 0;
      margin-left: auto;
      margin-bottom: 10px !important;
      margin-top: 10px;
      width: 100%;
    }

    @media only screen and (min-width: $breakpoint) {
      display: inline-block;
      vertical-align: top;
      padding-left: 20px;
      min-width: 313px;
      text-align: right;
      float: right;
      flex-basis: auto;
      margin-right: 0;
      margin-left: auto;
    }

    .not_supported {
      display: none;
    }

    .supported {
      display: inline-block;

      span {
        padding: 10px 15px;
        font-size: 10px;
        border: 1px solid #ddd;
        border-radius: 20px;
        margin-left: 5px;
        margin-bottom: 5px;
        display: block;
      }
    }
  }

  .item_photo {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      height: 120px;
      margin: 0;
      line-height: 85px;
      border: 1px solid #ddd;
      overflow: hidden;
      padding: 15px 20px 20px 20px;
      text-align: center;
      display: block;
    }

    @media only screen and (min-width: $breakpoint) {
      width: 507px;
      height: 120px;
      margin: 0;
      line-height: 85px;
      border: 1px solid #ddd;
      overflow: hidden;
      padding: 15px 20px 20px 20px;
      text-align: center;
      display: block;
    }

    img {
      max-height: 100%;
    }
  }

  .item_price {
    @media only screen and (max-width: $breakpoint) {
      font-size: 10px;
      margin: 10px;
    }

    @media only screen and (min-width: $breakpoint) {
      font-size: 12px;
    }

    &:last-of-type {
      padding-bottom: 7px;
    }

    span:first-child {
      font-size: 14px;
      margin-right: 0;
    }

    .box {
      display: inline-block;
      font-size: 10px;
      text-align: right;
      line-height: 1em;

      span {
        font-size: 10px;
        display: block;
        text-align: left;
      }
    }

    .moji {
      display: block;
      text-align: right;
    }
  }

  .item_detail_area {
    text-align: right;
  }

  .item_list_btn_area {
    display: inline-block;
  }
}

.flexbox {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -ms-flexbox;
  display: -o-flex;

  .item_detail_area {
    align-self: flex-end;
  }
}

#pagination_wrap {
  margin-bottom: 110px;

  ul {
    font-size: 0;
    letter-spacing: 0;
    text-align: center;

    li {
      display: inline-block;

      a {
        @media only screen and (max-width: $breakpoint) {
          font-size: 12px;
          display: inline-block;
          border: solid 1px #ddd;
          letter-spacing: normal;
          margin: 0 5px;
          width: 30px;
          height: 40px;
          transition: ease 0.2s;
          line-height: 40px;
          color: #444;
        }

        @media only screen and (min-width: $breakpoint) {
          font-size: 12px;
          display: inline-block;
          border: solid 1px #ddd;
          letter-spacing: normal;
          margin: 0 10px;
          width: 40px;
          height: 40px;
          transition: ease 0.2s;
          line-height: 40px;
          color: #444;
        }

        &:hover {
          border-color: #444;
        }
      }
    }

    .active {
      a {
        border-color: #444;
      }
    }
  }
}

/* --------------------------------
                サイドバー
                -------------------------------- */
.sidebar {
  ul {
    li {
      margin-bottom: 10px;

      a {
        display: block;
        color: #444;
      }
    }
  }

  .side_list {
    h4 {
      margin: 0;
      font-size: 16px;
      padding-left: 30px;
      position: relative;

      i {
        margin-right: 6px;
        font-size: 21px;
        line-height: 31px;
        position: absolute;
        left: 0;
        bottom: -4px;
      }
    }

    ul {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      margin: 10px 0;

      li {
        margin-bottom: 0;
        padding: 10px 0;
        border-bottom: 1px solid #ddd;

        &:last-of-type {
          border-bottom: none;
        }

        a {
          display: block;
          color: #444;

          &:hover {
            & .item_text {
              text-decoration: underline;
            }

            & .item_photo {
              opacity: 0.777;
            }
          }

          .item_photo {
            display: inline-block;
            width: 103px;
            height: 36px;
          }

          .item_text {
            @media only screen and (max-width: $breakpoint) {
              display: inline-block;
              width: calc(100% - 103px);
              padding-left: 10px;
              vertical-align: top;
            }

            @media only screen and (min-width: $breakpoint) {
              display: inline-block;
              width: 197px;
              padding-left: 10px;
              vertical-align: top;
            }
          }
        }
      }
    }

    .morelink {
      font-size: 14px;
      text-align: right;
      display: block;
      color: #444;
      &:hover {
        text-decoration: underline;
      }
      i {
        margin-left: 8px;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }
}

/* --------------------------------
                商品詳細
                -------------------------------- */
.detail_top_area {
  @media only screen and (max-width: $breakpoint) {
    padding: 0;
    margin: 20px 0 0 0 !important;
  }

  @media only screen and (min-width: $breakpoint) {
    padding: 20px 20px 0;
    margin-top: 20px;
  }
}

.title_area {
  display: inline-block;
  flex-basis: auto;

  .product_name {
    margin: 0;
    font-size: 14px;
    margin-bottom: -1px;
    font-weight: bold;
  }

  .maker_name {
    color: #818181;
    font-size: 12px;
    margin: 0;
  }
}

.tag_area {
  @media only screen and (max-width: $breakpoint) {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 10px !important;
  }

  @media only screen and (min-width: $breakpoint) {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
  }

  .not_supported {
    display: none;
  }

  .supported {
    display: inline-block;

    span {
      padding: 10px 15px;
      font-size: 10px;
      border: 1px solid #ddd;
      border-radius: 20px;
      margin-left: 5px;
      margin-bottom: 5px;
      display: block;
    }
  }
}

.font_detail {
  @media only screen and (max-width: $breakpoint) {
  }

  @media only screen and (min-width: $breakpoint) {
    padding: 0 20px;
  }

  .detail_top_img_area {
    padding: 80px 0 130px 0;
  }

  .box_line {
    .item_name {
      font-size: 18px;
    }
  }

  .outline_detail {
    border-top: 1px solid #ddd;
    padding: 40px 20px 0 0;
    margin-right: -20px;
  }

  .font_dtetail_s {
    margin-top: 70px;
  }

  .simulate_options {
    h3 {
      margin: 0 0 20px 0;

      .desc03 {
        font-size: 12px;
        font-weight: normal;
        color: #d91e27;
        //padding-left: 12px;
        line-height: 16px;
        display: block;
      }
    }

    .simulation_result {
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        height: 120px;
        margin: 0;
        line-height: 100px;
        border: 1px solid #ddd;
        overflow: hidden;
        padding: 10px 10px;
        margin-top: 10px;
        text-align: center;
      }

      @media only screen and (min-width: $breakpoint) {
        width: 507px;
        height: 120px;
        margin: 0;
        line-height: 100px;
        border: 1px solid #ddd;
        overflow: hidden;
        padding: 10px 10px;
        margin-top: 10px;
        text-align: center;
      }
      img {
        max-height: 100%;
      }
    }

    .sim_text {
      @media only screen and (max-width: $breakpoint) {
        width: calc(100% - 37px);
        height: 60px;
        background: #f2f2f2;
        border-radius: 4px;
        display: inline-block;
        position: relative;

        &:before {
          width: 40px;
          height: 40px;
          background-image: url(../img/common/serchform/text.gif);
          background-repeat: no-repeat;
          content: "";
          position: absolute;
          top: 22px;
          left: 10px;
        }

        input[type="text"] {
          border-radius: 48px 0 0 48px;
          width: calc(100% - 60px);
          height: 60px;
          background: #f2f2f2;
          border: 0;
          padding: 0px 0 0 30px;
          font-size: 14px;
          vertical-align: top;
          box-shadow: none !important;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
          }
        }

        a {
          font-size: 0;
          position: absolute;
          top: 0;
          right: 0;
          width: 60px;
          height: 60px;
          margin: 0;
          padding: 0;
          line-height: 60px;

          &:hover {
            i {
              color: #444;
            }
          }

          i {
            font-size: 18px;
            border-left: 1px solid #ddd;
            color: #999;
            display: inline-block;
            padding: 0 20px;
          }
        }
      }

      @media only screen and (min-width: $breakpoint) {
        width: 470px;
        height: 60px;
        background: #f2f2f2;
        border-radius: 4px;
        display: inline-block;
        position: relative;

        &:before {
          width: 40px;
          height: 40px;
          background-image: url(../img/common/serchform/text.gif);
          background-repeat: no-repeat;
          content: "";
          position: absolute;
          top: 22px;
          left: 20px;
        }

        input[type="text"] {
          border-radius: 48px 0 0 48px;
          width: 360px;
          height: 60px;
          background: #f2f2f2;
          border: 0;
          margin: 0 0 0 46px;
          padding: 0 2px;
          font-size: 14px;
          vertical-align: top;
          box-shadow: none !important;

          &:focus {
            outline: none;
          }

          &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
          }
        }

        a {
          font-size: 0;
          position: absolute;
          top: 0;
          right: 0;
          width: 60px;
          height: 60px;
          margin: 0;
          padding: 0;
          line-height: 60px;

          &:hover {
            i {
              color: #444;
            }
          }

          i {
            font-size: 18px;
            border-left: 1px solid #ddd;
            color: #999;
            display: inline-block;
            padding: 0px 20px;
          }
        }
      }
    }
  }

  table {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      margin-bottom: 60px;
      margin: 0 0 60px 0px;
      table-layout: fixed;

      tbody {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;

        tr {
          td {
            padding: 15px 30px;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            word-break: break-all;
          }
        }
      }
    }

    @media only screen and (min-width: $breakpoint) {
      width: 800px;
      margin-bottom: 60px;
      margin: 0 0 60px 20px;

      tbody {
        border-top: 1px solid #ddd;
        border-left: 1px solid #ddd;

        tr {
          td {
            padding: 15px 30px;
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
          }
        }
      }
    }
  }
}

//font_detail
.search_form_btn_reset_outline {
  width: 37px;
  height: 60px;
  padding: 0;
  padding-left: 20px;
  margin: 0;
  font-size: 18px;
  line-height: 57px;
  vertical-align: top;
  color: #999;
  display: inline-block;

  &:hover {
    color: #444;
  }
}

#item_detail {
  @media only screen and (max-width: $breakpoint) {
    padding: 0 0px;
  }

  @media only screen and (min-width: $breakpoint) {
    padding: 0 20px;
  }
}

.description_detail {
  @media only screen and (max-width: $breakpoint) {
    font-size: 14px;
    line-height: 2em;

    .content {
      min-height: 0px;
      padding-bottom: 0px;
      margin-bottom: 35px;
      border-bottom: 1px solid #ddd;
      word-break: break-all;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    width: 507px;
    font-size: 14px;
    line-height: 2em;
    display: inline-block;

    .content {
      border-right: 1px solid #ddd;
      padding-right: 20px;
      min-height: 150px;
      word-break: break-all;
    }
  }
}

.products_info {
  @media only screen and (max-width: $breakpoint) {
    .dl_cart_area {
      margin: 0 0px 0 0;
      text-align: right;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    display: inline-block;
    width: 313px;
    float: right;

    .dl_cart_area {
      margin: 0 -20px 0 0;
      text-align: right;

      form {
        display: inline-block;
      }
    }
  }

  .result_list {
    @media only screen and (max-width: $breakpoint) {
      border-bottom: none !important;
      margin-bottom: 30px;
      padding: 0;
      padding-bottom: 0 !important;
      margin: 0 0px 30px !important;
    }

    @media only screen and (min-width: $breakpoint) {
      border-bottom: none !important;
      margin-bottom: 30px;
      padding: 0;
      padding-bottom: 0 !important;
      margin: 0 20px 30px !important;
    }
  }

  .info {
    color: #999;
    background-color: #fff;
    border-color: #fff;
    padding: 30px 0;
    text-align: right;
    font-size: 12px;

    &:hover {
      color: #444;
    }
    &:active {
      -webkit-box-shadow:none;
      box-shadow:none;
    }
    &:focus {
      -webkit-box-shadow:none;
      box-shadow:none;
    }

    i {
      margin-left: 8px;
    }
  }
}

#item_photo_area {
  #detail_image_box__slides {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      padding: 10px 0 0;
    }

    @media only screen and (min-width: $breakpoint) {
      width: 820px;
      padding: 10px 0 0;
    }
  }

  .slick-list {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      height: auto;
      display: block;

      .slick-slide {
        height: 215px;
        text-align: center;
        line-height: 215px;

        img {
          max-height: 100%;
          display: inline-block;
        }
      }
    }

    @media only screen and (min-width: $breakpoint) {
      width: 507px;
      height: 215px;
      display: inline-block;

      .slick-slide {
        height: 215px;
        text-align: center;
        line-height: 215px;

        img {
          max-height: 100%;
          display: inline-block;
        }
      }
    }
  }

  ul {
    @media only screen and (max-width: $breakpoint) {
      font-size: 0;
      width: 100%;
      display: inline-block !important;
      vertical-align: bottom;
      margin: 10px 0 0;
    }

    @media only screen and (min-width: $breakpoint) {
      font-size: 0;
      width: 320px;
      display: inline-block !important;
      vertical-align: bottom;
      margin: 0 -10px -5px 0;
    }

    li {
      width: 54px;
      height: 54px;
      display: inline-block;
      border: 1px solid #ddd;
      border-radius: 2px;
      vertical-align: top;
      margin: 0 10px 10px 0;
      line-height: 50px;

      button {
        background: none;
        border: none;
        outline: none;
        border-image-width: 0;
        width: 100%;
        height: auto;
        &::before {
          display: none;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}

.result_list {
  #detail_cart_box {
    &__cart_quantity {
      margin-bottom: 15px;
      font-size: 14px;
      .errormsg {
        color: red;
        display: inline-block;
        position: relative;
        font-size: 12px;
        font-weight: bold;
        padding-left: 18px;
        &::before {
          position: absolute;
          content: "\f06a";
          font-size: 16px;
          font-family: "Font Awesome 6 Pro";
          left: 0;
          top: -3px;
        }
      }
      input[type="number"],
      input[type="button"] {
        display: inline-block;
        &:focus {
          box-shadow: none;
        }
      }
    }

    &__button_area {
      button {
        vertical-align: top;
        margin-top: 0;
      }
    }

    @media only screen and (max-width: $breakpoint) {
      text-align: right;
      margin-right: 0px;
    }

    @media only screen and (min-width: $breakpoint) {
      text-align: right;
      margin-right: -20px;
    }

    .item_price {
      padding-bottom: 7px;
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      appearance: textfield;
      -moz-appearance: textfield;

      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
      }
    }

    input[type="button"] {
      &:focus {
        outline: none;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
      }
    }

    .form-control {
      width: 38px;
      height: 38px;
      text-align: center;
      border: 0;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }

    .plus {
      width: 38px;
      height: 38px;
      vertical-align: top;
      border: 1px solid #ddd;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      background-color: #f2f2f2;
      color: #999;
    }

    .minus {
      width: 38px;
      height: 38px;
      vertical-align: top;
      border: 1px solid #ddd;
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      background-color: #f2f2f2;
      color: #999;
    }
  }
}

#related_product_area {
  @media only screen and (max-width: $breakpoint) {
    border-top: none !important;
    margin: 0 -8px !important;

    .flexbox {
      display: block;
    }

    .tag_area {
      margin-top: 10px;
    }

    .main_btn {
      width: 100% !important;
    }

    .item_name {
      overflow: hidden;
      text-overflow: unset !important;
      white-space: unset !important;
    }
  }

  @media only screen and (min-width: $breakpoint) {
  }
}

#detail_free_box__freearea {
  line-height: 2em;
  font-size: 14px;
  word-break: break-all;
}

#cboxLoadedContent {
  #content {
    padding: 20px;
  }
}

/* --------------------------------
                    シミレーションフォーム
                    -------------------------------- */
.font_sim_tabox {
  @media only screen and (max-width: $breakpoint) {
    background-color: #4d4d4d;
    padding: 0px 28px 35px;
    margin: 0 -8px 40px;
    h2 {
      margin: 0;
      color: #fff;
      padding: 0.83em 0;
      border-bottom: 1px solid #717171;
      font-size: 18px;
    }
  }

  @media only screen and (min-width: $breakpoint) {
    background-color: #4d4d4d;
    padding: 0px 20px 35px;
    margin: 0 0 70px;
    h2 {
      margin: 0;
      color: #fff;
      padding: 0.83em 0;
      border-bottom: 1px solid #717171;
      font-size: 18px;
    }
  }
}
#searchform {
  .s_form_title {
    @media only screen and (max-width: $breakpoint) {
      font-size: 14px;
      color: #fff;
      margin: 18px 0 18px;
    }
    @media only screen and (min-width: $breakpoint) {
      font-size: 16px;
      margin: 35px 0 18px;
      color: #fff;
      text-align: center;
    }
    margin-bottom: 30px;
    font-weight: bold;
  }
  @media only screen and (min-width: $breakpoint) {
    .sim_t_c_box {
      margin: 0 auto;
      width: 521px;
    }
  }
  .sim_text {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      height: 50px;
      background: #fff;
      border-radius: 4px 4px 0 0;
      display: inline-block;
      position: relative;

      &:before {
        width: 40px;
        height: 17px;
        background-image: url(../img/common/serchform/text.gif);
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 17px;
        left: 20px;
      }

      input[type="text"] {
        border-radius: 4px 4px 0 0;
        width: 100%;
        height: 50px;
        background: #fff;
        border: 0;
        padding: 0 0 0 46px;
        font-size: 14px;
        vertical-align: top;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
        }
      }
    }

    @media only screen and (min-width: $breakpoint) {
      width: 334px;
      height: 50px;
      background: #fff;
      border-radius: 4px 0 0 4px;
      display: inline-block;
      position: relative;

      &:before {
        width: 40px;
        height: 17px;
        background-image: url(../img/common/serchform/text.gif);
        background-repeat: no-repeat;
        content: "";
        position: absolute;
        top: 17px;
        left: 20px;
      }

      input[type="text"] {
        border-radius: 0;
        width: 280px;
        height: 50px;
        background: #fff;
        border: 0;
        margin: 0 0 0 46px;
        padding: 0 2px;
        font-size: 14px;
        vertical-align: top;

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
        }
      }
    }
  }

  .sim_check {
    @media only screen and (max-width: $breakpoint) {
      display: block;
      height: 50px;
      line-height: 50px;
      vertical-align: top;
      position: relative;
      background-color: #fff;
      border-top: 1px solid #b3b3b3;
      border-radius: 0 0 4px 4px;
      padding-left: 14px;
    }

    @media only screen and (min-width: $breakpoint) {
      display: inline-block;
      width: 187px;
      height: 50px;
      margin-left: 0;
      line-height: 50px;
      vertical-align: top;
      position: relative;
      background-color: #fff;
      border-left: 1px solid #b3b3b3;
      padding: 0 0 0 19px;
      border-radius: 0 4px 4px 0;
    }

    .form-check {
      input[type="checkbox"] {
        display: none;
      }
      label {
        position: relative;
        padding-left: 32px;
        display: block;
        font-size: 12px;
        color: #999;
        font-weight: bold;
        cursor: pointer;

        input[type="checkbox"] {
          display: none;
        }

        &:before {
          display: block;
          position: absolute;
          width: 22px;
          height: 22px;
          top: 14px;
          left: 0;
          content: "";
          border: 2px solid #ddd;
          border-radius: 4px;
        }

        &:after {
          display: block;
          position: absolute;
          top: -1px;
          left: 4px;
          content: "\f00c";
          font-size: 16px;
          font-family: "Font Awesome 6 Pro";
          font-weight: 100;
          color: #999;
          opacity: 0;
        }
      }

      .active {
        color: #444;
        &:after {
          color: #444;
          opacity: 1;
        }
      }
    }

    //checkbox
  }

  .sim_menu_outer {
    @media only screen and (max-width: $breakpoint) {
      width: calc(100% + 32px);
      overflow-x: scroll;
      overflow-y: hidden;
      display: block;
      margin-right: -5px;
      margin-top: 30px;
      margin: 0 -16px;
      padding-left: 16px;
    }

    @media only screen and (min-width: $breakpoint) {
    }
  }

  .sim_menu {
    @media only screen and (max-width: $breakpoint) {
      width: 840px;
      display: block;
      margin-right: -5px;
      margin-top: 30px;
    }

    @media only screen and (min-width: $breakpoint) {
      display: block;
      margin-right: -5px;
      margin-top: 30px;
      text-align: center;
    }

    .active {
      background: #606060;
      border-top: 1px solid #606060;
      border-right: 1px solid #606060;
      border-left: 1px solid #606060;
      color: #444;
      .col-form-label {
        color: #fff;
      }
      i:before {
        content: "\f107";
        color: #fff;
      }
    }

    li {
      display: inline-block;
      width: 164px;
      height: 40px;
      background: #4d4d4d;
      color: #b2b2b2;
      font-size: 14px;
      font-weight: bold;
      position: relative;
      border-top: 1px solid #4d4d4d;
      border-right: 1px solid #606060;
      border-left: 1px solid #4d4d4d;
      vertical-align: top;
      &:hover {
        color: #fff;
      }
      &:last-child {
        border-right: 1px solid #4d4d4d;
      }

      i {
        position: absolute;
        top: 8px;
        right: 8px;
        color: #818181;
        cursor: pointer;
        font-size: 18px;
      }

      span {
        display: none;
        line-height: 40px;
        padding: 0 23px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        color: #fff;
      }

      .col-form-label {
        //display: none;
        line-height: 40px;
        padding: 0 23px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        font-size: inherit;
        color: inherit;
        border-bottom:none;
      }
    }
  }

  //end simmenu
  .sim_menu_detail {
    .active {
      display: block;
    }

    li {
      @media only screen and (max-width: $breakpoint) {
        display: none;
        border: 1px solid #606060;
        background-color: #606060;
        margin-top: -1px;
        font-size: 12px;
        margin-left: -16px;
        margin-right: -16px;
      }

      @media only screen and (min-width: $breakpoint) {
        display: none;
        font-size: 12px;
        background-color: #606060;
      }

      div .form-check {
        &:first-child {
          display: none !important;
        }
      }

      #font_category {
        @media only screen and (max-width: $breakpoint) {
          padding: 40px 0 0;
          margin: 0px 20px;
          display: block;
          overflow: hidden;
          height: 270px;
          overflow-y: scroll;
          text-align: center;
        }

        @media only screen and (min-width: $breakpoint) {
          padding: 40px 13px 0;
          display: block;
        }

        .form-check {
          $root: &;
          display: inline-block;
          margin: 0 6.6px;
          vertical-align: top;
          position: relative;

          input[type="radio"] {
            width: 40px;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
            visibility: hidden;
          }

          .active {
            color: #fff;

            &:after {
              background-color: #fff;
              box-shadow: 0 0 0 1px #fff inset;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
          }

          // SCSS ループ処理
          @for $i from 0 to 40 {
            &:nth-of-type(#{$i}) {
              label {
                &::before {
                  content: "";
                  width: 40px;
                  height: 40px;
                  background-image: url("../img/parts/font_cat/font_cat_img#{$i}.png");
                  background-repeat: no-repeat;
                  display: block;
                  position: absolute;
                  top: 13px;
                  left: 13px;
                  z-index: 2;
                }

                &:hover::before {
                  background-image: url("../img/parts/font_cat/font_cat_img#{$i}_on.png");
                }
              }
              
              .active::before {
                background-image: url("../img/parts/font_cat/font_cat_img#{$i}_on.png");
              }
            }
          }

          label {
            display: block;
            width: 66px;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-top: 76px;
            font-size: 10px;
            text-align: center;
            color: #b2b2b2;
            margin-bottom: 30px;
            cursor: pointer;

            &:after {
              content: "";
              width: 66px;
              height: 66px;
              // background-color: #f2f2f2;
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              cursor: pointer;
              border-radius: 4px;
              box-shadow: 0 0 0 1px #b2b2b2 inset;
            }

            &:hover {
              color: #fff;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;

              &:after {
                background-color: #fff;
                box-shadow: 0 0 0 1px #fff inset;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
              }
            }
          }

          //label
        }

        //radio
      }

      //font_category
      #weight,
      #os,
      #type,
      #maker {
        @media only screen and (max-width: $breakpoint) {
          padding: 40px 17.5px 5px 17.5px;
          overflow: hidden;
          height: 270px;
          overflow-y: scroll;
        }

        @media only screen and (min-width: $breakpoint) {
          padding: 40px 17.5px 5px 17.5px;
        }

        .form-check {
          display: inline-block;
          width: 236px;
          margin: 0 12.5px 25px;
          vertical-align: middle;
          line-height: 1.2em;
          position: relative;
          padding-left: 32px;
          height: 28px;

          input[type="radio"] {
            display: none;
          }

          label {
            min-height: 28px;
            font-size: 14px;
            display: flex;
            display: -webkit-flex;
            display: -moz-flex;
            display: -ms-flex;
            display: -ms-flexbox;
            display: -o-flex;
            border-radius: 10px;
            flex-direction: column;
            justify-content: center;
            color: #999;
            cursor: pointer;
            height: 28px;

            &:before {
              display: block;
              position: absolute;
              width: 22px;
              height: 22px;
              top: 3px;
              left: 0;
              content: "";
              border: 1px solid #b2b2b2;
              border-radius: 4px;
            }

            &:after {
              display: block;
              position: absolute;
              top: 7px;
              left: 4px;
              content: "\f00c";
              font-size: 16px;
              font-family: "Font Awesome 6 Pro";
              font-weight: 100;
              color: #999;
              opacity: 0;
            }

            &:hover {
              color: #fff;
              -webkit-transition: all 0.3s ease;
              -moz-transition: all 0.3s ease;
              -o-transition: all 0.3s ease;
              transition: all 0.3s ease;
            }
          }

          .active {
            color: #fff;
            &:after {
              color: #fff;
              opacity: 1;
            }
            &:before {
              border: 1px solid #fff;
            }
          }
        }

        //radio
      }

      //weight os type maker
    }

    //li
  }

  //sim_menu_detail
  .btn_area {
    margin-top: 30px;

    .search_form_btn_reset {
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        line-height: 50px;
        font-size: 14px;
        color: #b2b2b2;
        margin: 0;
        padding: 0;
        position: relative;
        border-radius: 3px;
        margin-bottom: 20px;
        border: 2px solid #606060;
        box-shadow: none;
        -webkit-box-shadow: none;

        i {
          margin-right: 0.3em;
        }
        &:hover {
          background: #818181;
          border: 2px solid #818181;
          color: #fff;
        }
      }

      @media only screen and (min-width: $breakpoint) {
        width: 260px;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        line-height: 50px;
        font-size: 14px;
        color: #b2b2b2;
        margin: 0;
        padding: 0;
        position: relative;
        border-radius: 3px;
        border: 2px solid #606060;
        box-shadow: none;
        -webkit-box-shadow: none;

        i {
          margin-right: 0.3em;
        }
        &:hover {
          background: #818181;
          border: 2px solid #818181;
          color: #fff;
        }
      }
    }

    .search_form_btn {
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        margin: 0;
        padding: 0;
        margin-left: 0;
        border: 0;
        background-color: #606060;
        font-size: 14px;
        color: #b2b2b2;
        position: relative;
        border-radius: 3px;
        box-shadow: none;
        -webkit-animation-delay: none;

        i {
          margin-right: 0.3em;
        }
      }

      @media only screen and (min-width: $breakpoint) {
        width: 260px;
        height: 50px;
        display: inline-block;
        vertical-align: top;
        margin: 0;
        padding: 0;
        margin-left: 20px;
        border: 0;
        background-color: #606060;
        font-size: 14px;
        color: #b2b2b2;
        position: relative;
        border-radius: 3px;
        i {
          margin-right: 0.3em;
        }
      }

      &:hover {
        background-color: #818181;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        color: #fff;
      }
      &:active {
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
  }

  //btn_area
}
//searchform

/* --------------------------------
                        まとめ割問い合わせフォーム
                        -------------------------------- */
.matomewari {
  input {
    padding: 0 14px;
  }

  .form_coment {
    font-weight: bold;
    text-decoration: underline;
    color: #444;
    line-height: 3em;
    font-size: 16px;
    margin-bottom: 110px;
  }

  .inner {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      padding: 0 10px;
      p {
        font-size: 16px;
        line-height: 2em;
        margin: 0 0 10px;
        width: 100%;
        vertical-align: top;
      }
    }

    @media only screen and (min-width: $breakpoint) {
      width: 860px;

      p {
        font-size: 16px;
        line-height: 2em;
      }
    }

    h1 {
      @media only screen and (max-width: $breakpoint) {
        margin: 26px 0 26px;
        font-size: 26px;
      }

      @media only screen and (min-width: $breakpoint) {
        margin: 70px 0 26px;
        font-size: 38px;
      }

      margin: 70px 0 26px;
      font-size: 38px;
    }

    #top_wrap {
      padding: 0px;
    }

    form {
      h2 {
        span {
          display: none;
        }

        .coment {
          display: inline;
          font-size: 14px;
          font-weight: normal;
          margin-left: 16px;
        }
      }

      .form-group {
        @media only screen and (max-width: $breakpoint) {
          font-size: 11px;
          line-height: 2em;
        }

        @media only screen and (min-width: $breakpoint) {
          font-size: 14px;
          line-height: 2em;
          margin-left: 38px;
          padding: 20px 0;
        }

        .form-check {
          line-height: 3em;
          input[type="checkbox"],
          input[type="radio"] {
            display: none;
          }

          label {
            position: relative;
            padding-left: 34px;
            cursor: pointer;

            &:before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 18px;
              height: 18px;
              border: 1px solid #999;
              border-radius: 50%;
            }

            &.active:before {
              content: "";
              display: block;
              position: absolute;
              top: -3px;
              left: -3px;
              width: 24px;
              height: 24px;
              border: 3px solid #a1a1a1;
              border-radius: 50%;
            }

            &.active:after {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 18px;
              height: 18px;
              background: #ffffff;
              border-radius: 50%;
              border: 6px solid #444;
            }
          }

          .cam_label {
            @media only screen and (max-width: $breakpoint) {
              display: inline-block;
              margin-left: 15px;
              font-size: 11px;
              padding: 0px 3px;
              background: red;
              height: 16px;
              line-height: 16px;
              border-radius: 3px;
              color: #fff;
              position: relative;
              margin-top: -3px;
              width: 130px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            @media only screen and (min-width: $breakpoint) {
              display: inline-block;
              margin-left: 22px;
              font-size: 12px;
              padding: 0px 10px;
              background: red;
              height: 24px;
              line-height: 24px;
              border-radius: 3px;
              color: #fff;
              position: relative;
              margin-top: -3px;

              &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0px;
                left: -11px;
                border: 1px solid #999;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12px 12px 12px 0;
                border-color: transparent #ff0000 transparent transparent;
              }
            }
          }

          input[type="checkbox"] {
            margin-right: 14px;
            display: none;
          }
        }

        .selected_detail {
          display: none;
          border-top: 1px solid #ddd;
          margin-bottom: 70px;
          margin-top: 13px;

          dl {
            border: none !important;

            dt {
              @media only screen and (max-width: $breakpoint) {
                padding-left: 14px;
                display: block;
                height: 50px;
                line-height: 50px;
              }

              @media only screen and (min-width: $breakpoint) {
                width: 224px;
                padding-left: 14px;
              }

              label {
                font-size: 14px;
                font-weight: normal;
              }
            }

            dd {
              display: inline-block;

              select {
                @media only screen and (max-width: $breakpoint) {
                  margin: 0 8px 0 4px;
                  height: 38px;
                  padding: 0 14px;
                }

                @media only screen and (min-width: $breakpoint) {
                  margin: 0 18px 0 14px;
                  height: 38px;
                  padding: 0 14px;
                }

                &:first-of-type {
                  margin-left: 0;
                }
              }
            }
          }
        }

        .errormsg {
          color: red;
          font-weight: bold;
          margin: 0;
        }

        .subtitle {
          display: inline-block;
          color: #444;
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
        }

        .form_coment2 {
          margin-top: 20px;
          color: #999;
        }

        .form_coment3 {
          @media only screen and (max-width: $breakpoint) {
            display: block;
            margin-left: 20px !important;
          }

          @media only screen and (min-width: $breakpoint) {
            display: block;
            margin-left: 280px;
          }
        }
      }

      dl {
        @media only screen and (max-width: $breakpoint) {
          border-bottom: 1px solid #ddd;
          display: block;
          margin-left: 20px;
          width: 100%;
        }

        @media only screen and (min-width: $breakpoint) {
          height: 80px;
          border-bottom: 1px solid #ddd;
          display: table;
          margin-left: 20px;
        }

        dt {
          @media only screen and (max-width: $breakpoint) {
            display: block;
            vertical-align: middle;
            width: 105%;
            padding-left: 18px;
            width: calc(100% - 20px);
            padding-top: 10px;
          }

          @media only screen and (min-width: $breakpoint) {
            display: table-cell;
            vertical-align: middle;
            height: 80px;
            width: 300px;
            padding-left: 18px;
          }

          label {
            font-size: 16px;
            font-weight: bold;
          }

          span.required {
            margin-left: 14px;
            display: inline-block;
            height: 24px;
            background-color: #f2f2f2;
            color: #777;
            font-size: 10px;
            line-height: 24px;
            padding: 0 10px;
            border-radius: 12px;
            vertical-align: middle;
            margin-bottom: 4px;
          }
        }

        dd {
          @media only screen and (max-width: $breakpoint) {
            display: block;
            vertical-align: middle;
            width: calc(100% - 20px);
          }

          @media only screen and (min-width: $breakpoint) {
            display: table-cell;
            vertical-align: middle;
            height: 80px;
            width: 520px;
          }

          .form-group {
            margin-left: 0px;
          }

          .input_tel {
            input {
              margin-right: 0;
              margin-left: 0;
              width: calc(33.3% - 10.8px);
              height: 38px;

              &:last-of-type {
                margin-right: 0;
                width: 33.3%;
              }

              &:first-of-type {
                margin-left: 0;
              }
            }

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              input {
                width: 31%;

                &:last-of-type {
                  width: 33%;
                }
              }
            }
          }
        }
      }
    }
  }

  button {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      height: 50px;
      background: #f2f2f2;
      color: #999;
      font-weight: bold;
      border-radius: 3px;
    }

    @media only screen and (min-width: $breakpoint) {
      width: 220px;
      height: 50px;
      background: #f2f2f2;
      color: #999;
      font-weight: bold;
      border-radius: 3px;
    }

    &:hover {
      color: #999;
      background: #ddd;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &:active {
      box-shadow: none;
    }

    &.next {
      background: #4f4f4f;
      color: #f2f2f2;

      &:hover {
        background-color: #818181;
        color: #fff;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }

  .matomewari_button {
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      height: 50px;
      background: #f2f2f2;
      color: #777;
      font-weight: bold;
      border-radius: 3px;
      line-height: 36px;
      margin-bottom: 20px;
    }

    @media only screen and (min-width: $breakpoint) {
      width: 220px;
      height: 50px;
      background: #f2f2f2;
      color: #777;
      font-weight: bold;
      border-radius: 3px;
      line-height: 36px;
    }

    &:hover {
      color: #777;
      background: #ddd;
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    &:active {
      box-shadow: none;
    }

    &__next {
      width: 220px;
      height: 50px;
      background: #f2f2f2;
      color: #999;
      font-weight: bold;
      border-radius: 2px;
      line-height: 36px;
      background: #4f4f4f;
      color: #f2f2f2;

      &:hover {
        background-color: #818181;
        color: #fff;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
      }
    }
  }

  @media only screen and (max-width: $breakpoint) {
    #confirm_box__body {
      dt {
        padding-left: 0;
      }
    }

    #contactmatomewari_email {
      width: calc(100% - 7px) !important;
    }

    #top_box__name,
    #top_box__kana,
    #top_box__tel,
    #top_box__email {
      dt {
        padding-left: 0 !important;
      }

      .input_tel {
        padding: 10px 0;
      }

      .input_name {
        padding: 10px 0;

        input {
          display: inline-block !important;
          width: 49% !important;
          margin-right: 1% !important;
        }
      }
    }
  }

  #confirm_box__button_menu {
    text-align: center;
  }

  #confirm_box__back_button {
    display: inline-block;
    margin-right: 18px;
  }

  #confirm_box__confirm_button {
    display: inline-block;
  }

  #confirm_box__matome_select_detail {
    dd {
      ul {
        li {
          border-bottom: dotted 1px #ddd;
          padding: 10px 0;

          &:last-of-type {
            border-bottom: none;
          }
        }
      }
    }
  }
}

//matomewari
/* --------------------------------
 カテゴリ独自ページ
-------------------------------- */
//パターン1、、、フォント(ライセンス)
.lnewslist {
  border: 4px solid #ddd;
  margin: 53px 30px 0;
  position: relative;
  padding: 25px;
  .title {
    display: block;
    background-color: #ffcc00;
    padding: 6px;
    position: absolute;
    top: -17px;
    left: -4px;
    height: 26px;
    width: 70px;
    text-align: center;
    font-weight: bold;
  }
  .date-time {
    color: #595959;
    display: inline-block;
    vertical-align: top;
    font-size: 1.4rem;
  }
  .dec {
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;
    font-size: 1.4rem;
  }
}
.catpage_def {
  color: #111;
  .lets_video {
    width: calc(100% - 30px);
    height: auto;
    margin: 0 15px;
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
      margin: 0;
    }
  }
  .logo_moripass {
    display: block;
    width: 100%;
    height: 35px;
    margin: 20px 0 0;
    border: 1px solid #ddd;
    border-bottom: none;
    text-align: right;

    img {
      padding: 10px 22px 10px 0;
    }
  }
  .marker_y {
    display: inline-block;
    background-image: -webkit-linear-gradient(
      left,
      transparent 55%,
      #ffed2d 55%
    );
    background-image: -moz-linear-gradient(left, transparent 55%, #ffed2d 55%);
    background-image: -ms-linear-gradient(left, transparent 55%, #ffed2d 55%);
    background-image: -o-linear-gradient(left, transparent 55%, #ffed2d 55%);
    background-image: linear-gradient(left, transparent 55%, #ffed2d 55%);
  }
  .logo_cate183 {
    display: block;
    width: 100%;
    height: 35px;
    margin: 20px 0 0;
    border: 1px solid #ddd;
    text-align: right;

    img {
      padding: 10px 22px 10px 0;
    }
  }
  .logo_cate192 {
    display: block;
    width: 100%;
    height: 35px;
    margin: 20px 0 0;
    background-color: #444;
    clear: both;
    position: relative;
    img {
      padding: 10px 16px 10px 0;
      display: inline-block;
      float: right;
    }
  }

  .obi_new {
    position: absolute;
    width: 80px;
    height: 80px;
    background: #d6000f;
    color: #fff;
    font-weight: bold;
    border-radius: 40px;
    line-height: 80px;
    text-align: center;
    font-size: 21px;
    top: -23px;
    left: -5px;
  }
  .cam_dec {
    display: block;
    background: #e54b4b;
    color: #fff;
    font-size: 16px;
    text-align: center;
    padding: 15px 0;
    .row {
      padding-bottom: 0 !important;
    }
    .camline {
      border-right: 2px solid #fff;
      @media only screen and (max-width: $breakpoint) {
        margin-bottom: 10px;
      }
    }
    .disp01 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 16px;
      line-height: 16px;
      width: 150px;
      margin: 0 auto;
      &::before,
      &::after {
        border-top: 1px solid;
        content: "";
        display: inline; /* for IE */
        flex-grow: 1;
      }
      &::before {
        margin-right: 1em;
      }
      &::after {
        margin-left: 1em;
      }
    }
    .disp02 {
      height: 44px;
      line-height: 44px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -webkit-align-items: baseline;
      -moz-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      justify-content: center;
      position: relative;
      .font36 {
        font-size: 36px;
        font-weight: bold;
      }
      .font23 {
        font-size: 23px;
        font-weight: bold;
      }
    }
  } //camdec
  .old_stay_update_desc {
    background-color: #f5f5f5;
    position: relative;
    padding: 27px;
    width: 392px;
    @media only screen and (max-width: $breakpoint) {
      width: 100%;
    }
    .obi {
      position: absolute;
      left: 0;
      top: -10px;
      width: 100%;
      text-align: center;
    }
    .inner {
      text-align: center;
      a {
        background: #d70c19;
        margin-left: 27px;
        height: 42px;
        width: 194px;
        @media only screen and (max-width: $breakpoint) {
          img {
            margin-bottom: 20px;
          }
        }
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        line-height: 42px;
        font-size: 12px;
        border-radius: 3px;
        &:hover {
          background-color: #e15d66;
        }
        i {
          margin-left: 11px;
          font-size: 18px;
          vertical-align: bottom;
        }
      }
    }
  }
  h1 {
    font-size: 38px;
    text-align: center;
    font-weight: bold;
    margin: 60px 0 0;
    @media only screen and (max-width: $breakpoint) {
      font-size: 24px;
    }
  }
  .catpage_detail192 {
    padding: 0 65px;
    @media only screen and (max-width: $breakpoint) {
      margin: 0 10px;
    }
    .main_txt {
      font-size: 18px;
      line-height: 1.7em;
      margin-bottom: 80px;
    }
  }
  .catpage_detail {
    margin: 0 20px;
    @media only screen and (max-width: $breakpoint) {
      margin: 0;
    }
    .main_txt {
      font-size: 18px;
      line-height: 1.7em;
      margin-bottom: 80px;
    }

    .sc_navi {
      margin: 0 -10px !important;

      ul {

        li {
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0;
          a {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            height: 50px;
            background: #f2f2f2;
            color: #444;
            margin: 0 10px 13px;
            width: 100%;
            font-size: 12px;
            position: relative;
            font-weight: bold;
            &:after {
              position: absolute;
              content: "\f107";
              font-size: 21px;
              font-family: "Font Awesome 6 Pro";
              font-weight: 100;
              color: #111;
              right: 16px;
              top: 10px;
            }
            &:hover {
              color: #35aae7;
            }
          }
        }
      }
    }
    .lineup_navi {
      margin: 0 -10px !important;
      padding-bottom: 122px !important;
      ul {
        .col-sm-6 {
          a {
            height: 120px;
          }
        }
        li {
          display: -webkit-box;
          display: -webkit-flex;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          padding: 0;
          a {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -webkit-align-items: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: center;
            height: 100px;
            color: #444;
            margin: 0 10px 30px;
            width: 100%;
            font-size: 14px;
            position: relative;
            font-weight: bold;
            line-height: 1.8em;
            text-align: center;
            box-shadow: 0 0 0 3px #f2f2f2 inset;
            i {
              position: absolute;
              font-size: 21px;
              font-weight: 100;
              color: #111;
              right: 16px;
              top: 39px;
            }

            img {
              width: 230px;
              height: auto;
            }
            &:hover {
              color: #35aae7;
              box-shadow: 0 0 0 5px #ddd inset;
            }
            .cam_obi {
              position: absolute;
              top: -10px;
              left: -10px;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              font-size: 9px;
              background: #df3c3c;
              color: #fff;
              text-align: center;
              line-height: 14px;
              padding: 4px;
              display: block;
              z-index: 1;
            } //cam_obi
            .new_obi {
              position: absolute;
              top: -10px;
              left: -10px;
              width: 40px;
              height: 40px;
              border-radius: 40px;
              font-size: 9px;
              background: #df3c3c;
              color: #fff;
              text-align: center;
              line-height: 33px;
              padding: 4px;
              display: block;
              z-index: 1;
            }
          } //a
        } //li
      } //ul
    } //lineup_navi
  } //catpage_detail
  .catpage_detail02 {
    h3 {
      font-size: 20px;
      color: #444444;
    }
    .s-icon {
      width: 12px;
      height: 14px;
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 10px;
      }
    }
    .plus_mark {
      display: block;
      position: relative;
      &::after {
        content: "";
        width: 68px;
        height: 68px;
        position: absolute;
        bottom: calc(50% - 34px);
        left: calc(50% - 34px);
        background-color: #444;
        border-radius: 68px;
        z-index: 1;
      }
      &::before {
        content: "\f067";
        font-family: "Font Awesome 6 Pro";
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: calc(50% - 24px);
        left: calc(50% - 24px);
        border-radius: 48px;
        z-index: 2;
        text-align: center;
        line-height: 48px;
        font-size: 46px;
        color: #fff;
      }
    }
    .haku_navi_btn {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      height: 80px;
      background: #444;
      color: #f2f2f2;
      margin: 0 10px 13px;
      width: 420px;
      max-width: 100%;
      font-size: 24px;
      position: relative;
      margin: 0 auto;
      &::after {
        position: absolute;
        content: "\f107";
        font-size: 40px;
        font-family: "Font Awesome 6 Pro";
        font-weight: 100;
        right: 16px;
        top: 10px;
        color: #777;
      }
      &:hover {
        background-color: #818181;
      }
    }
    //ご購入のお手続き（新規・更新・追加）↓
    .box_line3 {
      display: block;
      border-bottom: 2px solid #dddddd;
      margin-bottom: 20px;
      padding-bottom: 15px;
    }
    .procedure_table {
      vertical-align: top;
      margin-bottom: 40px;
      width: 100%;
      tbody {
        vertical-align: top;
      }
      tr {
        th {
          position: relative;
          .num {
            width: 68px;
            height: 68px;
            background-color: #f2cc0c;
            border-radius: 68px;
            display: block;
            padding: 10px;
            margin: 0 15px;
            text-align: center;
            z-index: 1;
            position: relative;
            @media only screen and (max-width: $breakpoint) {
              margin-left: 0;
            }
            span {
              display: block;
              font-size: 36px;
              margin-top: -9px;
            }
          }
          .num_line {
            width: 1px;
            background-color: #ddd;
            height: calc(100% + 50px);
            position: absolute;
            top: -50px;
            left: 49px;
            z-index: 0;
            //transform:translateZ(1px);
            @media only screen and (max-width: $breakpoint) {
              left: 35px;
            }
          }
        }
        td {
          .num_title {
            font-size: 18px;
            font-weight: bold;
            margin-top: 25px;
            @media only screen and (max-width: $breakpoint) {
              font-size: 14px;
            }
          }
          .desc {
            padding: 15px 0 30px 50px;
            font-size: 14px;
            line-height: 1.8em;
            @media only screen and (max-width: $breakpoint) {
              padding: 15px 0 30px 10px;
              font-size: 12px;
            }
            .fa-envelope {
              position: absolute;
              font-size: 22px;
              left: -28px;
              top: -10px;
              color: #444;
            }
            .qanda__p {
              dt {
                position: relative;
                padding: 25px 40px;
                border-bottom: 1px solid #ddd;
                color: #444;
                cursor: pointer;
                font-weight: bold;
                @media only screen and (max-width: $breakpoint) {
                  padding: 25px;
                }
                .question {
                  position: absolute;
                  left: 0;
                }
                i {
                  display: inline-block;
                  position: absolute;
                  right: 20px;
                  top: 25px;
                  @media only screen and (max-width: $breakpoint) {
                    right: 8px;
                    top: 40%;
                  }
                }
              }
              .active {
                i {
                  &::before {
                    content: "\f106";
                  }
                }
              }
              dd {
                position: relative;
                display: none;
                padding: 25px 0 40px 40px;
                @media only screen and (max-width: $breakpoint) {
                  padding: 25px 0 40px 25px;
                }
                .answer {
                  position: absolute;
                  font-weight: bold;
                  left: 0;
                  color: #e54b4b;
                }
              }
            }
            .fa-fax {
              position: absolute;
              font-size: 24px;
              left: -5px;
              top: -2px;
              color: #444;
              @media only screen and (max-width: $breakpoint) {
                left: -1px;
              }
            }
            a {
              position: relative;
              .fa-file-pdf {
                color: #ff5454;
              }
            }
            .dl_line_box {
              border: 4px solid #ddd;
              padding: 25px !important;
              width: 285px;
              height: 172px !important;
              .obi01{
                color: #111;
                width: 0;
                height: 0;
                border-left: 100px solid #f2cc0c;
                border-top: 0 solid transparent;
                border-bottom: 100px solid transparent;
                position:
                absolute;
                left: -4px; 
                top: -4px;
              }
              .obi02{
                color: #111;
                width: 0;
                height: 0;
                border-left: 100px solid #f2880e;
                border-top: 0 solid transparent;
                border-bottom: 100px solid transparent;
                position:
                absolute;
                left: -4px; 
                top: -4px;
              }
              .obititle{
                color: #111;
                font-weight: bold;
                font-size: 14px;
                position: absolute;
                top: 0px;
                left: 0px;
                line-height: 32px;
                .font32{
                  font-size: 32px !important;
                }
                .font15{
                  font-size: 15px !important;
                }
              }
            }
            .list {
              position: relative;
              height: 40px;
              line-height: 40px;
              display: inline-block;
              padding: 0 30px;
              //width: 100%;

              @media only screen and (max-width: $breakpoint) {
                margin-left: 0 !important;
                padding: 0 10px 0 30px;
                font-size: 12px;
              }
              .dlbtn {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                background: #4d4d4d;
                color: #fff;
                border-radius: 3px;
                padding: 0 15px 0 40px;
                margin: 0;
                position: relative;
                margin-top: 25px !important;
                overflow: hidden;
                &:hover {
                  background-color: #818181;
                }
                &::before {
                  position: absolute;
                  content: "";
                  display: inline-block;
                  top: -180px;
                  left: 0;
                  width: 30px;
                  height: 100%;
                  background-color: #fff;
                  animation: shiny-btn1 5s ease-in-out infinite;
                }
                @-webkit-keyframes shiny-btn1 {
                  0% {
                    -webkit-transform: scale(0) rotate(45deg);
                    opacity: 0;
                  }
                  80% {
                    -webkit-transform: scale(0) rotate(45deg);
                    opacity: 0.5;
                  }
                  81% {
                    -webkit-transform: scale(4) rotate(45deg);
                    opacity: 1;
                  }
                  100% {
                    -webkit-transform: scale(50) rotate(45deg);
                    opacity: 0;
                  }
                }
                i {
                  font-size: 22px;
                  position: absolute;
                  left: 15px;
                  top: 0;
                }
              }
            }
            .border_r {
              border-right: 1px solid #ddd;
              @media only screen and (max-width: $breakpoint) {
                border-right: none;
              }
            }
          }
        }
      }
    } //procedure
    .procedure_text {
      margin-left: 15px;
      font-size: 14px;
      line-height: 1.8em;
      margin-bottom: 80px;
    }
    .cam_procedure_title {
      height: 50px;
      line-height: 50px;
      font-size: 20px;
      font-weight: bold;
      padding: 0 25px;
      background-color: #858585;
      color: #fff;
    }
    .cam_procedure {
      padding: 40px 30px;
    }
    //ご購入のお手続き（新規・更新・追加）↑
    //基本情報↓
    .b_info {
      width: 100%;
      tbody {
        vertical-align: top;
        tr {
          &:last-of-type td {
            padding-bottom: 0;
          }
          th {
            font-weight: normal;
            width: 160px;
            text-align: left;
            padding-left: 20px;
            padding-right: 20px;
          }
          td {
            padding-bottom: 20px;
            padding-right: 20px;
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
    .b_info2 {
      width: 100%;
      margin-bottom: 60px;
      tbody {
        line-height: 3em;
        tr {
          th {
            font-weight: normal;
            width: 20%;
            text-align: center;
            span {
              font-size: 18px;
            }
          }
          .border_r {
            border-right: 1px solid #ddd;
          }
        }
      }
    }
    .b_info3 {
      width: 100%;
      caption {
        text-align: left;
        padding: 0 20px;
        margin-bottom: 20px;
        caption-side: top;
      }
      tbody {
        vertical-align: top;
        tr {
          &:last-of-type td {
            padding-bottom: 0;
          }
          th {
            font-weight: normal;
            width: 160px;
            text-align: right;
            padding-left: 20px;
            padding-right: 20px;
          }
          td {
            padding-bottom: 20px;
            padding-right: 20px;
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
    .newlets_info {
      width: 100%;
      font-size: 20px;
      margin-bottom: 0;
      tbody {
        vertical-align: top;
        tr {
          &:last-of-type td {
            padding-bottom: 0;
          }
          th {
            font-weight: bold;
            text-align: left;
            padding: 25px 3px 0 40px;
            vertical-align: middle;
          }
          td {
            padding: 25px 40px 0 0;
            vertical-align: middle;
            span {
              font-weight: bold;
              font-size: 30px;
            }
          }
        }
      }
    }
    .f_more {
      display: none;
      &__btn {
        display: block;
        text-align: center;
        height: 45px;
        line-height: 45px;
        border: 1px solid #dddddd;
        cursor: pointer;
        margin-top: 20px;
        color: #35aae7;
      }
    }
    .f_type {
      display: inline-block;
      background: #f2f2f2;
      padding: 3px 10px;
      color: #666;
      &__std {
        border-left: 5px solid #fff700;
      }
      &__pro {
        border-left: 5px solid #ff4d00;
      }
      &__w1g {
        border-left: 5px solid #ff00c8;
      }
      &__w2g {
        border-left: 5px solid #a900ff;
      }
      &__com {
        border-left: 5px solid #00b2ff;
      }
    }
    //基本情報↑
    //よくある質問Q&A↓
    .qanda {
      dt {
        position: relative;
        padding: 25px 60px;
        font-size: 20px;
        font-weight: bold;
        border-bottom: 1px solid #ddd;
        color: #444;
        cursor: pointer;
        @media only screen and (max-width: $breakpoint) {
          padding: 25px 45px;
        }
        .question {
          position: absolute;
          left: 0;
        }
        i {
          display: inline-block;
          position: absolute;
          right: 20px;
          top: 40%;
        }
      }
      .active {
        i {
          &::before {
            content: "\f106";
          }
        }
      }
      dd {
        position: relative;
        display: none;
        padding: 25px 0 60px 60px;
        @media only screen and (max-width: $breakpoint) {
          padding: 25px 0 60px 45px;
        }
        .answer {
          position: absolute;
          left: 0;
          font-size: 20px;
          font-weight: bold;
          color: #e54b4b;
        }
        table {
          border-collapse: collapse;
          margin: 0 auto;
          padding: 0;
          width: 650px;
          table-layout: fixed;
        }

        table tr {
          background-color: #fff;
          border: 1px solid #ddd;
          padding: 0.35em;
        }
        table th,
        table td {
          padding: 1em 10px 1em 1em;
          border-right: 1px solid #ddd;
        }
        table th {
          font-size: 0.85em;
        }
        table thead tr {
          background-color: #eee;
        }
        .txt {
          text-align: left;
          font-size: 0.85em;
        }
        .price {
          text-align: right;
        }
        @media screen and (max-width: 600px) {
          table {
            border: 0;
            width: 100%;
          }
          table th {
            background-color: #eee;
            display: block;
            border-right: none;
          }
          table thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
          }

          table tr {
            display: block;
            margin-bottom: 0.625em;
          }

          table td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: 0.8em;
            text-align: right;
            position: relative;
            padding: 0.625em 0.625em 0.625em 4em;
            border-right: none;
          }

          table td::before {
            content: attr(data-label);
            font-weight: bold;
            position: absolute;
            left: 10px;
          }

          table td:last-child {
            border-bottom: 0;
          }
        }
      }
    }
    //よくある質問Q&A↑
    margin-left: 30px;
    @media only screen and (max-width: $breakpoint) {
      margin-left: 0;
    }
    .r_30 {
      margin-right: 30px;
      @media only screen and (max-width: $breakpoint) {
        margin-left: 0;
      }
    }
    .main_txt {
      font-size: 14px;
      line-height: 1.7em;
      margin-bottom: 100px;
      .font_list {
        @media only screen and (max-width: $breakpoint) {
          div {
            margin-bottom: 20px;
          }
        }
        margin-top: 50px;
        font-weight: bold;
        padding-bottom: 0 !important;
        padding-left: 30px;
        a {
          position: relative;
          i {
            color: #ec2606;
            position: absolute;
            font-size: 24px;
            left: -28px;
            top: -8px;
          }
        }
        .dlbtn {
          display: inline-block;
          height: 40px;
          line-height: 40px;
          background: #4d4d4d;
          color: #fff;
          border-radius: 3px;
          padding: 0 15px 0 40px;
          margin: 0;
          position: relative;
          margin-top: 0 !important;
          margin-left: -40px;
          overflow: hidden;
          &:hover {
            background-color: #818181;
          }
          &::before {
            position: absolute;
            content: "";
            display: inline-block;
            top: -180px;
            left: 0;
            width: 30px;
            height: 100%;
            background-color: #fff;
            animation: shiny-btn1 5s ease-in-out infinite;
          }
          @keyframes shiny-btn1 {
            0% {
              -webkit-transform: scale(0) rotate(45deg);
              opacity: 0;
            }
            80% {
              -webkit-transform: scale(0) rotate(45deg);
              opacity: 0.5;
            }
            81% {
              -webkit-transform: scale(4) rotate(45deg);
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(50) rotate(45deg);
              opacity: 0;
            }
          }
          i {
            font-size: 22px;
            position: absolute;
            left: 15px;
          }
        }
      }
      .renew {
        display: block;
        margin: 60px auto 0;
      }
    } //main_txt
    .osusume_text {
      margin-bottom: 100px;
      ._txt {
        background-color: #f2cc0c;
        border-radius: 240px;
        width: 240px;
        height: 240px;
        margin-right: 40px;
        margin-bottom: 30px;
        position: relative;
        @media only screen and (max-width: $breakpoint) {
          margin: 0 auto 30px;
        }
        h3 {
          font-size: 18px;
          text-align: center;
          margin-top: 62px;
          margin-bottom: 0;
          color: #111111;
          @media only screen and (max-width: $breakpoint) {
            margin-top: 0;
            padding-top: 57px;
            margin-bottom: 0;
          }
        }
        div {
          padding: 25px;
        }
      }
    } //osusume_text
    .fee_text {
      margin-bottom: 150px;
    }
    .fee_text2 {
      margin-bottom: 150px;
      max-width: 800px;
      margin-right: auto;
      margin-left: auto;
      .t_p {
        font-size: 18px;
        color: #444444;
        margin-top: 0;
      }
      .color_mojipass01 {
        color: #a72227 !important;
      }
      .color_mojipass02 {
        color: #cf6385 !important;
      }
      .color_mojipass03 {
        color: #012167 !important;
      }
      .color_license_def {
        color: #f2cc0c !important;
      }
      .font26 {
        font-size: 26px;
      }
      .title-border {
        display: flex;
        align-items: center;
      }
      .title-border:before,
      .title-border:after {
        border-top: 2px solid;
        content: "";
        width: 20px;
        margin: 0 auto;
      }
      .title-border:before {
        margin-right: 1rem;
      }
      .title-border:after {
        margin-left: 1rem;
      }
      .title-border {
        display: flex;
        align-items: center;
      }
    }
    .pricebox_showalets {
      display: block;
      margin: 0 auto;
      border: 4px solid #ddd;
      position: relative;
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        max-width: 360px;
        margin-bottom: 20px;
      }
      .pricebox_top {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        height: 50%;
        border-bottom: 1px solid #ddd;
        padding: 19px 20px 17px;
        &::after {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          border-left: 12px solid transparent;
          border-right: 12px solid transparent;
          border-top: 12px solid #ddd;
          bottom: -12px;
          z-index: 1;
        }
        &::before {
          content: "";
          width: 0;
          height: 0;
          position: absolute;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-top: 10px solid #fff;
          bottom: -10px;
          z-index: 2;
        }
        .obi01 {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 100px solid #e54b4b;
          border-top: 0 solid transparent;
          border-bottom: 100px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }

        .obititle {
          color: #fff;
          font-weight: bold;
          font-size: 15px;
          position: absolute;
          top: 5px;
          left: 4px;
          .font28 {
            font-size: 28px;
          }
          .font14 {
            font-size: 14px;
          }
        } //obititle
      } //pricebox_top
      .pricebox_bottom {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50%;
        position: relative;
        padding: 20px;
      }
      .price_title {
        display: block;
        width: 83px;
        height: 38px;
        text-align: center;
        color: #111;
        font-size: 22px;
        font-weight: bold;
        line-height: 38px;
      }
      .price {
        display: block;
        font-size: 14px;
        font-weight: bold;
        span {
          display: inline;
          font-size: 34px;
        }
      }
      .price_desc {
        font-size: 12px;
        line-height: 1.8em;
        text-align: center;
        &__red {
          color: #e54b4b;
          &__bold {
            color: #e54b4b;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
      .navi_btn {
        display: block;
        width: 240px;
        height: 40px;
        background: #4d4d4d;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        position: relative;
        margin-top: 10px;
        &:hover {
          background-color: #818181;
        }
        i {
          position: absolute;
          display: block;
          top: 0;
          right: 10px;
          font-size: 24px;
          color: #777;
        }
      }
      p {
        width: 210px;
        display: block;
      }
    } //pricebox_showalets

    .pricebox {
      display: block;
      width: 340px;
      height: 440px;
      margin: 0 auto;
      border: 4px solid #ddd;
      position: relative;
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        max-width: 340px;
        margin-bottom: 20px;
      }
      &::after {
        content: "";
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: calc(50% - 24px);
        left: calc(50% - 24px);
        background-color: #ddd;
        border-radius: 48px;
        z-index: 1;
      }
      &::before {
        content: "\f067";
        font-family: "Font Awesome 6 Pro";
        width: 48px;
        height: 48px;
        position: absolute;
        bottom: calc(50% - 24px);
        left: calc(50% - 24px);
        border-radius: 48px;
        z-index: 2;
        text-align: center;
        line-height: 48px;
        font-size: 33px;
        color: #818181;
      }
      .cam_price_title {
        display: inline-block;
        background-color: #e54b4b;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        border-radius: 100px;
        font-size: 18px;
        font-weight: bold;
        padding: 0 15px;
      }
      .pricebox_top {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        position: relative;
        height: 50%;
        border-bottom: 1px solid #ddd;
        padding-bottom: 48px;
        .obi01 {
          color: #111;
          width: 0;
          height: 0;
          border-left: 111px solid #f2cc0c;
          border-top: 0 solid transparent;
          border-bottom: 135px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi03 {
          color: #111;
          width: 0;
          height: 0;
          border-left: 111px solid #f2880c;
          border-top: 0 solid transparent;
          border-bottom: 135px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obititle {
          color: #111;
          font-weight: bold;
          font-size: 15px;
          position: absolute;
          top: -5px;
          left: 7px;
          .font40 {
            font-size: 40px;
          }
          .font22 {
            font-size: 22px;
          }
        } //obititle
      } //pricebox_top
      .pricebox_bottom {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        height: 50%;
        position: relative;
        padding-top: 48px;
      }
      .price_title {
        display: block;
        background-color: #818181;
        width: 83px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        border-radius: 100px;
        font-size: 18px;
        font-weight: bold;
      }
      .price {
        display: block;
        font-size: 20px;
        font-weight: bold;
        span {
          display: inline;
          font-size: 40px;
        }
      }
      .price_desc {
        font-size: 14px;
        font-weight: bold;
        &__red {
          color: #e54b4b;
        }
      }
      p {
        width: 210px;
        display: block;
      }
    } //pricebox
    .pricebox2_head {
      height: 50px;
      width: 340px;
      background-color: #666;
      color: #fff;
      font-size: 18px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
    }
    .pricebox2 {
      display: block;
      width: 340px;
      height: 300px;
      margin: 0 auto 50px;
      border: 4px solid #ddd;
      position: relative;
      @media only screen and (max-width: $breakpoint) {
        width: 100%;
        max-width: 340px;
        margin-bottom: 20px;
      }
      &__dammy {
        display: block;
        width: 340px;
        height: 300px;
        margin: 0 auto 50px;
        position: relative;
        @media only screen and (max-width: $breakpoint) {
          width: 0;
          height: 0;
          margin: 0;
        }
      }

      .pricebox_inner {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        position: relative;
        height: 100%;
        .obi_mojipass {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 100px solid #cf6385;
          border-top: 0 solid transparent;
          border-bottom: 100px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_mojipassb {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 100px solid #012167;
          border-top: 0 solid transparent;
          border-bottom: 100px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_nis01 {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 100px solid #f2cc0c;
          border-top: 0 solid transparent;
          border-bottom: 100px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_nis02 {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 100px solid #818181;
          border-top: 0 solid transparent;
          border-bottom: 100px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_dynav {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 125px solid #ec6d1e;
          border-top: 0 solid transparent;
          border-bottom: 125px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_dynat {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 125px solid #01a0eb;
          border-top: 0 solid transparent;
          border-bottom: 125px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi_dynas {
          color: #fff;
          width: 0;
          height: 0;
          border-left: 125px solid #86529d;
          border-top: 0 solid transparent;
          border-bottom: 125px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi01 {
          color: #111;
          width: 0;
          height: 0;
          border-left: 111px solid #f2cc0c;
          border-top: 0 solid transparent;
          border-bottom: 135px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obi03 {
          color: #111;
          width: 0;
          height: 0;
          border-left: 111px solid #f2880c;
          border-top: 0 solid transparent;
          border-bottom: 135px solid transparent;
          position: absolute;
          left: -4px;
          top: -4px;
        }
        .obititle {
          color: #fff;
          font-weight: bold;
          font-size: 15px;
          position: absolute;
          top: -5px;
          left: 7px;
          .font40 {
            font-size: 40px;
          }
          .font22 {
            font-size: 22px;
          }
          .font20 {
            font-size: 20px;
          }
          .font26 {
            font-size: 26px;
          }
        } //obititle
        .obititle2 {
          color: #111;
          font-weight: bold;
          font-size: 15px;
          position: absolute;
          top: -5px;
          left: 7px;
          .font40 {
            font-size: 40px;
          }
          .font22 {
            font-size: 22px;
          }
          .font20 {
            font-size: 20px;
          }
          .font26 {
            font-size: 26px;
          }
        }
      } //pricebox_inner
      .price_title {
        display: inline-block;
        background-color: #818181;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        border-radius: 100px;
        font-size: 18px;
        font-weight: bold;
        padding: 0 15px;
      }
      .cam_price_title {
        display: inline-block;
        background-color: #e54b4b;
        height: 38px;
        line-height: 38px;
        text-align: center;
        color: #fff;
        border-radius: 100px;
        font-size: 18px;
        font-weight: bold;
        padding: 0 15px;
      }
      .price {
        display: block;
        font-size: 20px;
        font-weight: bold;
        span {
          display: inline;
          font-size: 40px;
        }
      }
      .camlb {
        background-color: #e54b4b;
        color: #fff;
        padding: 0.5em;
        margin-bottom: 0.5em;
      }
      .price_desc {
        font-size: 14px;
        font-weight: bold;
        color: #a5a5a5;
        &__red {
          color: #e54b4b;
          position: relative;
          .tagnew {
            font-size: 12px;
            position: absolute;
            background: #f2cc0c;
            color: #ffffff;
            border-radius: 30px;
            width: 35px;
            height: 35px;
            line-height: 36px;
            top: 0;
            left: -36px;
            display: inline-block;
            text-align: center;
          }
          .tagupdate {
            font-size: 12px;
            position: absolute;
            background: #818181;
            color: #ffffff;
            border-radius: 30px;
            width: 35px;
            height: 35px;
            line-height: 36px;
            top: 0;
            left: -36px;
            display: inline-block;
            text-align: center;
          }
        }
      }
      .navi_btn {
        display: block;
        width: 180px;
        height: 50px;
        background: #4d4d4d;
        color: #fff;
        margin: 0 auto;
        text-align: center;
        line-height: 50px;
        border-radius: 2px;
        position: relative;
        margin-top: 10px;
        &:hover {
          background-color: #818181;
        }
        i {
          position: absolute;
          display: block;
          top: 0;
          right: 10px;
          font-size: 24px;
          color: #777;
        }
      }
      p {
        width: 210px;
        display: block;
      }
    } //pricebox2
    .cate105 {
      margin: 0 auto 130px !important;
      display: block;
    }
    @media only screen and (max-width: $breakpoint) {
      .table_outer {
        overflow: scroll;
      }
    }
    .hikaku,
    .hikaku2 {
      width: 830px;
      font-size: 14px;
      line-height: 2em;
      font-weight: bold;
      .menu01 {
        background-color: #999;
        vertical-align: bottom;
        color: #fff;
        height: 65px;
        td {
          padding: 15px 10px;
          border: 1px solid #ddd;
          border-top: none;
          span {
            font-weight: normal;
          }
        }
      }
      .menu02 {
        background-color: #999;
        vertical-align: top;
        color: #fff;
        height: 43px;
        td {
          padding: 0px 10px;
          border: 1px solid #ddd;
          border-top: none;
        }
      }
      .menu03 {
        background-color: #999;
        vertical-align: middle;
        color: #fff;
        height: 43px;
        th {
          border-right: 1px solid #ddd;
        }
        td {
          border-right: 1px solid #ddd;
          &:last-of-type {
            border-right: none;
          }
        }
      }
      .list {
        color: #444;
        font-weight: normal;
        &:nth-of-type(even) {
          background: #f2f2f2;
        }
        span {
          font-size: 12px;
        }
        th {
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          background-color: #fff !important;
          vertical-align: top;
          padding: 28px 25px 25px;
          font-weight: normal;
          text-align: left;
          background: #fff;
          a {
            display: block;
            img {
              vertical-align: top;
            }
            &:hover {
              opacity: 0.777;
            }
          }
        }
        td {
          padding: 20px 10px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          vertical-align: top;
          &:last-of-type {
            border-right: none;
          }
        }
      }
    }
    .hikaku2 {
      .list {
        td {
          vertical-align: middle !important;
        }
      }
    }
    .hikaku_note {
      margin: 30px 0 130px;
      color: #444;
      line-height: 2em;
    }
    //ダイナスマート
    .color_dynav {
      background-color: rgb(236, 109, 30);
    }
    .color_dynat {
      background-color: rgb(1, 160, 235);
    }
    .color_dynas {
      background-color: rgb(134, 82, 157);
    }
    .dyna_h3 {
      font-size: 18px;
      color: #fff;
      padding: 6px 1em;
      border-radius: 6px;
    }
    .dyna_pbr {
      border-right: 4px solid #ddd;
      @media only screen and (max-width: $breakpoint) {
        border-right: none;
      }
    }
    .dyna_pbb {
      border-bottom: 4px solid #ddd;
      @media only screen and (max-width: $breakpoint) {
        border-bottom: none;
      }
    }
  } //catpage_detail02

  @media only screen and (max-width: $breakpoint) {
    h2 {
      font-size: 24px;
      text-align: left;
      font-weight: bold;
      margin: 50px 0 0;
    }
    .catpage_detail {
      .main_txt {
        font-size: 18px;
        line-height: 1.7em;
        margin-bottom: 50px;
      }
      .sc_navi {
        margin: 0 8px !important;
        ul {
          li {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            padding: 0;
            a {
              display: -webkit-box;
              display: -webkit-flex;
              display: -moz-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              justify-content: center;
              background: #f2f2f2;
              color: #444;
              margin: 0 0 14px;
              width: 100%;
              font-size: 14px;
              position: relative;
              height: 70px;
              &:after {
                position: absolute;
                content: "\f107";
                font-size: 21px;
                font-family: "Font Awesome 6 Pro";
                font-weight: 100;
                color: #111;
                right: 16px;
                top: 22px;
              }
              &:hover {
                color: #35aae7;
              }
            }
          }
        }
      }
      .lineup_navi {
        margin: 0 8px !important;
        padding-bottom: 122px !important;
        ul {
          li {
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            padding: 0;
            a {
              display: -webkit-box;
              display: -webkit-flex;
              display: -moz-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -webkit-align-items: center;
              -moz-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              justify-content: center;
              height: 120px;
              color: #444;
              margin: 0 0 28px;
              width: 100%;
              font-size: 14px;
              position: relative;
              font-weight: bold;
              line-height: 1.8em;
              box-shadow: 0 0 0 3px #f2f2f2 inset;
              &:after {
                display: block;
                position: absolute;
                content: "\f105";
                font-size: 21px;
                font-family: "Font Awesome 6 Pro";
                font-weight: 100;
                color: #111;
                right: 16px;
              }
              i {
                display: none;
              }
              img {
                width: 230px;
                height: auto;
              }
              &:hover {
                color: #35aae7;
                box-shadow: 0 0 0 5px #ddd inset;
              }
              .cam_obi {
                position: absolute;
                top: -10px;
                left: -10px;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                font-size: 9px;
                background: #df3c3c;
                color: #fff;
                text-align: center;
                line-height: 14px;
                padding: 6px 4px;
                display: block;
                z-index: 1;
              } //cam_obi
              .p_obi {
                position: absolute;
                top: -10px;
                left: -10px;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                font-size: 9px;
                background: #ffcc00;
                color: #444;
                text-align: center;
                line-height: 32px;
                padding: 4px;
                display: block;
                z-index: 1;
              }
            }
          }
        }
      } //lineup_navi
    }
  } //brakepoint
} //catpage_def
.open_cambackground {
  cursor: pointer;
}
//キャンペーン別窓
.cambackground {
  background-color: rgba(68, 68, 68, 0.76);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: scroll;
  padding: 60px;
  overflow-x: hidden;
  transform: translateZ(1px);
  @media only screen and (max-width: $breakpoint) {
    padding: 30px;
  }
  .cammain {
    width: 910px;
    max-width: 100%;
    background-color: #308fcc;
    position: relative;
    .price_box {
      max-width: 740px;
      margin-left: auto;
      margin-right: auto;
      border-radius: 5px;
      padding-top: 25px;
      background-color: #fff;
      margin-bottom: 40px;
      @media only screen and (max-width: $breakpoint) {
        padding-top: 10px;
        margin-bottom: 10px;
      }
    }
    .letscam {
      padding: 40px;
      @media only screen and (max-width: $breakpoint) {
        padding: 20px;
      }
    }
    .camclose {
      display: block;
      width: 60px;
      height: 60px;
      position: absolute;
      top: -30px;
      right: -30px;
      z-index: 20000;
      background: #444;
      border-radius: 60px;
      cursor: pointer;
      text-align: center;
      line-height: 64px;
      color: #fff;
      font-size: 24px;
      @media only screen and (max-width: $breakpoint) {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        top: -15px;
        right: -15px;
        line-height: 32px;
        font-size: 12px;
      }
    }
  }
}
.haku_navi_btn2 {
  display: block;
  height: 58px;
  background: #444;
  color: #f2f2f2;
  margin: 0 10px 13px;
  width: 252px;
  max-width: 100%;
  font-size: 18px;
  position: relative;
  margin: 0 auto;
  line-height: 58px;
  &::after {
    position: absolute;
    content: "\f105";
    font-size: 24px;
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
    right: 16px;
    top: -1px;
    color: #777;
  }
  &:hover {
    background-color: #818181;
    color: #f2f2f2;
  }
}
.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}

//morisawafonts表
.morifonts {
  　.tbl-m01 {
    border-collapse: separate;
    border-spacing: 5px;
    font-size: 14px;
    text-align: center;
  }
  .tbl-m01 {
    border-spacing: 2px;
    width: 100%;
  }
  .tbl-m01 th {
    border: none;
    background-color: #828c97;
    color: #fff;
    padding: 14px;
    text-align: center;
  }
  .tbl-m01 td {
    background: #eff0f4;
    color: #444;
    border: none;
    padding: 14px;
    text-align: center;
    span {
      color: #818181;
      font-size: 10px;
      display: inline-block;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .tbl-m01 th {
      width: 20%;
    }
    .tbl-m01 td {
      width: 25%;
    }
  }
}
.morifonts_price {
  display: block;
  .right30 {
    margin-right: 30px;
    @media only screen and (max-width: $breakpoint) {
      margin-right: unset;
    }
  }
  //価格表
  .tbl-m03 {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 5px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-spacing: 2px;
    width: calc(100% - 80px);
    margin: 0 40px;
    th {
      border: none;
      background-color: #828c97;
      color: #fff;
      padding: 14px;
      text-align: center;
    }
    td {
      background: #eff0f4;
      color: #444;
      border: none;
      padding: 14px;
      text-align: center;
      font-weight: bold;
      .price_batu {
        color: #818181;
        font-weight: unset;
        display: inline-block;
        text-decoration: line-through;
        text-decoration-color: #4d4d4d;
      }
    }
  }

  .camcontent {
    border: 1px solid #2876c8;
    font-size: 14px;

    .c_red {
      color: #e54b4b;
    }
    .con {
      padding: 55px;
      @media only screen and (max-width: $breakpoint) {
        padding: 2em;
      }
      .title_table01 {
        font-size: 20px;
        font-weight: bold;
        th {
          width: 56px;
          height: 56px;
          background-color: #2876c8;
          border-radius: 56px;
          color: #fff;
          text-align: center;
        }
        td {
          color: #2876c8;
        }
      }
      .title_table02 {
        font-size: 20px;
        font-weight: bold;
        th {
          .cer {
            width: 56px;
            height: 56px;
            background-color: #fff;
            border-radius: 56px;
            color: #2876c8;
            text-align: center;
            border: 2px solid #2876c8;
            line-height: 52px;
          }
        }
        td {
          color: #2876c8;
        }
      }
      .dec {
        font-size: 18px;
        margin: 1em 0;
      }
      .tbl-m02 {
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 5px;
        font-size: 14px;
        text-align: center;
        border-spacing: 2px;
        width: 100%;
        th {
          border: none;
          background-color: #828c97;
          color: #fff;
          padding: 14px;
          text-align: center;
        }
        td {
          background: #eff0f4;
          color: #444;
          border: none;
          font-size: 18px;
          padding: 14px;
          text-align: center;
          font-weight: bold;
          .price_batu {
            color: #818181;
            font-size: 14px;
            font-weight: unset;
            display: inline-block;
            text-decoration: line-through;
            text-decoration-color: #4d4d4d;
          }
        }
      }
      .link_dec {
        //padding: 45px 60px;
        min-height: 228px;
        background-color: #eff0f4;
        h3 {
          font-size: 24px;
          color: #3271b9;
          text-align: center;
          font-weight: bold;
        }
        p {
          font-size: 16px;
          max-width: 500px;
        }
        a:hover {
          opacity: 0.8;
        }
        .linkbtn01 {
          display: block;
          width: 250px;
          height: 60px;
          border-radius: 30px;
          background-color: #2876c8;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          line-height: 60px;
          margin-left: auto;
          margin-right: auto;
        }
        .linkbtn02 {
          display: block;
          width: 250px;
          height: 60px;
          border-radius: 30px;
          background-color: #fff;
          color: #2876c8;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          line-height: 58px;
          margin-left: auto;
          margin-right: auto;
          border: 2px solid #2876c8;
        }
      }
    }
  }
}
.morifonts_n01 {
  padding: 35px;
  background-color: #eff0f4;
  background-color: #fff7f7;
  margin: 0 10px;
  color: #e54b4b;
  font-size: 16px;
  a:hover {
    opacity: 0.8;
  }
  @media(min-width: 768px){
    a[href^="tel:"]{
      pointer-events: none;
    }
  }
  p {
    line-height: 1.7em;
  }
  hr {
    margin: 30px 0;
    border-top: 1px solid #e54b4b;
  }
  .linkbtn02 {
    display: block;
    width: 250px;
    height: 60px;
    border-radius: 30px;
    background-color: #e54b4b;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}
.morifonts_n02 {
  padding: 35px;
  background-color: #2876c8;
  margin: 0 10px;
  color: #fff;
  font-size: 16px;
  a:hover {
    opacity: 0.8;
  }
  p {
    line-height: 1.7em;
  }
  hr {
    margin: 30px 0;
    border-top: 1px solid #e54b4b;
  }
  .linkbtn02 {
    display: block;
    width: 250px;
    height: 60px;
    border-radius: 30px;
    background-color: #ffe43e;
    color: #2876c8;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    line-height: 60px;
    margin-left: auto;
    margin-right: auto;
  }
}
.morih3sub {
  color: #3271b9;
  font-size: 14px;
}
.moridesch3 {
  font-size: 18px;
  color: #3271b9;
  font-weight: bold;
}
.imover:hover {
  opacity: 0.8;
}
.fcolor_red {
  color: #e54b4b;
}
.cambanatop {
  display: block;
  height: 78px;
  background: #ffe303;
  color: #2876c8;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 78px;
  position: relative;
  &::after {
	position: absolute;
    border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #ffe43e;
    opacity: 1;
    content: "";
	bottom: -30px;
	left: calc(50% - 15px);
  }
}
.morisawa-dec {
  margin: 0;
  background-color: color(srgb 0.1826 0.5591 0.798);
  min-height: 408px;
  color: #fff;

  .inner {
    max-width: 593px;
    margin: 30px;
    h2{
      font-size: 2.5rem;
      text-align: center;
    }
    p{
      font-size: 18px;
      line-height: 1.75em;
      font-weight: 100;
    }
    a{
      text-decoration: underline;
      text-underline-offset: 5px;
      color: #fff;
    }
  }
}
/* --------------------------------
会員登録
-------------------------------- */
.agree_space {
  background-color: #f4f4f4;
  padding: 10px;
  display: block;
  width: 100%;
  text-align: center;
  iframe {
    background-color: #fff;
  }
  .agree_block {
    display: inline-block;
    text-align: center;
    width: 280px;
    padding: 5px;
    background: #fff;
    label {
      line-height: 28px;
      margin-bottom: 0;
    }
    .ec-errorMessage {
      margin-bottom: 5px;
    }
  }
  .member_agree_text {
    display: block;
    background-color: #fff;
    width: 100%;
    height: 250px;
    text-align: left;
    overflow: auto;
    font-size: 13px;
    padding: 8px;
    margin-bottom: 10px;
  }
}
.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}
.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
