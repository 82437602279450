@charset "UTF-8";
@import "csss/style.css";
body {
  color: #525263;
  transition: z-index 0ms 5.28455ms;
  background: #fff;
  margin: 0;
}

a {
  text-decoration: none;
}

pre {
  background-color: transparent;
  border: none;
  padding: 16px 0;
}

p {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
}

/**
メディアクエリ
SP フォーストで記述する。
Twitter Bootstrap デフォルト準拠
 */
/**
 * ECCUBE 固有のスタイルユーティリティ
 */
/*
カート

カート 注文詳細 に関する Project コンポーネントを定義します。

ex [カートページ](http://demo3.ec-cube.net/shopping)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartRole

Styleguide 15.1

*/
.ec-cartRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.ec-cartRole:after {
  content: " ";
  display: table;
}
.ec-cartRole:after {
  clear: both;
}
.ec-cartRole textarea { /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartRole img {
  max-width: 100%;
}
.ec-cartRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartRole *,
.ec-cartRole *::before,
.ec-cartRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartRole img {
  width: 100%;
}
.ec-cartRole::before {
  display: none;
}
.ec-cartRole .ec-cartRole__progress {
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error {
  width: 100%;
  text-align: center;
}
.ec-cartRole .ec-cartRole__error .ec-alert-warning {
  max-width: 80%;
  display: inline-block;
}
.ec-cartRole .ec-cartRole__totalText {
  margin-bottom: 0;
  padding: 16px 0 6px;
  width: 100%;
  text-align: center;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalText {
    margin-bottom: 30px;
    padding: 0;
  }
}
.ec-cartRole .ec-cartRole__cart {
  margin: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__cart {
    margin: 0 10%;
  }
}
.ec-cartRole .ec-cartRole__actions {
  text-align: right;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__actions {
    width: 30%;
    margin-right: 10%;
  }
}
.ec-cartRole .ec-cartRole__total {
  padding: 15px 0 30px;
  font-weight: bold;
  font-size: 16px;
}
.ec-cartRole .ec-cartRole__totalAmount {
  margin-left: 30px;
  color: #de5d50;
  font-size: 16px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRole .ec-cartRole__totalAmount {
    font-size: 24px;
  }
}
.ec-cartRole .ec-blockBtn--action {
  margin-bottom: 10px;
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品をを表示するテーブル枠です。

ex [カートページ　テーブル部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
+ec-cartTable

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>

Styleguide 15.1.2
*/
.ec-cartTable {
  display: table;
  border-top: 1px dotted #ccc;
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .ec-cartTable {
    border-top: none;
  }
}

/*
カート商品表示枠（テーブルヘッダ）

カート内の商品を表示するテーブルのヘッダです。
スマホでは非表示となります。

ex [カートページ　カートテーブルヘッダ部分(カート内に商品がある状態でアクセス)](http://demo3.ec-cube.net/cart)


Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartHeader

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.3
*/
.ec-cartHeader {
  display: none;
  width: 100%;
  background: #F4F3F0;
}
@media only screen and (min-width: 768px) {
  .ec-cartHeader {
    display: table-row;
  }
}
.ec-cartHeader .ec-cartHeader__label {
  display: table-cell;
  padding: 16px;
  text-align: center;
  background: #F4F3F0;
  overflow-x: hidden;
  font-weight: bold;
}

.ec-cartCompleteRole {
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.4;
  color: #525263;
  -webkit-text-size-adjust: 100%;
  width: 100%;
  max-width: 1130px;
}
.ec-cartCompleteRole:after {
  content: " ";
  display: table;
}
.ec-cartCompleteRole:after {
  clear: both;
}
.ec-cartCompleteRole textarea { /* for chrome fontsize bug */
  font-family: sans-serif;
}
.ec-cartCompleteRole img {
  max-width: 100%;
}
.ec-cartCompleteRole html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.ec-cartCompleteRole *,
.ec-cartCompleteRole *::before,
.ec-cartCompleteRole *::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}
.ec-cartCompleteRole img {
  width: 100%;
}

/*
カート内商品

カート内のアイテムを表示するテーブル行です。
スマホでは非表示となります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRow

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.4
*/
.ec-cartRow {
  display: table-row;
}
.ec-cartRow .ec-cartRow__delColumn {
  border-bottom: 1px dotted #ccc;
  text-align: center;
  display: table-cell;
  width: 14%;
  vertical-align: middle;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn {
    width: 8.3333333%;
  }
}
.ec-cartRow .ec-cartRow__delColumn .ec-icon img {
  width: 1.5em;
  height: 1.5em;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__delColumn .ec-icon img {
    width: 1em;
    height: 1em;
  }
}
.ec-cartRow .ec-cartRow__contentColumn {
  border-bottom: 1px dotted #ccc;
  padding: 10px 0;
  display: table;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__contentColumn {
    display: table-cell;
  }
}
.ec-cartRow .ec-cartRow__img {
  display: table-cell;
  width: 40%;
  vertical-align: middle;
  padding-right: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__img {
    display: inline-block;
    min-width: 80px;
    max-width: 100px;
    padding-right: 0;
  }
}
.ec-cartRow .ec-cartRow__summary {
  display: table-cell;
  margin-left: 5px;
  font-weight: bold;
  vertical-align: middle;
  width: 46%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary {
    display: inline-block;
    width: calc(100% - 130px);
    margin-left: 20px;
    vertical-align: middle;
  }
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__name {
  margin-bottom: 5px;
}
.ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
  display: block;
  font-weight: normal;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__summary .ec-cartRow__sutbtotalSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn {
  display: table-cell;
  border-bottom: 1px dotted #ccc;
  vertical-align: middle;
  text-align: center;
  width: 20%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn {
    width: 16.66666667%;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
  display: none;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amount {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
  display: block;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountSP {
    display: none;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpDown {
    display: block;
  }
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountUpButton .ec-cartRow__amountUpButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  margin: 0 2px;
  display: inline-block;
  border: 2px solid #c9c9c9;
  border-radius: 50%;
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
  cursor: pointer;
  line-height: 40px;
  vertical-align: middle;
  position: relative;
  text-align: center;
  background: #fff;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButton .ec-cartRow__amountDownButton__icon img, .ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled .ec-cartRow__amountDownButton__icon img {
  display: block;
  margin-left: -0.4em;
  width: 0.8em;
  height: 0.8em;
  position: absolute;
  top: 28%;
  left: 50%;
}
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}
.ec-cartRow .ec-cartRow__subtotalColumn {
  display: none;
  border-bottom: 1px dotted #ccc;
  text-align: right;
  width: 16.66666667%;
}
@media only screen and (min-width: 768px) {
  .ec-cartRow .ec-cartRow__subtotalColumn {
    display: table-cell;
    vertical-align: middle;
  }
}
.ec-cartRow .ec-cartRow__subtotalColumn div {
  margin-bottom: 10px;
}

/*
カート内商品(商品が１の場合)

商品が１の場合はカート商品を減らす「-」ボタンの無効化状態になります。

ex [カートページ　テーブル部分](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartTable
  +ec-cartRowOnly

sg-wrapper:
<div class="ec-cartRole">
  <sg-wrapper-content/>
</div>


Styleguide 15.1.5
*/
.ec-cartRow .ec-cartRow__amountColumn .ec-cartRow__amountDownButtonDisabled {
  cursor: default;
}

/*
アラート

カート内の商品に問題があることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-cartRole
  .ec-cartRole__cart
    +ec-alert-warning

Styleguide 15.1.6
*/
.ec-alert-warning {
  width: 100%;
  padding: 10px;
  text-align: center;
  background: #F99;
  margin-bottom: 20px;
}
.ec-alert-warning .ec-alert-warning__icon {
  display: inline-block;
  margin-right: 1rem;
  width: 20px;
  height: 20px;
  color: #fff;
  fill: #fff;
  vertical-align: top;
}
.ec-alert-warning .ec-alert-warning__text {
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  position: relative;
}

/*
アラート(空)

カートが空であることを示す警告メッセージです。

ex [マイページ　カート](http://demo3.ec-cube.net/cart)

(カート内に商品がある状態でアクセス)

Markup:
include /assets/tmpl/elements/15.1.cart.pug
.ec-off3Grid
        .ec-off3Grid__cell
            +ec-alert-warningEnpty

Styleguide 15.1.7
*/
/* LESS Document => SCSS Document fgoriginal_h_f_r */
/************************************************
モバイルのブレイクポイント
************************************************ */
/************************************************
 各ページコンテンツ用
************************************************ */
@media only screen and (max-width: 767px) {
  .pc {
    display: none !important;
  }
}
@media only screen and (min-width: 767px) {
  .pc {
    display: block !important;
  }
}

@media only screen and (max-width: 767px) {
  .sp {
    display: block !important;
  }
}
@media only screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .pc-inb {
    display: none !important;
  }
}
@media only screen and (min-width: 767px) {
  .pc-inb {
    display: inline-block !important;
  }
}

@media only screen and (max-width: 767px) {
  .sp-inb {
    display: inline-block !important;
  }
}
@media only screen and (min-width: 767px) {
  .sp-inb {
    display: none !important;
  }
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}

.font22 {
  font-size: 22px !important;
}

.bold {
  font-weight: bold;
}

.text_l {
  text-align: left;
}

.text_c {
  text-align: center;
}

.text_r {
  text-align: right;
}

.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.ov-v {
  overflow: visible;
}

.i_b_reset {
  font-size: 0;
  letter-spacing: 0;
}

.flexbox {
  display: flex;
}

.line15em {
  line-height: 1.5em !important;
}

.line2em {
  line-height: 2em !important;
}

/* Margin
--------------------------------------------- */
.no-margin {
  margin: 0 !important;
}

@media only screen and (max-width: 767px) {
  .spmb0 {
    margin-bottom: 0 !important;
  }
  .spmb10 {
    margin-bottom: 10px !important;
  }
  .spmb20 {
    margin-bottom: 20px !important;
  }
  .spmb30 {
    margin-bottom: 30px !important;
  }
  .spmb40 {
    margin-bottom: 40px !important;
  }
  .spmb45 {
    margin-bottom: 45px !important;
  }
  .spmb70 {
    margin-bottom: 70px !important;
  }
  .spmb60 {
    margin-bottom: 60px !important;
  }
  .spmt30 {
    margin-top: 30px !important;
  }
  .spmt70 {
    margin-top: 70px !important;
  }
}
@media only screen and (min-width: 767px) {
  .pcmt0 {
    margin-top: 0px !important;
  }
  .pcmt10 {
    margin-top: 10px !important;
  }
  .pcmt30 {
    margin-top: 30px !important;
  }
  .pcmt50 {
    margin-top: 50px !important;
  }
  .pcmt60 {
    margin-top: 60px !important;
  }
  .pcmt70 {
    margin-top: 70px !important;
  }
  .pcmt90 {
    margin-top: 90px !important;
  }
  .pcmb10 {
    margin-bottom: 10px !important;
  }
  .pcmb20 {
    margin-bottom: 20px !important;
  }
  .pcmb30 {
    margin-bottom: 30px !important;
  }
  .pcmb40 {
    margin-bottom: 40px !important;
  }
  .pcmb45 {
    margin-bottom: 45px !important;
  }
  .pcmb50 {
    margin-bottom: 50px !important;
  }
  .pcmb60 {
    margin-bottom: 60px !important;
  }
  .pcmb70 {
    margin-bottom: 70px !important;
  }
  .pcmb80 {
    margin-bottom: 80px !important;
  }
  .pcmb90 {
    margin-bottom: 90px !important;
  }
  .pcmb100 {
    margin-bottom: 100px !important;
  }
  .pcmb110 {
    margin-bottom: 110px !important;
  }
  .pcmb150 {
    margin-bottom: 150px !important;
  }
  .pcmr30 {
    margin-right: 30px !important;
  }
  .pcpl60 {
    padding-left: 60px !important;
  }
  .pcdynapad {
    padding: 60px 30px !important;
  }
}
.mt00 {
  margin-top: 0 !important;
}

.mt05 {
  margin-top: 5px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt12 {
  margin-top: 12px !important;
}

.mt15 {
  margin-top: 15px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt25 {
  margin-top: 25px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mt35 {
  margin-top: 35px !important;
}

.mt40 {
  margin-top: 40px !important;
}

.mt45 {
  margin-top: 45px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt60 {
  margin-top: 60px !important;
}

.mt70 {
  margin-top: 70px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt90 {
  margin-top: 90px !important;
}

.mr00 {
  margin-right: 0 !important;
}

.mr05 {
  margin-right: 5px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr12 {
  margin-right: 12px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.mr25 {
  margin-right: 25px !important;
}

.mr30 {
  margin-right: 30px !important;
}

.mr35 {
  margin-right: 35px !important;
}

.mr40 {
  margin-right: 40px !important;
}

.mr45 {
  margin-right: 45px !important;
}

.mr50 {
  margin-right: 50px !important;
}

.mb2em {
  margin-bottom: 2em;
}

.mb00 {
  margin-bottom: 0 !important;
}

.mb05 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb12 {
  margin-bottom: 12px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb25 {
  margin-bottom: 25px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb35 {
  margin-bottom: 35px !important;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb45 {
  margin-bottom: 45px !important;
}

.mb50 {
  margin-bottom: 50px !important;
}

.mb55 {
  margin-bottom: 55px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb65 {
  margin-bottom: 65px !important;
}

.mb70 {
  margin-bottom: 70px !important;
}

.mb75 {
  margin-bottom: 75px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mb85 {
  margin-bottom: 85px !important;
}

.mb90 {
  margin-bottom: 90px !important;
}

.mb95 {
  margin-bottom: 95px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mb105 {
  margin-bottom: 105px !important;
}

.mb110 {
  margin-bottom: 110px !important;
}

.mb115 {
  margin-bottom: 115px !important;
}

.mb120 {
  margin-bottom: 120px !important;
}

.ml00 {
  margin-left: 0 !important;
}

.ml05 {
  margin-left: 5px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml12 {
  margin-left: 12px !important;
}

.ml15 {
  margin-left: 15px !important;
}

.ml20 {
  margin-left: 20px !important;
}

.ml25 {
  margin-left: 25px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.ml35 {
  margin-left: 35px !important;
}

.ml40 {
  margin-left: 40px !important;
}

.ml45 {
  margin-left: 45px !important;
}

.ml50 {
  margin-left: 50px !important;
}

/* Padding
--------------------------------------------- */
.no-padding {
  padding: 0 !important;
}

.pt00 {
  padding-top: 0 !important;
}

.pt05 {
  padding-top: 5px !important;
}

.pt10 {
  padding-top: 10px !important;
}

.pt12 {
  padding-top: 12px !important;
}

.pt15 {
  padding-top: 15px !important;
}

.pt20 {
  padding-top: 20px !important;
}

.pt25 {
  padding-top: 25px !important;
}

.pt30 {
  padding-top: 30px !important;
}

.pt35 {
  padding-top: 35px !important;
}

.pt40 {
  padding-top: 40px !important;
}

.pt45 {
  padding-top: 45px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pr00 {
  padding-right: 0 !important;
}

.pr05 {
  padding-right: 5px !important;
}

.pr10 {
  padding-right: 10px !important;
}

.pr12 {
  padding-right: 12px !important;
}

.pr15 {
  padding-right: 15px !important;
}

.pr20 {
  padding-right: 20px !important;
}

.pr25 {
  padding-right: 25px !important;
}

.pr30 {
  padding-right: 30px !important;
}

.pr35 {
  padding-right: 35px !important;
}

.pr40 {
  padding-right: 40px !important;
}

.pr45 {
  padding-right: 45px !important;
}

.pr50 {
  padding-right: 50px !important;
}

.pb00 {
  padding-bottom: 0 !important;
}

.pb05 {
  padding-bottom: 5px !important;
}

.pb10 {
  padding-bottom: 10px !important;
}

.pb12 {
  padding-bottom: 12px !important;
}

.pb15 {
  padding-bottom: 15px !important;
}

.pb20 {
  padding-bottom: 20px !important;
}

.pb25 {
  padding-bottom: 25px !important;
}

.pb30 {
  padding-bottom: 30px !important;
}

.pb35 {
  padding-bottom: 35px !important;
}

.pb40 {
  padding-bottom: 40px !important;
}

.pb45 {
  padding-bottom: 45px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.pl00 {
  padding-left: 0 !important;
}

.pl05 {
  padding-left: 5px !important;
}

.pl10 {
  padding-left: 10px !important;
}

.pl12 {
  padding-left: 12px !important;
}

.pl15 {
  padding-left: 15px !important;
}

.pl20 {
  padding-left: 20px !important;
}

.pl25 {
  padding-left: 25px !important;
}

.pl30 {
  padding-left: 30px !important;
}

.pl35 {
  padding-left: 35px !important;
}

.pl40 {
  padding-left: 40px !important;
}

.pl45 {
  padding-left: 45px !important;
}

.pl50 {
  padding-left: 50px !important;
}

#svgicon {
  display: block;
  width: 0;
  height: 0;
  overflow: hidden;
}

.cb {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  fill: currentColor;
}

.cb-images {
  width: 1.125em;
}

.icon-bg {
  fill: #d4d4d5;
}

.icon-with-bg {
  fill: #fff;
}

@media only screen and (max-width: 767px) {
  .container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 767px) {
  .container-fluid {
    margin-left: auto;
    margin-right: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media only screen and (min-width: 767px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
  }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
@media only screen and (min-width: 767px) {
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
}

/* モバイルできるまでとりあえず処理
.col-sm-6 {
    width: 50% !important;
    float: left !important;
}
　*/
/* 共通　フォームのナビ色とフォーカス時文字を消す　*/
input::-webkit-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

/* --------------------------------
基本
-------------------------------- */
.gotoTop {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 43px;
  height: 43px;
  border: 2px solid #ffcc00;
  border-radius: 10px;
  background-color: #fff;
  line-height: 43px;
  text-align: center;
  font-size: 23px;
  color: #ffcc00;
  cursor: pointer;
  opacity: 0.777;
}
@media only screen and (max-width: 767px) {
  .gotoTop {
    right: 10px;
    bottom: 10px;
  }
}
.gotoTop:hover {
  opacity: 1;
}

.cam-fbnr {
  display: block;
  position: fixed;
  right: 30px;
  bottom: 30px;
  transform: translateY(160px);
  transition: 1s;
  z-index: 10000;
}
@media only screen and (max-width: 767px) {
  .cam-fbnr {
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #fff;
  }
}
.cam-fbnr .cam-fclose-btn {
  position: absolute;
  right: -15px;
  top: -15px;
  height: 30px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  background: #444444;
  display: inline-block;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .cam-fbnr .cam-fclose-btn {
    right: 0px;
    top: -15px;
  }
}
.cam-fbnr .cam-fclose-btn:hover {
  background-color: #666;
}
.cam-fbnr a {
  display: block;
  border: 5px solid #fc0;
}

html {
  font-size: 62.5%;
  /* 62.5%を指定すると「1.0 rem = 10px」 */
}
html body {
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Heebo", "Sawarabi Gothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "メイリオ", "Meiryo", "sans-serif";
  color: #444;
}
@media only screen and (max-width: 767px) {
  html body {
    min-width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  html body {
    min-width: 1216px;
  }
}
html body br {
  letter-spacing: normal;
  /* ← IE6、IE7対策 */
}
html body a {
  color: #35aae7;
  text-decoration: none;
  cursor: pointer;
}
html body a:hover, html body a:focus, html body a:active {
  color: #35aae7;
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
html body button:focus, html body button:active:focus {
  outline: none;
}
html body .btn:focus, html body .btn:active:focus {
  outline: none;
}
html body textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
html body ul,
html body ol {
  list-style: none;
  margin: 0;
  padding: 0;
}
html body dl,
html body dt,
html body dd,
html body li {
  margin: 0;
  padding: 0;
}
html body img {
  max-width: 100%;
  height: auto;
}
html body .price {
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
}
html body .price span {
  font-size: 18px;
  margin-right: 5px;
}

/* --------------------------------
	ヘッダーブロック
-------------------------------- */
#header {
  /* -------------- */
  background-color: #fff;
  font-family: "Heebo", "Sawarabi Gothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "メイリオ", "Meiryo", "sans-serif";
  color: #444;
  /* -------------- */
}
@media only screen and (max-width: 767px) {
  #header {
    min-width: 100%;
    overflow: hidden;
  }
}
@media only screen and (min-width: 767px) {
  #header {
    min-width: 1216px;
  }
}
#header a {
  color: #35aae7;
  text-decoration: none;
  cursor: pointer;
}
#header a:hover, #header a:focus, #header a:active {
  color: #0099e8;
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#header button:focus, #header button:active:focus {
  outline: none;
}
#header .btn:focus, #header .btn:active:focus {
  outline: none;
}
#header textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
#header .inner {
  width: 100%;
  max-width: 100%;
}
#header .row {
  padding-bottom: 0px;
}
#header .memver_navi {
  background-color: #444;
  color: #ddd;
  height: 37px;
  line-height: 37px;
  font-size: 12px;
  font-size: 1.2rem;
}
#header .memver_navi p {
  width: auto;
  margin: 0;
  padding-left: 50px;
  display: inline-block;
  vertical-align: top;
}
#header .memver_navi p .name {
  font-weight: bold;
  margin-left: 1em;
  margin-right: 0.5em;
}
#header .memver_navi .member_btnlist {
  display: inline-block;
  width: auto;
  float: right;
  vertical-align: top;
}
#header .memver_navi .member_btnlist:before {
  clear: both;
}
#header .memver_navi .member_btnlist ul {
  font-size: 0;
  letter-spacing: 0;
  height: 37px;
}
#header .memver_navi .member_btnlist ul li {
  display: inline-block;
  font-size: 12px;
  border-left: 1px solid #6d6d6d;
}
#header .memver_navi .member_btnlist ul li a {
  display: block;
  padding: 0 50px;
  font-size: 12px;
  color: #ddd;
}
#header .memver_navi .member_btnlist ul li a:hover {
  color: #999;
}
#header .memver_navi .member_btnlist ul .reg {
  background-color: #ffcc00;
}
#header .memver_navi .member_btnlist ul .reg a {
  color: #444;
}
@media only screen and (max-width: 767px) {
  #header .navi {
    padding-bottom: 0px;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
    height: 64px;
    padding-right: 56px;
    position: relative;
  }
}
@media only screen and (min-width: 767px) {
  #header .navi {
    height: 107px;
    padding-bottom: 0px;
    height: 107px;
    padding: 15px 0;
  }
}
#header .navi .col-sm-6 {
  height: 100%;
  display: block;
  padding: 0;
  font-size: 0;
  letter-spacing: 0;
}
#header .navi .col-sm-6 .header_logo_area {
  height: 100%;
  width: 100%;
  display: block;
  font-size: 0;
  letter-spacing: 0;
}
@media only screen and (max-width: 767px) {
  #header .navi .col-sm-6 .header_logo_area h1 {
    margin: 0;
    display: inline-block;
    margin-left: 8px;
  }
}
@media only screen and (min-width: 767px) {
  #header .navi .col-sm-6 .header_logo_area h1 {
    margin: 0;
    line-height: 48px;
    display: inline-block;
    font-size: 24px;
    text-align: center;
    margin-left: 43px;
  }
}
@media only screen and (max-width: 767px) {
  #header .navi .col-sm-6 .header_logo_area .desctext {
    display: block;
    font-size: 10px;
    font-size: 1rem;
    padding-top: 3px;
    vertical-align: top;
    -webkit-font-smoothing: auto;
    transform: rotate(0.05deg);
    text-shadow: 0 0 0.1px rgba(51, 51, 51, 0.5);
  }
  #header .navi .col-sm-6 .header_logo_area .desctext span {
    display: inline-block;
    overflow: hidden;
    height: 28px;
    max-width: 120px;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}
@media only screen and (min-width: 767px) {
  #header .navi .col-sm-6 .header_logo_area .desctext {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
    font-size: 1.2rem;
    vertical-align: top;
    font-weight: normal;
  }
  #header .navi .col-sm-6 .header_logo_area .desctext span {
    display: inline-block;
    overflow: hidden;
    height: 28px;
    max-width: 200px;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
}
#header .navi .col-sm-6 .header_right_area {
  text-align: right;
}
#header .navi .col-sm-6 .header_bottom_area {
  display: inline-block;
  text-align: left;
}
#header .navi .col-sm-6 .search form .search_inner {
  width: 254px;
  height: 48px;
  background: #f2f2f2;
  border-radius: 48px;
  font-size: 0;
  letter-spacing: 0;
}
#header .navi .col-sm-6 .search form .search_inner input {
  border-radius: 48px 0 0 48px;
  width: 180px;
  font-size: 12px;
  height: 48px;
  background: #f2f2f2;
  border: 0;
  margin: 0 0 0 20px;
  padding: 0 2px;
  font-size: 12px;
  vertical-align: top;
  display: inline-block;
  box-shadow: none !important;
}
#header .navi .col-sm-6 .search form .search_inner input:focus {
  outline: none;
  box-shadow: none !important;
}
#header .navi .col-sm-6 .search form .search_inner input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
}
#header .navi .col-sm-6 .search form .search_inner button {
  width: 50px;
  height: 48px;
  border-radius: 0 48px 48px 0;
  border: none;
  background: #f2f2f2;
  padding: 0;
  margin: 0;
  font-size: 15px;
  color: #999;
}
#header .navi .col-sm-6 .search form .search_inner button:focus {
  outline: none;
}
#header .navi .col-sm-6 .search form .search_inner button:hover {
  color: #444;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#header .navi .col-sm-6 .support_btn {
  display: inline-block;
  position: relative;
  width: 146px;
  height: 48px;
  margin-left: 20px;
  padding: 8px 0;
  vertical-align: top;
  color: #444;
}
#header .navi .col-sm-6 .support_btn i {
  font-size: 22px;
  position: absolute;
  top: 9px;
  left: 0;
}
#header .navi .col-sm-6 .support_btn span:nth-of-type(1) {
  font-size: 10px;
  display: block;
}
#header .navi .col-sm-6 .support_btn span:nth-of-type(2) {
  font-size: 14px;
  display: block;
  letter-spacing: 0.13em;
}
#header .navi .col-sm-6 ul {
  display: inline-block;
  text-align: left;
  margin-right: 20px;
  height: 48px;
  vertical-align: top;
  padding: 9px 0;
}
#header .navi .col-sm-6 ul li {
  display: inline-block;
  text-align: left;
  line-height: 30px;
  height: 30px;
}
#header .navi .col-sm-6 ul li:first-child {
  border-right: 1px solid #999;
}
#header .navi .col-sm-6 ul li a {
  padding: 0 30px;
  font-size: 22px;
  color: #999;
}
#header .navi .col-sm-6 ul li a:hover {
  color: #444;
}
#header .navi .col-sm-6 ul li a .cart_icon {
  position: relative;
}
#header .navi .col-sm-6 ul li .badge {
  background: #e22d1f;
  width: 18px;
  height: 18px;
  font-size: 10px;
  border-radius: 15px;
  color: #fff;
  display: block;
  position: absolute;
  top: -7px;
  right: -7px;
  text-align: center;
  line-height: 14px;
  overflow: hidden;
  animation: show 0.3s both;
}
@keyframes show {
  0% {
    top: 0;
    right: 0;
    width: 0;
    height: 0;
  }
  100% {
    top: -8px;
    right: -8px;
    width: 18px;
    height: 18px;
  }
}
#header .main_menu {
  height: 45px;
  border-bottom: 1px solid #ddd;
}
#header .main_menu nav {
  width: 1200px;
  margin: auto;
  display: block;
  height: 100%;
}
#header .main_menu nav .category_wrap {
  width: 100%;
  height: 100%;
}
#header .main_menu nav .category_wrap ul {
  display: table;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  height: 100%;
}
#header .main_menu nav .category_wrap ul li {
  display: table-cell;
  position: relative;
  height: 100%;
}
#header .main_menu nav .category_wrap ul li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: #ddd;
}
#header .main_menu nav .category_wrap ul li:last-child:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  display: inline-block;
  width: 1px;
  height: 30px;
  background-color: #ddd;
}
#header .main_menu nav .category_wrap ul li a {
  font-size: 13px;
  font-weight: bold;
  color: #999;
  display: block;
  line-height: 30px;
  height: 44px;
}
#header .main_menu nav .category_wrap ul li a:after {
  content: "";
  z-index: 100;
  position: absolute;
  right: 51%;
  left: 51%;
  bottom: -1px;
  display: inline-block;
  height: 7px;
  background-color: #ffcc00;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
#header .main_menu nav .category_wrap ul li a:hover {
  color: #444;
}
#header .main_menu nav .category_wrap ul li a:hover:after {
  right: 0;
  left: 0;
}
#header .main_menu nav .category_wrap ul li a.active {
  color: #444;
}
#header .main_menu nav .category_wrap ul li a.active:after {
  right: 0;
  left: 0;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

/* --------------------------------
	フッター
-------------------------------- */
#footer {
  /* -------------- */
  font-size: 12px;
  font-size: 1.2rem;
  font-family: "Heebo", "Sawarabi Gothic", "ヒラギノ角ゴPro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "游ゴシック体", "YuGothic", "メイリオ", "Meiryo", "sans-serif";
  color: #444;
  /* -------------- */
  background: #f2f2f2;
}
#footer #listcan_link {
  height: unset;
  line-height: unset;
  border-color: unset;
  background-color: unset;
  border: unset;
  color: #999;
  position: relative;
}
#footer #listcan_link:hover {
  color: #444;
}
@media only screen and (max-width: 767px) {
  #footer {
    min-width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  #footer {
    min-width: 1216px;
  }
}
#footer a {
  color: #35aae7;
  text-decoration: none;
  cursor: pointer;
}
#footer a:hover, #footer a:focus, #footer a:active {
  color: #0099e8;
  text-decoration: none;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#footer button:focus, #footer button:active:focus {
  outline: none;
}
#footer .btn:focus, #footer .btn:active:focus {
  outline: none;
}
#footer textarea {
  /* for chrome fontsize bug */
  font-family: sans-serif;
}
#footer .inner {
  padding: 0;
  margin: 0 auto;
}
@media only screen and (max-width: 767px) {
  #footer .inner {
    width: 100%;
    max-width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner {
    width: 1200px;
    max-width: 1216px;
  }
}
#footer .inner .footer_sns_logo_area .fa-facebook {
  color: #3C5A99;
}
#footer .inner .footer_sns_logo_area .fa-twitter {
  color: #1DA1F2;
}
@media only screen and (max-width: 767px) {
  #footer .inner .footer_sns_logo_area {
    margin: 0;
    height: 30px;
    font-size: 32px;
    margin: 10px;
    text-align: center;
  }
  #footer .inner .footer_sns_logo_area .fa-facebook {
    margin-right: 45px;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footer_sns_logo_area {
    margin: 0;
    height: 30px;
    font-size: 32px;
    line-height: 48px;
    margin: 40px 105px 40px 0;
    text-align: right;
  }
  #footer .inner .footer_sns_logo_area .fa-facebook {
    margin-right: 10px;
  }
}
#footer .inner .footer_logo_area {
  height: 100%;
  width: 100%;
  display: block;
  font-size: 0;
  letter-spacing: 0;
}
@media only screen and (max-width: 767px) {
  #footer .inner .footer_logo_area h2 {
    margin: 0;
    line-height: 48px;
    display: block;
    font-size: 24px;
    text-align: center;
    margin: 20px auto 0;
    background: none;
  }
  #footer .inner .footer_logo_area h2 a {
    width: 156px;
    display: inline-block;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footer_logo_area h2 {
    margin: 0;
    height: 30px;
    line-height: 48px;
    display: inline-block;
    font-size: 24px;
    text-align: center;
    margin: 40px 0;
    background: none;
  }
  #footer .inner .footer_logo_area h2 a {
    width: 156px;
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  #footer .inner .footer_logo_area .desctext {
    display: block;
    font-size: 10px;
    font-size: 1rem;
    vertical-align: top;
    text-align: center;
    -webkit-font-smoothing: auto;
    transform: rotate(0.05deg);
    text-shadow: 0 0 0.1px rgba(51, 51, 51, 0.5);
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footer_logo_area .desctext {
    display: inline-block;
    margin-left: 20px;
    font-size: 12px;
    font-size: 1.2rem;
    vertical-align: top;
    font-weight: normal;
  }
}
@media only screen and (max-width: 767px) {
  #footer .inner .footerbox {
    display: inline-block;
    width: 100%;
    padding: 0;
    vertical-align: top;
  }
  #footer .inner .footerbox:nth-of-type(1) {
    padding: 20px 0;
    border-left: none;
  }
  #footer .inner .footerbox:nth-of-type(4) {
    padding: 0 0 0 0;
  }
  #footer .inner .footerbox .level01 {
    text-align: left;
    margin: 0;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footerbox {
    display: inline-block;
    width: 25%;
    padding: 0 20px;
    border-left: 1px solid #ddd;
    vertical-align: top;
  }
  #footer .inner .footerbox:nth-of-type(1) {
    padding: 0 20px 0 0;
    border-left: none;
  }
  #footer .inner .footerbox:nth-of-type(4) {
    padding: 0 0 0 20px;
  }
  #footer .inner .footerbox .level01 {
    margin: 0 0 20px 0;
    font-size: 12px;
    font-weight: bold;
  }
}
@media only screen and (max-width: 767px) {
  #footer .inner .footerbox__2 {
    display: inline-block;
    width: 100%;
    padding: 0;
    vertical-align: top;
  }
  #footer .inner .footerbox__2:nth-of-type(1) {
    padding: 20px 0;
    border-left: none;
  }
  #footer .inner .footerbox__2:nth-of-type(4) {
    padding: 0 0 0 0;
  }
  #footer .inner .footerbox__2 .level01 {
    text-align: left;
    margin: 0;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footerbox__2 {
    display: inline-block;
    width: 100%;
    padding: 0;
    vertical-align: top;
    margin: 30px 0 47px;
    border-left: 1px solid #ddd;
  }
  #footer .inner .footerbox__2:nth-of-type(1) {
    padding: 0 20px 0 0;
    border-left: none;
  }
  #footer .inner .footerbox__2:nth-of-type(4) {
    padding: 0 0 0 20px;
  }
  #footer .inner .footerbox__2 .level01 {
    margin: 0 0 0 20px;
    font-size: 12px;
    font-weight: bold;
  }
}
#footer .inner .footerbox__2 .btn_list li, #footer .inner .footerbox .btn_list li {
  font-size: 14px;
  color: #444;
  height: 50px;
  display: block;
  text-align: center;
  line-height: 50px;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
  position: relative;
}
@media only screen and (max-width: 767px) {
  #footer .inner .footerbox__2 .btn_list li, #footer .inner .footerbox .btn_list li {
    width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footerbox__2 .btn_list li, #footer .inner .footerbox .btn_list li {
    width: 220px;
  }
}
#footer .inner .footerbox__2 .btn_list li:last-child, #footer .inner .footerbox .btn_list li:last-child {
  border-bottom: none;
}
#footer .inner .footerbox__2 .btn_list li a, #footer .inner .footerbox .btn_list li a {
  color: #444;
  display: block;
  background: #fff;
  height: 50px;
  border: 1px solid #ddd;
  line-height: 50px;
}
#footer .inner .footerbox__2 .btn_list li a:hover, #footer .inner .footerbox .btn_list li a:hover {
  background-color: #999;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#footer .inner .footerbox__2 .btn_list li i, #footer .inner .footerbox .btn_list li i {
  color: #999;
  font-size: 20px;
  position: absolute;
  left: 20px;
  top: 0;
  font-weight: 300;
}
@media only screen and (max-width: 767px) {
  #footer .inner .footerbox__2 .btn_list2 a {
    width: calc(100% - 50px);
    display: inline-block !important;
  }
  #footer .inner .footerbox__2 .btn_list2 .ac_btn {
    width: 50px;
    height: 50px;
    vertical-align: top;
    font-size: 20px;
    color: #999;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
  }
  #footer .inner .footerbox__2 .btn_list2 .active .fa-plus:before {
    content: "\f068";
  }
  #footer .inner .footerbox__2 .btn_list2 ul {
    display: none;
  }
  #footer .inner .footerbox__2 .btn_list2 ul li a {
    width: 100%;
    position: relative;
    padding-left: 30px;
  }
  #footer .inner .footerbox__2 .btn_list2 ul li a i {
    left: 14px;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footerbox__2 .btn_list2 a {
    margin-left: 20px;
    margin-top: 20px;
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  #footer .inner .footerbox__2 .btn_list2 li, #footer .inner .footerbox .btn_list2 li {
    position: relative;
    padding-left: 0px;
  }
  #footer .inner .footerbox__2 .btn_list2 li a, #footer .inner .footerbox .btn_list2 li a {
    color: #999;
    font-weight: bold;
    display: block;
    padding-left: 14px;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-bottom: 1px dotted #ddd;
  }
  #footer .inner .footerbox__2 .btn_list2 li a:hover, #footer .inner .footerbox .btn_list2 li a:hover {
    color: #444;
  }
  #footer .inner .footerbox__2 .btn_list2 li a i, #footer .inner .footerbox .btn_list2 li a i {
    font-weight: 900;
    margin-right: 0.3em;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media only screen and (min-width: 767px) {
  #footer .inner .footerbox__2 .btn_list2 li, #footer .inner .footerbox .btn_list2 li {
    position: relative;
  }
  #footer .inner .footerbox__2 .btn_list2 li a, #footer .inner .footerbox .btn_list2 li a {
    line-height: 2em;
    color: #999;
    font-weight: bold;
    display: inline-block;
    padding-left: 14px;
  }
  #footer .inner .footerbox__2 .btn_list2 li a:hover, #footer .inner .footerbox .btn_list2 li a:hover {
    color: #444;
  }
  #footer .inner .footerbox__2 .btn_list2 li a i, #footer .inner .footerbox .btn_list2 li a i {
    font-weight: 900;
    margin-right: 0.3em;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 0;
  }
  #footer .inner .footerbox__2 .btn_list2 li ul li, #footer .inner .footerbox .btn_list2 li ul li {
    display: inline-block;
    width: 25%;
  }
  #footer .inner .footerbox__2 .btn_list2 li ul li a, #footer .inner .footerbox .btn_list2 li ul li a {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    max-width: 100%;
    position: relative;
    font-weight: normal;
    margin-top: 0;
  }
  #footer .inner .footerbox__2 .btn_list2 li ul li a i, #footer .inner .footerbox .btn_list2 li ul li a i {
    font-weight: 300;
    font-size: 12px;
  }
}
#footer .footer_boder {
  border-top: 2px solid #e4e4e4;
  border-bottom: 2px solid #e4e4e4;
}
@media only screen and (max-width: 767px) {
  #footer .footer_boder {
    padding: 5px 0;
  }
}
@media only screen and (min-width: 767px) {
  #footer .footer_boder {
    padding: 10px 0;
  }
}
#footer .footer_boder .footerbn {
  padding: 0 8px;
}
@media only screen and (max-width: 767px) {
  #footer .footer_boder .footerbn li {
    display: inline-block;
    width: 50%;
    height: 54px;
    text-align: center;
    margin: 5px 0;
  }
}
@media only screen and (min-width: 767px) {
  #footer .footer_boder .footerbn li {
    display: inline-block;
    height: 54px;
    margin-right: 10px;
    text-align: center;
    background: #f7f7f7;
    margin-right: 10px;
  }
}
#footer .footer_boder .footerbn li a {
  display: block;
  height: 54px;
}
#footer .footer_boder .footerbn li a img {
  height: 100%;
  width: auto;
  text-align: center;
  line-height: 54px;
}
#footer .footer_boder .footer_pmark {
  display: flex;
  justify-content: space-between;
  align-items: end;
}
@media only screen and (max-width: 767px) {
  #footer .footer_boder .footer_pmark {
    display: block;
    text-align: center;
  }
  #footer .footer_boder .footer_pmark img {
    margin-bottom: 10px;
  }
}
#footer .copyright {
  display: block;
  text-align: center;
  padding: 10px 0 0 0;
  color: #999;
}
#footer p {
  background: #efefef;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .sp {
    display: block !important;
  }
  .pc {
    display: none !important;
  }
  .pc-inl {
    display: none !important;
  }
  .sp-inl {
    display: inline !important;
  }
  span.sp {
    display: inline !important;
  }
}
@media only screen and (min-width: 767px) {
  .sp {
    display: none !important;
  }
  .sp-inl {
    display: none !important;
  }
  .pc {
    display: block !important;
  }
  .pc-inl {
    display: inline-block !important;
  }
}
/* --------------------------------
	どうするか　ページトップボタン
-------------------------------- */
#page-top {
  display: none !important;
}

/* --------------------------------
	追記部分
-------------------------------- */
#main {
  margin-bottom: 15px;
}

/************************************************
モバイル専用があれば
************************************************ */
@media only screen and (max-width: 767px) {
  .inner {
    overflow: hidden;
  }
  /****** Base Layout Setting ********/
  #contents,
  #header,
  #footer,
  .pagetop {
    /* Force Hardware Acceleration in WebKit
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    will-change: transform; */
  }
  #contents,
  #header,
  #footer,
  .pagetop {
    position: relative;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .drawer-open #contents,
  .drawer-open #header,
  .drawer-open #footer,
  .drawer-open .pagetop {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
  .drawer {
    height: 0;
    visibility: hidden;
    -webkit-transition: visibility 0s 0.3s;
    -moz-transition: visibility 0s 0.3s;
    transition: visibility 0s 0.3s;
  }
  .drawer-open .drawer {
    height: 100%;
    visibility: visible;
    -webkit-transition: visibility 0s 0.3s;
    -moz-transition: visibility 0s 0.3s;
    transition: visibility 0s 0.3s;
  }
  .overlay.is-visible {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
  .overlay.is-visible.cart-is-visible {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .drawer {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 260px;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 1;
    -webkit-transition: -webkit-transform 0.3s;
    -moz-transition: -moz-transform 0.3s;
    transition: transform 0.3s;
  }
  .drawer .input_search {
    height: 50px;
    padding: 0 50px 0 1em;
    border-bottom: 1px solid #ddd;
    position: relative;
    margin-top: 14px;
    margin-left: 1%;
    margin-right: 1%;
  }
  .drawer button {
    border: 0;
    background: none;
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    width: 44px;
    height: 43px;
    overflow: hidden;
    white-space: nowrap;
    z-index: 1;
    font-size: 20px;
  }
  .drawer li {
    list-style: none;
  }
  .drawer li a {
    border-bottom: 1px solid #ddd;
    color: #999;
    display: block;
    font-size: 14px;
    font-size: 1.4rem;
    height: 50px;
    line-height: 50px;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    transform: translateZ(0px);
    transition: transform 0.3s ease 0s, opacity 0.3s ease 0s;
    white-space: nowrap;
  }
  .drawer li a:hover {
    color: #444;
    -webkit-transition: background 0.3s 0.3s;
    -moz-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
  }
  .drawer li a i {
    font-size: 20px;
    margin-right: 1rem;
    border-right: 1px dotted #ddd;
    padding-right: 1rem;
    width: 35px !important;
    display: inline-block !important;
  }
  .drawer li a .cart_icon .badge {
    background: #e22d1f;
    width: 18px;
    height: 18px;
    font-size: 10px;
    border-radius: 15px;
    color: #fff;
    display: block;
    position: absolute;
    top: 4px;
    left: 22px;
    text-align: center;
    line-height: 14px;
    overflow: hidden;
  }
  #drawer .category-nav {
    text-align: left;
    width: 100%;
  }
  #drawer .member_link li:nth-of-type(3) a:before {
    content: "\f004";
    font-family: FontAwesome;
    font-size: 18px;
  }
  #drawer .member_link li:nth-of-type(4) a:before {
    content: "\f07a";
    font-family: FontAwesome;
    font-size: 18px;
  }
  #drawer .member_link li i {
    font-size: 20px;
  }
  #drawer .member_link li a .price {
    padding-left: 5px;
  }
  #drawer .search input {
    height: 43px;
    width: 100%;
    font-size: 16px;
    font-size: 1.6rem;
    border: 0 none;
    padding: 0.5em 0;
    box-shadow: none;
    background: none;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  #drawer .search input:focus {
    outline: none;
  }
  #drawer .search .bt_search {
    right: 0;
  }
  #drawer .category-nav a {
    border-right: none;
  }
  .drawer-open .nav-trigger span {
    /* hide line in the center */
    background: rgba(46, 50, 51, 0);
  }
  .drawer-open .nav-trigger span::before,
  .drawer-open .nav-trigger span::after {
    /* keep visible other 2 lines */
    background: #444;
  }
  .drawer-open .nav-trigger span::before {
    -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
    transform: translateX(4px) translateY(-3px) rotate(45deg);
  }
  .drawer-open .nav-trigger span::after {
    -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
    transform: translateX(4px) translateY(2px) rotate(-45deg);
  }
  #btn_menu {
    margin: 0;
    position: absolute;
    right: 15px;
    top: 10px;
    z-index: 50;
    width: 40px;
    height: 40px;
    transition: 0.3s ease;
  }
  #btn_menu {
    display: inline-block;
    width: 40px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 17px;
    transition: 0.3s ease;
  }
  #btn_menu a {
    width: 40px;
    height: 30px;
    display: block;
  }
  .nav-trigger {
    width: 100%;
    height: 100%;
    color: transparent !important;
  }
  .nav-trigger span,
  .nav-trigger span::before,
  .nav-trigger span::after {
    /* hamburger icon in CSS */
    position: absolute;
    display: inline-block;
    height: 3px;
    display: block;
    width: 30px;
    background: #444;
  }
  .nav-trigger span {
    /* line in the center */
    position: absolute;
    width: 30px;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    -webkit-transition: background 0.3s 0.3s;
    -moz-transition: background 0.3s 0.3s;
    transition: background 0.3s 0.3s;
  }
  .nav-trigger span::before,
  .nav-trigger span::after {
    /* other 2 lines */
    content: "";
    right: 0;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 0% 50%;
    -moz-transform-origin: 0% 50%;
    -ms-transform-origin: 0% 50%;
    -o-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    -webkit-transition: -webkit-transform 0.3s 0.3s;
    -moz-transition: -moz-transform 0.3s 0.3s;
    transition: transform 0.3s 0.3s;
  }
  .nav-trigger span::before {
    /* menu icon top line */
    top: -8px;
  }
  .nav-trigger span::after {
    /* menu icon bottom line */
    top: 8px;
  }
  .drawer-open .nav-trigger span {
    /* hide line in the center */
    background: rgba(46, 50, 51, 0);
  }
  .drawer-open .nav-trigger span::before,
  .drawer-open .nav-trigger span::after {
    /* keep visible other 2 lines */
    background: #444;
  }
  .drawer-open .nav-trigger span::before {
    -webkit-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -moz-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -ms-transform: translateX(4px) translateY(-3px) rotate(45deg);
    -o-transform: translateX(4px) translateY(-3px) rotate(45deg);
    transform: translateX(4px) translateY(-3px) rotate(45deg);
  }
  .drawer-open .nav-trigger span::after {
    -webkit-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -moz-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -ms-transform: translateX(4px) translateY(2px) rotate(-45deg);
    -o-transform: translateX(4px) translateY(2px) rotate(-45deg);
    transform: translateX(4px) translateY(2px) rotate(-45deg);
  }
  .overlay {
    /* shadow layer visible when navigation is active */
    position: fixed;
    z-index: 2;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
  }
  .overlay.is-visible {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s, -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s, transform 0.3s 0s;
  }
  .main_btn {
    display: inline-block;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 0;
    text-decoration: none;
    background-color: #F4A93C;
    color: #FFF !important;
    border-radius: 5px;
    outline: none !important;
  }
  .main_btn:active {
    margin-bottom: 0px;
  }
  .text-primary {
    color: #F4A93C;
  }
  .text-danger {
    color: #d8430e;
  }
}
.cambana-cash {
  background: #e61212;
}

#h_cam_box {
  margin-left: -8px;
  margin-right: -8px;
  position: relative;
  z-index: 1;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -o-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
  -ms-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.19);
}
#h_cam_box .cam_toggle_btn {
  background-color: #ffcc00;
  min-height: 50px;
  display: block;
  cursor: pointer;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  #h_cam_box .cam_toggle_btn {
    padding: 14px;
    font-size: 14px;
  }
}
#h_cam_box .cam_toggle_btn span {
  background: red;
  display: inline-block;
  padding: 5px 7px;
  border-radius: 5px;
  margin-right: 7px;
  height: 27px;
  line-height: 20px;
  color: #ffcc00;
}
#h_cam_box .cam_toggle_btn i {
  margin-left: 1em;
}
#h_cam_box .cam_toggle_btn.active {
  color: #999;
}
#h_cam_box .cam_toggle_btn.active i:before {
  content: "\f056";
}
#h_cam_box .cam_toggle_detail {
  background-color: #414140;
  display: none;
  padding: 27px 0;
}
#h_cam_box .cam_toggle_detail #main {
  width: 1230px;
  margin: auto;
}
@media only screen and (max-width: 767px) {
  #h_cam_box .cam_toggle_detail #main {
    width: 100%;
  }
}
#h_cam_box .cam_toggle_detail #main .col-sm-4 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
@media only screen and (max-width: 767px) {
  #h_cam_box .cam_toggle_detail #main .col-sm-4:last-of-type {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  #h_cam_box .cam_toggle_detail #main .col-sm-4 {
    padding-bottom: 27px;
    text-align: center;
  }
}

.messe111 {
  position: relative;
}
.messe111 div {
  position: absolute;
  display: block;
  width: 300px;
  background: rgba(255, 237, 45, 0.6784313725);
  padding: 5px;
  z-index: 10000;
}

/* LESS Document => SCSS Document fgoriginal */
/*ec-cube4用調整用*/
.ec-layoutRole .ec-layoutRole__contents {
  max-width: inherit !important;
}

/***********************************************
モバイルのブレイクポイント
************************************************ */
/* --------------------------------
Common style
-------------------------------- */
/* --------------------------------
ボタン系
-------------------------------- */
@media only screen and (max-width: 767px) {
  .cart_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    margin-top: 0 !important;
  }
  .cart_btn:hover, .cart_btn:focus {
    background-color: #818181;
    color: #fff;
  }
  .cart_btn i {
    position: absolute;
    left: 8px;
    color: #818181;
    font-size: 18px;
  }
  .sold_out_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #fff;
    color: #444 !important;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;
  }
  .sold_out_btn:hover, .sold_out_btn:focus {
    background-color: #fff;
    color: #444;
  }
  .next_btn {
    display: inline-block;
    width: calc(100% - 63px);
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    position: relative;
  }
  .next_btn:hover, .next_btn:focus {
    background-color: #818181;
    color: #fff;
  }
  .next_btn i {
    position: absolute;
    right: 8px;
    color: #818181;
    font-size: 18px;
  }
}
@media only screen and (min-width: 767px) {
  .cart_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    margin-top: 0 !important;
  }
  .cart_btn:hover, .cart_btn:focus {
    background-color: #818181;
    color: #fff;
  }
  .cart_btn i {
    position: absolute;
    left: 8px;
    color: #818181;
    font-size: 18px;
  }
  .sold_out_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #fff;
    color: #444;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    border: 0;
  }
  .sold_out_btn:hover, .sold_out_btn:focus {
    background-color: #fff;
    color: #444;
  }
  .next_btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    background: #4d4d4d;
    color: #fff;
    border-radius: 3px;
    padding: 0;
    margin: 0;
    position: relative;
    position: relative;
  }
  .next_btn:hover, .next_btn:focus {
    background-color: #818181;
    color: #fff;
  }
  .next_btn i {
    position: absolute;
    right: 8px;
    color: #818181;
    font-size: 18px;
  }
}
.like_btn {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: #f2f2f2;
  padding: 0;
  margin-right: 20px;
  font-size: 18px;
  line-height: 18px;
  color: #999;
}
@media only screen and (max-width: 767px) {
  .like_btn {
    margin-right: 18px;
    margin-bottom: 20px;
  }
}
.like_btn:focus, .like_btn:active:focus {
  outline: none;
}

.mypage .download_btn {
  display: inline-block;
  width: 180px;
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  background: #4d4d4d;
  color: #fff;
  border-radius: 3px;
  padding: 0;
  margin: 1em 0;
  position: relative;
  text-align: center;
  line-height: 35px;
}
.mypage .download_btn:hover, .mypage .download_btn:focus {
  background-color: #818181;
  color: #fff;
}
.mypage .download_btn i {
  font-size: 18px;
  margin-right: 0.5em;
}

/* ここから下はまだ整理できてない*/
/* svg icon */
/* --------------------------------
商品スライダーブロック
-------------------------------- */
.slider-nav {
  display: none;
}

.slider-nav.slick-initialized {
  display: block;
}

@media only screen and (max-width: 767px) {
  #main_w860 .slider-nav {
    overflow: hidden;
    margin-top: -7px;
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 767px) {
  #main_w860 .slider-nav {
    overflow: hidden;
    margin-top: -7px;
    margin-bottom: 70px;
  }
}
#main_w860 .slider-nav .slick-list {
  overflow: visible;
}
@media only screen and (max-width: 767px) {
  #main_w860 .slider-nav .slick-list .inner img {
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  #main_w860 .slider-nav .slick-list .inner img {
    display: block;
  }
}
#main_w860 .slider-nav .slick-list .inner img:hover {
  opacity: 0.777;
}
#main_w860 .slider-nav .slick-list .slick-active {
  transform: scale(1);
}
#main_w860 .slider-nav .btnbox_pre {
  left: 20px;
}
#main_w860 .slider-nav .btnbox_nex {
  right: 20px;
}
#main_w860 .slider-nav .slide-dots {
  display: block;
  visibility: visible;
}

.slider-nav .slick-list .slick-center {
  transform: scale(1);
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-slide {
    display: block;
    position: relative !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media only screen and (min-width: 767px) {
  .slider-nav .slick-slide {
    display: block;
    position: relative !important;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
.slider-nav .slick-slide .price_d {
  position: absolute;
  top: 11px;
  left: 0px;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-slide .detail {
    width: 100%;
    position: absolute;
    bottom: 25px;
    right: 0;
    z-index: 1;
    text-align: center;
    font-size: 12px;
    color: #444;
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  .slider-nav .slick-slide .detail {
    position: absolute;
    bottom: 40px;
    right: 40px;
    z-index: 1;
    text-align: right;
    font-size: 12px;
    color: #444;
  }
}
.slider-nav .slick-slide .detail:hover {
  color: #444;
}
.slider-nav .slick-slide .detail .tag {
  font-size: 10px;
  font-weight: bold;
  color: #dc1e1e;
}
.slider-nav .slick-slide .detail .weight {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
}
.slider-nav .slick-slide .detail .price {
  display: inline-block;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 5px;
}
@media only screen and (max-width: 767px) {
  .slider-nav .slick-slide .detail .price {
    font-size: 18px;
  }
}
@media only screen and (min-width: 767px) {
  .slider-nav .slick-slide .detail .price {
    font-size: 30px;
  }
}
.slider-nav .slick-slide .detail .price span {
  font-size: 18px;
  margin-right: 5px;
}
.slider-nav .slick-slide .inner {
  display: block;
  height: 100%;
  width: 100%;
  opacity: 1;
  overflow: hidden;
}
.slider-nav .slick-slide .inner:hover {
  opacity: 0.777;
}
.slider-nav .slick-slide .inner__01 {
  background: #c4c4c4;
}
.slider-nav .slick-slide .inner__02 {
  background: #c4c4c4;
}
.slider-nav .slick-slide .inner__03 {
  background: #c4c4c4;
}
.slider-nav .slick-slide .inner__04 {
  background: #c4c4c4;
}
@media only screen and (max-width: 767px) {
  .slider-nav .btnbox_nex {
    display: none !important;
  }
}
@media only screen and (min-width: 767px) {
  .slider-nav .btnbox_nex {
    display: block;
    position: absolute;
    top: 158px;
    right: 50px;
    height: 34px;
    width: 28px;
    margin-top: 0;
    padding: 5px 0;
    cursor: pointer;
    opacity: 0.7;
  }
}
.slider-nav .btnbox_nex:hover {
  opacity: 1;
}
.slider-nav .btnbox_nex .nexArrow {
  display: block;
  content: "";
  width: 22px;
  height: 22px;
  border: 0px;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  filter: drop-shadow(2px 2px 1px #444);
}
@media only screen and (max-width: 767px) {
  .slider-nav .btnbox_pre {
    display: none !important;
  }
}
@media only screen and (min-width: 767px) {
  .slider-nav .btnbox_pre {
    display: block;
    position: absolute;
    top: 158px;
    left: 50px;
    height: 34px;
    width: 28px;
    margin-top: 0;
    padding: 5px 5px;
    cursor: pointer;
    opacity: 0.7;
    z-index: 1;
  }
}
.slider-nav .btnbox_pre:hover {
  opacity: 1;
}
.slider-nav .btnbox_pre .preArrow {
  display: block;
  content: "";
  width: 22px;
  height: 22px;
  border: 0px;
  border-top: solid 2px #ffffff;
  border-right: solid 2px #ffffff;
  -ms-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  filter: drop-shadow(-2px -2px 1px #444);
}
.slider-nav .slide-dots {
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 40px;
}
@media only screen and (max-width: 767px) {
  .slider-nav .slide-dots {
    bottom: 8px;
    right: 8px;
  }
}
.slider-nav .slide-dots li {
  display: inline-block;
  border: 2px solid #fff;
  width: 8px;
  border-radius: 8px;
  height: 8px;
  margin-left: 10px;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .slider-nav .slide-dots li {
    margin-left: 8px;
  }
}
.slider-nav .slide-dots li:first-child {
  margin-left: 0;
}
.slider-nav .slide-dots li button {
  display: none;
}
.slider-nav .slide-dots .slick-active {
  background: #ffcb01;
  border: 2px solid #ffcb01;
}

/* スライダーその他のキャンペーンバナーなど */
#cam_main {
  max-width: 1335px;
}
#cam_main .cam_list {
  background-color: #e4e4e1;
}
#cam_main .cam_list h2 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 36px;
}
@media only screen and (max-width: 767px) {
  #cam_main .cam_list h2 {
    font-size: 18px;
  }
}
#cam_main .cam_list .main {
  max-width: 1216px;
  padding: 50px 0 0px;
}
#cam_main .cam_list .main .row {
  margin-left: -15px;
  margin-right: -15px;
}
@media only screen and (max-width: 767px) {
  #cam_main .cam_list .main .row {
    margin-left: 0;
    margin-right: 0;
  }
}
#cam_main .cam_list .main .row .col-sm-6,
#cam_main .cam_list .main .row .col-sm-12 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
#cam_main .cam_list .main .row .col-sm-6 {
  margin-bottom: 50px;
}
#cam_main .cam_list .main .row .cam_list_label {
  position: absolute;
  z-index: 1;
  text-align: center;
  margin: 0 auto;
  height: 34px;
  font-weight: bold;
  font-size: 16px;
  padding: 6px 28px;
  top: -17px;
  left: 26px;
}
#cam_main .cam_list .main .row .cam_list_label::before {
  transform: skewX(-30deg);
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: #ffcb01;
}
@media only screen and (max-width: 767px) {
  #cam_main .cam_list .main {
    width: 100%;
    padding: 50px 8px 70px;
  }
}

/* --------------------------------
mainコンテンツ
-------------------------------- */
@media only screen and (max-width: 767px) {
  #main {
    width: 100%;
    margin: auto;
    /*         .row {
    margin-left: 8px;
    margin-right: 8px;
    padding-bottom: 10px;
    }*/
  }
}
@media only screen and (min-width: 767px) {
  #main {
    width: 1200px;
    margin: auto;
    /*        .row {
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 10px;
    }*/
  }
}
#main #topicpath #list_header_menu {
  display: block;
  padding: 15px 0;
}
#main #topicpath #list_header_menu li {
  display: inline;
  font-size: 10px;
  font-weight: bold;
}
#main #topicpath #list_header_menu li a {
  color: #999;
}
#main #topicpath #list_header_menu li a:hover {
  color: #444;
}
#main #topicpath #list_header_menu li:after {
  content: "\f105";
  font-family: "Font Awesome 6 Pro";
  padding: 0 10px;
  color: #999;
}
#main #topicpath #list_header_menu li:first-child a {
  color: #999;
}
#main #topicpath #list_header_menu li:last-child a {
  color: #444;
}
#main #topicpath #list_header_menu li:last-child:after {
  content: "";
  padding: 0;
}
@media only screen and (min-width: 767px) {
  #main #main_w860 {
    width: 860px;
    float: left;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  #main .sidebar {
    display: block;
    width: 100%;
    margin-bottom: 70px;
  }
  #main .sidebar img {
    width: 321px;
    height: auto;
    margin: 0 auto;
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  #main .sidebar {
    display: inline-block;
    vertical-align: top;
    width: 300px;
    margin-left: 40px;
  }
}

/* --------------------------------
  新着コンテンツ+お買い物ガイドバナー
  -------------------------------- */
@media only screen and (max-width: 767px) {
  #news_area h3 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    display: block;
    border-bottom: 1px solid #ddd;
    vertical-align: top;
    padding-bottom: 17px;
  }
}
@media only screen and (min-width: 767px) {
  #news_area {
    margin-right: 30px;
    font-size: 0;
    letter-spacing: 0;
    margin-top: 37px;
  }
  #news_area h3 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
    width: 100px;
    height: 100px;
    display: inline-block;
    border-right: 1px solid #ddd;
    vertical-align: top;
  }
}
@media only screen and (max-width: 767px) {
  #news_area .newslist {
    display: block;
    width: 100%;
    margin-top: 0px;
  }
}
@media only screen and (min-width: 767px) {
  #news_area .newslist {
    display: inline-block;
    width: 760px;
  }
}
#news_area dl {
  vertical-align: top;
  margin-bottom: 15px;
}
#news_area dl dt {
  color: #999;
  font-size: 12px;
  display: inline-block;
  width: 25%;
  vertical-align: top;
  margin-bottom: 14px;
  margin-top: 14px;
}
@media only screen and (min-width: 767px) {
  #news_area dl dt {
    color: #999;
    font-size: 12px;
    display: inline-block;
    width: 100px;
    vertical-align: top;
    margin-bottom: 15px;
    margin-top: 0;
    text-align: right;
  }
}
#news_area dl dt .morelink {
  font-size: 12px;
  letter-spacing: -2px;
  text-decoration: underline;
  color: #999;
}
#news_area dl dt .morelink:hover {
  color: #444;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#news_area dl dt .morelink i {
  letter-spacing: 0;
  margin-left: 8px;
}
@media only screen and (max-width: 767px) {
  #news_area dl dd {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 73%;
    vertical-align: top;
  }
  #news_area dl dd a {
    color: #444;
    width: 100%;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 15px 0;
  }
  #news_area dl dd a:hover {
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
@media only screen and (min-width: 767px) {
  #news_area dl dd {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    width: 660px;
    padding-left: 20px;
    vertical-align: top;
    margin-bottom: 15px;
  }
  #news_area dl dd a {
    color: #444;
  }
  #news_area dl dd a:hover {
    text-decoration: underline;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}

@media only screen and (max-width: 767px) {
  .guide_bana {
    text-align: center;
  }
}
@media only screen and (min-width: 767px) {
  .guide_bana {
    margin-top: 37px;
  }
}
.guide_bana h3 {
  margin: 0;
}

/* --------------------------------
    共通ボックス　おすすめ商品グループプラグイン
    -------------------------------- */
@media only screen and (max-width: 767px) {
  .box_line {
    display: block;
    border-bottom: 3px solid #444;
    width: 100% !important;
    margin: 0 0 40px 0 !important;
  }
  .box_line h2,
  .box_line h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
    margin-top: 0;
    color: #111;
  }
  .box_line .icon_dl {
    display: inline-block;
    background-color: #a89754;
    color: #fff;
    height: 26px;
    vertical-align: middle;
    margin-bottom: 10px;
    line-height: 26px;
    padding: 0px 12px;
    border-radius: 12px;
    margin-left: 1em;
  }
}
@media only screen and (min-width: 767px) {
  .box_line {
    display: block;
    border-bottom: 3px solid #444;
    margin-bottom: 25px;
  }
  .box_line h2,
  .box_line h3 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 0;
    display: inline-block;
    color: #111;
  }
  .box_line .icon_dl {
    display: inline-block;
    background-color: #a89754;
    color: #fff;
    height: 26px;
    vertical-align: middle;
    margin-bottom: 10px;
    line-height: 26px;
    padding: 0px 12px;
    border-radius: 12px;
    margin-left: 1em;
  }
}
.box_line .link_navi {
  display: table-cell;
  float: right;
  height: 37px;
  vertical-align: bottom;
  line-height: 37px;
  font-size: 14px;
  color: #444;
}
.box_line .link_navi:hover {
  text-decoration: underline;
}
.box_line .link_navi i {
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  .box_line2 {
    display: block;
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100% !important;
    margin: 0 0 40px 0 !important;
  }
  .box_line2 h2,
  .box_line2 h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
    margin-top: 0;
    color: #444;
  }
}
@media only screen and (min-width: 767px) {
  .box_line2 {
    display: block;
    border-bottom: 2px solid #ddd;
    border-top: 2px solid #ddd;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 25px;
  }
  .box_line2 h2,
  .box_line2 h3 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 0;
    display: inline-block;
    color: #444;
  }
}

@media only screen and (max-width: 767px) {
  .box_maker_h3 {
    display: block;
    background-color: #f9ce24;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    width: 100% !important;
    margin: 0 0 40px 0 !important;
  }
  .box_maker_h3 h2,
  .box_maker_h3 h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px;
    margin-top: 0;
    color: #444;
  }
}
@media only screen and (min-width: 767px) {
  .box_maker_h3 {
    display: block;
    background-color: #f9ce24;
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 25px;
  }
  .box_maker_h3 h2,
  .box_maker_h3 h3 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 16px;
    margin-top: 0;
    display: inline-block;
    color: #444;
  }
}

@media only screen and (max-width: 767px) {
  .box_g {
    display: block;
    font-size: 0;
    letter-spacing: 0;
  }
}
@media only screen and (min-width: 767px) {
  .box_g {
    display: block;
    font-size: 0;
    letter-spacing: 0;
    width: calc(100% + 20px);
  }
}
@media only screen and (max-width: 767px) {
  .box_g .pickup_item {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .box_g .pickup_item .item_photo {
    width: 50%;
    height: 138px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    text-align: center;
  }
  .box_g .pickup_item .item_photo img {
    height: 98%;
    width: auto;
    padding: 4%;
  }
  .box_g .pickup_item dl {
    width: 50%;
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .box_g .pickup_item dl dt {
    font-size: 14px;
    font-weight: bold;
    color: #444;
    padding: 20px 10px 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 68px;
  }
  .box_g .pickup_item dl dd {
    font-size: 12px;
    padding: 0 10px 20px 10px;
    color: #444;
  }
  .box_g .pickup_item dl dd .price {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 0;
    color: #444;
  }
  .box_g .pickup_item dl dd .price span {
    font-size: 10px;
    font-weight: bold;
  }
}
@media only screen and (min-width: 767px) {
  .box_g .pickup_item {
    display: inline-block;
    width: 386px;
    height: 140px;
    margin: 0px 5px;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .box_g .pickup_item .item_photo {
    width: 193px;
    height: 138px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    text-align: center;
  }
  .box_g .pickup_item .item_photo img {
    height: 98%;
    width: auto;
    padding: 4%;
  }
  .box_g .pickup_item dl {
    width: 191px;
    height: 100%;
    display: inline-block;
    vertical-align: top;
  }
  .box_g .pickup_item dl dt {
    font-size: 14px;
    font-weight: bold;
    color: #444;
    padding: 20px 10px 0px 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 68px;
  }
  .box_g .pickup_item dl dd {
    font-size: 12px;
    height: 70px;
    padding: 0 10px 20px 10px;
    color: #444;
  }
  .box_g .pickup_item dl dd .price {
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-right: 5px;
    margin-left: 0;
    color: #444;
  }
  .box_g .pickup_item dl dd .price span {
    font-size: 18px;
    font-weight: bold;
  }
}
.box_g .pickup_item a {
  display: block;
  box-shadow: 0 0 0 3px #f2f2f2 inset;
}
.box_g .pickup_item a:hover {
  box-shadow: 0 0 0 5px #ddd inset;
}
.box_g .pickup_item a:hover .a_inner {
  display: block;
}
.box_g .pickup_item a .item_comment {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 12px;
  margin: 0;
  font-weight: bold;
  margin-bottom: 6px;
  color: #dc1e1e;
}

@media only screen and (min-width: 767px) {
  .pack_item .pickup_item:nth-of-type(1),
  .pack_item .pickup_item:nth-of-type(2) {
    height: 180px !important;
    width: 590px !important;
  }
  .pack_item .pickup_item:nth-of-type(1) .item_photo,
  .pack_item .pickup_item:nth-of-type(2) .item_photo {
    width: 295px;
    height: 178px;
  }
  .pack_item .pickup_item:nth-of-type(1) dl,
  .pack_item .pickup_item:nth-of-type(2) dl {
    width: 293px;
  }
  .pack_item .pickup_item:nth-of-type(1) dl dt,
  .pack_item .pickup_item:nth-of-type(2) dl dt {
    padding: 30px 10px 0 10px;
    height: 108px;
  }
}

@media only screen and (max-width: 767px) {
  .wp_pickup dd .item_comment {
    font-size: 12px !important;
    color: #444 !important;
    height: auto !important;
    text-overflow: unset !important;
    white-space: normal !important;
    font-weight: normal !important;
  }
}
@media only screen and (min-width: 767px) {
  .wp_pickup dt {
    height: 55px !important;
  }
  .wp_pickup dd {
    height: 83px !important;
  }
  .wp_pickup dd .item_comment {
    font-size: 12px !important;
    color: #444 !important;
    height: auto !important;
    text-overflow: unset !important;
    white-space: normal !important;
    font-weight: normal !important;
  }
}
@media only screen and (min-width: 767px) {
  .wp_pickup .pickup_item:nth-of-type(1),
  .wp_pickup .pickup_item:nth-of-type(2) {
    height: 180px !important;
    width: 590px !important;
  }
  .wp_pickup .pickup_item:nth-of-type(1) .item_photo,
  .wp_pickup .pickup_item:nth-of-type(2) .item_photo {
    width: 295px;
    height: 178px;
  }
  .wp_pickup .pickup_item:nth-of-type(1) dl,
  .wp_pickup .pickup_item:nth-of-type(2) dl {
    width: 293px;
  }
  .wp_pickup .pickup_item:nth-of-type(1) dl dt,
  .wp_pickup .pickup_item:nth-of-type(2) dl dt {
    padding: 30px 10px 0 10px;
    height: 70px !important;
  }
  .wp_pickup .pickup_item:nth-of-type(1) dl dd,
  .wp_pickup .pickup_item:nth-of-type(2) dl dd {
    height: 78px !important;
    padding: 0 10px 0 10px;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .wp_pickup .pickup_item:nth-of-type(1) dl dd .item_comment,
  .wp_pickup .pickup_item:nth-of-type(2) dl dd .item_comment {
    font-size: 12px;
    color: #444;
    height: auto;
    text-overflow: unset;
    white-space: normal;
    font-weight: normal;
  }
}

/* --------------------------------
        人気フォントランキングブロック
        -------------------------------- */
@media only screen and (min-width: 767px) {
  .p_ranking .pl26 {
    padding-left: 26px !important;
  }
}
.p_ranking h2 {
  margin-top: 0;
}
.p_ranking h3 {
  margin: 0 0 35px 0;
  font-size: 16px;
  font-weight: bold;
}
.p_ranking h3 i {
  margin-right: 6px;
  font-size: 21px;
}
@media only screen and (max-width: 767px) {
  .p_ranking .list {
    height: 120px;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
  }
  .p_ranking .list:last-of-type {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 767px) {
  .p_ranking .list {
    height: 147px;
    margin-right: 26px;
    margin-bottom: 30px;
    position: relative;
  }
}
.p_ranking .list__right {
  margin-right: 0;
}
.p_ranking .list__r1 {
  background-color: rgb(209, 190, 165);
}
.p_ranking .list__r1:hover {
  background-color: rgba(209, 190, 165, 0.777);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.p_ranking .list__r1 .rank_num {
  color: #d3b182;
}
.p_ranking .list__r2 {
  background-color: rgb(183, 178, 171);
}
.p_ranking .list__r2:hover {
  background-color: rgba(183, 178, 171, 0.777);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.p_ranking .list__r2 .rank_num {
  color: #b7b2ab;
}
.p_ranking .list__r3 {
  background-color: rgb(192, 153, 146);
}
.p_ranking .list__r3:hover {
  background-color: rgba(192, 153, 146, 0.777);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.p_ranking .list__r3 .rank_num {
  color: #cda098;
}
.p_ranking .list a {
  display: block;
  font-size: 0;
  letter-spacing: 0;
  color: #444;
}
@media only screen and (max-width: 767px) {
  .p_ranking .list a {
    padding: 5px;
  }
}
@media only screen and (min-width: 767px) {
  .p_ranking .list a {
    padding: 20px 20px 20px 20px;
  }
}
.p_ranking .list a:hover {
  color: #444;
}
.p_ranking .list a .img {
  display: inline-block;
  width: calc(50% - 20px);
  height: 107px;
  margin-right: 20px;
  font-size: 19px;
  color: rgba(255, 255, 255, 0.6784313725);
  line-height: 107px;
  text-align: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.p_ranking .list a .img__dlfont_1 {
  background-image: url(../img/parts/p_rank/dlfont_hpnw6.png);
  opacity: 0.3;
}
.p_ranking .list a .img__dlfont_1_1 {
  background-image: url(../img/parts/p_rank/olfont_ranking01_hpnw8.png);
  opacity: 0.3;
}
.p_ranking .list a .img__dlfont_1_2 {
  background-image: url(../img/parts/p_rank/dlfont_ranking01_hsnw6.png);
  opacity: 0.3;
}
.p_ranking .list a .img__dlfont_2 {
  background-image: url(../img/parts/p_rank/dlfont_hsnw8.png);
  opacity: 0.3;
}
.p_ranking .list a .img__dlfont_3 {
  background-image: url(../img/parts/p_rank/dlfont_ar_kanteiryuh.png);
  opacity: 0.3;
}
.p_ranking .list a .img__dlfont_3_2 {
  background-image: url(../img/parts/p_rank/dlfont_Rodin_Pro_M.png);
  opacity: 0.3;
}
.p_ranking .list a .img__olfont_1 {
  background-image: url(../img/parts/p_rank/olfont_hakusyutensyo.png);
  opacity: 0.3;
}
.p_ranking .list a .img__olfont_2 {
  background-image: url(../img/parts/p_rank/olfont_tomiten.png);
  opacity: 0.3;
}
.p_ranking .list a .img__olfont_3 {
  background-image: url(../img/parts/p_rank/olfont_oohige113.png);
  opacity: 0.3;
}
.p_ranking .list a .img__olfont_3_2 {
  background-image: url(../img/parts/p_rank/olfont_iwata_kanteiryu.png);
  opacity: 0.3;
}
.p_ranking .list a .detail {
  display: inline-block;
  width: 50%;
  height: 107px;
  padding: 10px 0 10px 21px;
  vertical-align: top;
  border-left: 1px solid rgba(255, 255, 255, 0.2588235294);
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .p_ranking .list a .detail {
    font-size: 10px;
  }
}
@media only screen and (min-width: 767px) {
  .p_ranking .list a .detail {
    font-size: 12px;
  }
}
.p_ranking .list a .detail .name {
  font-size: 12px;
  color: #fff;
  margin-bottom: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.p_ranking .list a .detail .tag {
  font-size: 10px;
  font-weight: bold;
  color: #dc1e1e;
  height: 14px;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .p_ranking .list a .detail .moji {
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 767px) {
  .p_ranking .list a .detail .moji {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .p_ranking .list a .detail .price {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    margin-right: 5px;
  }
  .p_ranking .list a .detail .price span {
    font-size: 12px;
    margin-right: 5px;
  }
}
@media only screen and (min-width: 767px) {
  .p_ranking .list a .detail .price {
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    margin-right: 5px;
  }
  .p_ranking .list a .detail .price span {
    font-size: 18px;
    margin-right: 5px;
  }
}
.p_ranking .list a .detail .cam_color {
  color: #dc1e1e;
}
.p_ranking .list .rank_num {
  position: absolute;
  top: -12px;
  left: -7px;
  width: 40px;
  height: 40px;
  font-size: 20px;
  background: #444;
  border-radius: 40px;
  text-align: center;
  line-height: 40px;
}
@media only screen and (max-width: 767px) {
  .p_ranking .list .rank_num {
    left: -3px;
  }
}

/************************************************
        商品一覧、商品詳細、サイドバー用
        ************************************************ */
@media only screen and (max-width: 767px) {
  .cat_desc {
    display: block;
    background: #ffcc00;
    margin: 20px 0 40px;
    padding: 20px;
  }
  .cat_desc h2 {
    margin: 0;
    vertical-align: top;
    border-left: 5px solid #fff;
    padding-left: 12px;
  }
  .cat_desc p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-top: 20px;
  }
  .cat_desc p a {
    color: #111;
    text-decoration: underline;
  }
  .cat_desc p a:hover {
    color: #111;
    text-decoration: underline;
  }
}
@media only screen and (min-width: 767px) {
  .cat_desc {
    display: block;
    background: #ffcc00;
    margin: 20px 0 40px;
    padding: 20px;
  }
  .cat_desc h2 {
    display: inline-block;
    font-size: 20px;
    width: 300px;
    margin: 0;
    vertical-align: top;
    border-left: 5px solid #fff;
    padding-left: 12px;
  }
  .cat_desc p {
    display: inline-block;
    width: 520px;
    vertical-align: top;
    margin: 0;
    line-height: 1.8em;
  }
  .cat_desc p a {
    color: #111;
    text-decoration: underline;
  }
  .cat_desc p a:hover {
    color: #111;
    text-decoration: underline;
  }
}

@media only screen and (max-width: 767px) {
  #item_list .flexbox {
    display: block !important;
  }
}
@media only screen and (max-width: 767px) {
  #item_list .flexbox {
    display: block !important;
  }
}
#tabbox:last-of-type {
  margin-bottom: 40px;
}
#tabbox .active {
  background-color: #fff;
  color: inherit;
}
#tabbox .active i:before {
  content: "\f068";
}
#tabbox dt {
  display: block;
  border-bottom: 5px solid #444;
  background-color: #4d4d4d;
  color: #fff;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  #tabbox dt {
    padding: 20px 8px;
  }
}
@media only screen and (min-width: 767px) {
  #tabbox dt {
    padding: 20px;
  }
}
#tabbox dt h2 {
  font-weight: bold;
  margin: 0;
  padding: 0;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  #tabbox dt h2 {
    font-size: 18px;
  }
}
@media only screen and (min-width: 767px) {
  #tabbox dt h2 {
    font-size: 20px;
  }
}
#tabbox dt i {
  float: right;
  font-size: 20px;
  line-height: 26px;
  color: #818181;
}
@media only screen and (max-width: 767px) {
  #tabbox dd {
    display: none;
    padding: 40px 0px;
  }
}
@media only screen and (min-width: 767px) {
  #tabbox dd {
    display: none;
    padding: 40px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .list_cate {
    text-align: center;
  }
}
@media only screen and (min-width: 767px) {
  .list_cate {
    margin: 0px -10px;
  }
}
.list_cate ul li {
  font-size: 10px;
  width: 66px;
  display: inline-block;
  vertical-align: top;
  margin: 0 9px 30px 9px;
}
.list_cate ul li a {
  display: block;
  color: #999;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list_cate ul li a .font_icon {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -ms-flexbox;
  display: -o-flex;
  width: 66px;
  height: 66px;
  background-color: #f2f2f2;
  margin-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 13px;
}
.list_cate ul li a .font_icon .catimg-0 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img0.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-1 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img1.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-2 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img2.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-3 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img3.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-4 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img4.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-5 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img5.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-6 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img6.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-7 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img7.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-8 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img8.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-9 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img9.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-10 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img10.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-11 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img11.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-12 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img12.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-13 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img13.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-14 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img14.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-15 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img15.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-16 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img16.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-17 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img17.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-18 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img18.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-19 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img19.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-20 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img20.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-21 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img21.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-22 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img22.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-23 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img23.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-24 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img24.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-25 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img25.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-26 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img26.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-27 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img27.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-28 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img28.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-29 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img29.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-30 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img30.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-31 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img31.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-32 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img32.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-33 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img33.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-34 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img34.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-35 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img35.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-36 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img36.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-37 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img37.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-38 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img38.png");
  background-repeat: no-repeat;
}
.list_cate ul li a .font_icon .catimg-39 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img39.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover {
  color: #444;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.list_cate ul li a:hover .font_icon {
  background-color: #fff;
  box-shadow: 0 0 0 2px #b3b3b3 inset;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.list_cate ul li a:hover .catimg-0 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img0_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-1 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img1_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-2 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img2_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-3 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img3_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-4 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img4_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-5 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img5_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-6 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img6_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-7 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img7_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-8 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img8_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-9 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img9_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-10 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img10_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-11 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img11_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-12 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img12_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-13 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img13_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-14 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img14_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-15 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img15_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-16 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img16_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-17 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img17_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-18 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img18_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-19 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img19_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-20 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img20_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-21 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img21_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-22 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img22_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-23 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img23_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-24 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img24_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-25 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img25_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-26 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img26_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-27 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img27_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-28 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img28_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-29 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img29_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-30 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img30_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-31 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img31_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-32 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img32_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-33 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img33_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-34 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img34_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-35 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img35_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-36 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img36_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-37 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img37_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-38 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img38_on.png");
  background-repeat: no-repeat;
}
.list_cate ul li a:hover .catimg-39 {
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img39_on.png");
  background-repeat: no-repeat;
}

@media only screen and (max-width: 767px) {
  .list_maker ul li {
    font-size: 12px;
    display: block;
    width: 100%;
    vertical-align: top;
    padding-left: 8px;
    position: relative;
    border-bottom: 1px dotted #ddd;
    height: 50px;
    line-height: 50px;
  }
  .list_maker ul li a {
    display: block;
    color: #999;
  }
  .list_maker ul li a i {
    margin-right: 7px;
  }
  .list_maker ul li a:hover {
    color: #444;
  }
}
@media only screen and (min-width: 767px) {
  .list_maker {
    margin: 0 -10px -20px;
  }
  .list_maker ul li {
    font-size: 12px;
    display: inline-block;
    margin: 0 10px 20px;
    width: 190px;
    vertical-align: top;
    padding-left: 14px;
    position: relative;
  }
  .list_maker ul li a {
    color: #999;
  }
  .list_maker ul li a i {
    position: absolute;
    top: 2px;
    left: 0;
  }
  .list_maker ul li a:hover {
    color: #444;
  }
}

@media only screen and (max-width: 767px) {
  .result_list:nth-of-type(1) {
    margin-bottom: 0;
  }
  .result_list:nth-of-type(2) {
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    padding-bottom: 40px !important;
  }
}
@media only screen and (min-width: 767px) {
  .result_list:nth-of-type(1) {
    margin-bottom: 0;
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .result_list:nth-of-type(2) {
    border-bottom: 1px solid #ddd;
    margin-bottom: 40px;
    margin-left: 20px !important;
    margin-right: 20px !important;
    padding-bottom: 40px !important;
    padding-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .result_list .title_area {
    flex-basis: auto;
    display: inline-block;
    width: 100%;
  }
  .result_list .title_area h2 {
    margin-top: 0;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  .result_list .title_area h2 a {
    color: #444;
  }
  .result_list .title_area h2 a:hover {
    text-decoration: underline;
  }
  .result_list .title_area .maker {
    color: #818181;
    font-size: 12px;
  }
}
@media only screen and (min-width: 767px) {
  .result_list .title_area {
    flex-basis: auto;
    display: inline-block;
  }
  .result_list .title_area h2 {
    margin-top: 0;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 2px;
  }
  .result_list .title_area h2 a {
    color: #444;
  }
  .result_list .title_area h2 a:hover {
    text-decoration: underline;
  }
  .result_list .title_area .maker {
    color: #818181;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .result_list .tag_area {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 10px !important;
    margin-top: 10px;
    width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  .result_list .tag_area {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
  }
}
.result_list .tag_area .not_supported {
  display: none;
}
.result_list .tag_area .supported {
  display: inline-block;
}
.result_list .tag_area .supported span {
  padding: 10px 15px;
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .result_list .item_photo {
    width: 100%;
    height: 120px;
    margin: 0;
    line-height: 85px;
    border: 1px solid #ddd;
    overflow: hidden;
    padding: 15px 20px 20px 20px;
    text-align: center;
    display: block;
  }
}
@media only screen and (min-width: 767px) {
  .result_list .item_photo {
    width: 507px;
    height: 120px;
    margin: 0;
    line-height: 85px;
    border: 1px solid #ddd;
    overflow: hidden;
    padding: 15px 20px 20px 20px;
    text-align: center;
    display: block;
  }
}
.result_list .item_photo img {
  max-height: 100%;
}
@media only screen and (max-width: 767px) {
  .result_list .item_price {
    font-size: 10px;
    margin: 10px;
  }
}
@media only screen and (min-width: 767px) {
  .result_list .item_price {
    font-size: 12px;
  }
}
.result_list .item_price:last-of-type {
  padding-bottom: 7px;
}
.result_list .item_price span:first-child {
  font-size: 14px;
  margin-right: 0;
}
.result_list .item_price .box {
  display: inline-block;
  font-size: 10px;
  text-align: right;
  line-height: 1em;
}
.result_list .item_price .box span {
  font-size: 10px;
  display: block;
  text-align: left;
}
.result_list .item_price .moji {
  display: block;
  text-align: right;
}
.result_list .item_detail_area {
  text-align: right;
}
.result_list .item_list_btn_area {
  display: inline-block;
}

.flexbox {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -ms-flexbox;
  display: -o-flex;
}
.flexbox .item_detail_area {
  align-self: flex-end;
}

#pagination_wrap {
  margin-bottom: 110px;
}
#pagination_wrap ul {
  font-size: 0;
  letter-spacing: 0;
  text-align: center;
}
#pagination_wrap ul li {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  #pagination_wrap ul li a {
    font-size: 12px;
    display: inline-block;
    border: solid 1px #ddd;
    letter-spacing: normal;
    margin: 0 5px;
    width: 30px;
    height: 40px;
    transition: ease 0.2s;
    line-height: 40px;
    color: #444;
  }
}
@media only screen and (min-width: 767px) {
  #pagination_wrap ul li a {
    font-size: 12px;
    display: inline-block;
    border: solid 1px #ddd;
    letter-spacing: normal;
    margin: 0 10px;
    width: 40px;
    height: 40px;
    transition: ease 0.2s;
    line-height: 40px;
    color: #444;
  }
}
#pagination_wrap ul li a:hover {
  border-color: #444;
}
#pagination_wrap ul .active a {
  border-color: #444;
}

/* --------------------------------
                サイドバー
                -------------------------------- */
.sidebar ul li {
  margin-bottom: 10px;
}
.sidebar ul li a {
  display: block;
  color: #444;
}
.sidebar .side_list h4 {
  margin: 0;
  font-size: 16px;
  padding-left: 30px;
  position: relative;
}
.sidebar .side_list h4 i {
  margin-right: 6px;
  font-size: 21px;
  line-height: 31px;
  position: absolute;
  left: 0;
  bottom: -4px;
}
.sidebar .side_list ul {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
}
.sidebar .side_list ul li {
  margin-bottom: 0;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}
.sidebar .side_list ul li:last-of-type {
  border-bottom: none;
}
.sidebar .side_list ul li a {
  display: block;
  color: #444;
}
.sidebar .side_list ul li a:hover .item_text {
  text-decoration: underline;
}
.sidebar .side_list ul li a:hover .item_photo {
  opacity: 0.777;
}
.sidebar .side_list ul li a .item_photo {
  display: inline-block;
  width: 103px;
  height: 36px;
}
@media only screen and (max-width: 767px) {
  .sidebar .side_list ul li a .item_text {
    display: inline-block;
    width: calc(100% - 103px);
    padding-left: 10px;
    vertical-align: top;
  }
}
@media only screen and (min-width: 767px) {
  .sidebar .side_list ul li a .item_text {
    display: inline-block;
    width: 197px;
    padding-left: 10px;
    vertical-align: top;
  }
}
.sidebar .side_list .morelink {
  font-size: 14px;
  text-align: right;
  display: block;
  color: #444;
}
.sidebar .side_list .morelink:hover {
  text-decoration: underline;
}
.sidebar .side_list .morelink i {
  margin-left: 8px;
  font-size: 20px;
  vertical-align: middle;
}

/* --------------------------------
                商品詳細
                -------------------------------- */
@media only screen and (max-width: 767px) {
  .detail_top_area {
    padding: 0;
    margin: 20px 0 0 0 !important;
  }
}
@media only screen and (min-width: 767px) {
  .detail_top_area {
    padding: 20px 20px 0;
    margin-top: 20px;
  }
}

.title_area {
  display: inline-block;
  flex-basis: auto;
}
.title_area .product_name {
  margin: 0;
  font-size: 14px;
  margin-bottom: -1px;
  font-weight: bold;
}
.title_area .maker_name {
  color: #818181;
  font-size: 12px;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .tag_area {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: 10px !important;
  }
}
@media only screen and (min-width: 767px) {
  .tag_area {
    display: inline-block;
    vertical-align: top;
    padding-left: 20px;
    min-width: 313px;
    text-align: right;
    float: right;
    flex-basis: auto;
    margin-right: 0;
    margin-left: auto;
  }
}
.tag_area .not_supported {
  display: none;
}
.tag_area .supported {
  display: inline-block;
}
.tag_area .supported span {
  padding: 10px 15px;
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
  display: block;
}

@media only screen and (min-width: 767px) {
  .font_detail {
    padding: 0 20px;
  }
}
.font_detail .detail_top_img_area {
  padding: 80px 0 130px 0;
}
.font_detail .box_line .item_name {
  font-size: 18px;
}
.font_detail .outline_detail {
  border-top: 1px solid #ddd;
  padding: 40px 20px 0 0;
  margin-right: -20px;
}
.font_detail .font_dtetail_s {
  margin-top: 70px;
}
.font_detail .simulate_options h3 {
  margin: 0 0 20px 0;
}
.font_detail .simulate_options h3 .desc03 {
  font-size: 12px;
  font-weight: normal;
  color: #d91e27;
  line-height: 16px;
  display: block;
}
@media only screen and (max-width: 767px) {
  .font_detail .simulate_options .simulation_result {
    width: 100%;
    height: 120px;
    margin: 0;
    line-height: 100px;
    border: 1px solid #ddd;
    overflow: hidden;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: center;
  }
}
@media only screen and (min-width: 767px) {
  .font_detail .simulate_options .simulation_result {
    width: 507px;
    height: 120px;
    margin: 0;
    line-height: 100px;
    border: 1px solid #ddd;
    overflow: hidden;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: center;
  }
}
.font_detail .simulate_options .simulation_result img {
  max-height: 100%;
}
@media only screen and (max-width: 767px) {
  .font_detail .simulate_options .sim_text {
    width: calc(100% - 37px);
    height: 60px;
    background: #f2f2f2;
    border-radius: 4px;
    display: inline-block;
    position: relative;
  }
  .font_detail .simulate_options .sim_text:before {
    width: 40px;
    height: 40px;
    background-image: url(../img/common/serchform/text.gif);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 22px;
    left: 10px;
  }
  .font_detail .simulate_options .sim_text input[type=text] {
    border-radius: 48px 0 0 48px;
    width: calc(100% - 60px);
    height: 60px;
    background: #f2f2f2;
    border: 0;
    padding: 0px 0 0 30px;
    font-size: 14px;
    vertical-align: top;
    box-shadow: none !important;
  }
  .font_detail .simulate_options .sim_text input[type=text]:focus {
    outline: none;
    box-shadow: none;
  }
  .font_detail .simulate_options .sim_text input[type=text]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
  }
  .font_detail .simulate_options .sim_text a {
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
    line-height: 60px;
  }
  .font_detail .simulate_options .sim_text a:hover i {
    color: #444;
  }
  .font_detail .simulate_options .sim_text a i {
    font-size: 18px;
    border-left: 1px solid #ddd;
    color: #999;
    display: inline-block;
    padding: 0 20px;
  }
}
@media only screen and (min-width: 767px) {
  .font_detail .simulate_options .sim_text {
    width: 470px;
    height: 60px;
    background: #f2f2f2;
    border-radius: 4px;
    display: inline-block;
    position: relative;
  }
  .font_detail .simulate_options .sim_text:before {
    width: 40px;
    height: 40px;
    background-image: url(../img/common/serchform/text.gif);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 22px;
    left: 20px;
  }
  .font_detail .simulate_options .sim_text input[type=text] {
    border-radius: 48px 0 0 48px;
    width: 360px;
    height: 60px;
    background: #f2f2f2;
    border: 0;
    margin: 0 0 0 46px;
    padding: 0 2px;
    font-size: 14px;
    vertical-align: top;
    box-shadow: none !important;
  }
  .font_detail .simulate_options .sim_text input[type=text]:focus {
    outline: none;
  }
  .font_detail .simulate_options .sim_text input[type=text]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
  }
  .font_detail .simulate_options .sim_text a {
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    margin: 0;
    padding: 0;
    line-height: 60px;
  }
  .font_detail .simulate_options .sim_text a:hover i {
    color: #444;
  }
  .font_detail .simulate_options .sim_text a i {
    font-size: 18px;
    border-left: 1px solid #ddd;
    color: #999;
    display: inline-block;
    padding: 0px 20px;
  }
}
@media only screen and (max-width: 767px) {
  .font_detail table {
    width: 100%;
    margin-bottom: 60px;
    margin: 0 0 60px 0px;
    table-layout: fixed;
  }
  .font_detail table tbody {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  .font_detail table tbody tr td {
    padding: 15px 30px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    word-break: break-all;
  }
}
@media only screen and (min-width: 767px) {
  .font_detail table {
    width: 800px;
    margin-bottom: 60px;
    margin: 0 0 60px 20px;
  }
  .font_detail table tbody {
    border-top: 1px solid #ddd;
    border-left: 1px solid #ddd;
  }
  .font_detail table tbody tr td {
    padding: 15px 30px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

.search_form_btn_reset_outline {
  width: 37px;
  height: 60px;
  padding: 0;
  padding-left: 20px;
  margin: 0;
  font-size: 18px;
  line-height: 57px;
  vertical-align: top;
  color: #999;
  display: inline-block;
}
.search_form_btn_reset_outline:hover {
  color: #444;
}

@media only screen and (max-width: 767px) {
  #item_detail {
    padding: 0 0px;
  }
}
@media only screen and (min-width: 767px) {
  #item_detail {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .description_detail {
    font-size: 14px;
    line-height: 2em;
  }
  .description_detail .content {
    min-height: 0px;
    padding-bottom: 0px;
    margin-bottom: 35px;
    border-bottom: 1px solid #ddd;
    word-break: break-all;
  }
}
@media only screen and (min-width: 767px) {
  .description_detail {
    width: 507px;
    font-size: 14px;
    line-height: 2em;
    display: inline-block;
  }
  .description_detail .content {
    border-right: 1px solid #ddd;
    padding-right: 20px;
    min-height: 150px;
    word-break: break-all;
  }
}

@media only screen and (max-width: 767px) {
  .products_info .dl_cart_area {
    margin: 0 0px 0 0;
    text-align: right;
  }
}
@media only screen and (min-width: 767px) {
  .products_info {
    display: inline-block;
    width: 313px;
    float: right;
  }
  .products_info .dl_cart_area {
    margin: 0 -20px 0 0;
    text-align: right;
  }
  .products_info .dl_cart_area form {
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .products_info .result_list {
    border-bottom: none !important;
    margin-bottom: 30px;
    padding: 0;
    padding-bottom: 0 !important;
    margin: 0 0px 30px !important;
  }
}
@media only screen and (min-width: 767px) {
  .products_info .result_list {
    border-bottom: none !important;
    margin-bottom: 30px;
    padding: 0;
    padding-bottom: 0 !important;
    margin: 0 20px 30px !important;
  }
}
.products_info .info {
  color: #999;
  background-color: #fff;
  border-color: #fff;
  padding: 30px 0;
  text-align: right;
  font-size: 12px;
}
.products_info .info:hover {
  color: #444;
}
.products_info .info:active {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.products_info .info:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.products_info .info i {
  margin-left: 8px;
}

@media only screen and (max-width: 767px) {
  #item_photo_area #detail_image_box__slides {
    width: 100%;
    padding: 10px 0 0;
  }
}
@media only screen and (min-width: 767px) {
  #item_photo_area #detail_image_box__slides {
    width: 820px;
    padding: 10px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  #item_photo_area .slick-list {
    width: 100%;
    height: auto;
    display: block;
  }
  #item_photo_area .slick-list .slick-slide {
    height: 215px;
    text-align: center;
    line-height: 215px;
  }
  #item_photo_area .slick-list .slick-slide img {
    max-height: 100%;
    display: inline-block;
  }
}
@media only screen and (min-width: 767px) {
  #item_photo_area .slick-list {
    width: 507px;
    height: 215px;
    display: inline-block;
  }
  #item_photo_area .slick-list .slick-slide {
    height: 215px;
    text-align: center;
    line-height: 215px;
  }
  #item_photo_area .slick-list .slick-slide img {
    max-height: 100%;
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  #item_photo_area ul {
    font-size: 0;
    width: 100%;
    display: inline-block !important;
    vertical-align: bottom;
    margin: 10px 0 0;
  }
}
@media only screen and (min-width: 767px) {
  #item_photo_area ul {
    font-size: 0;
    width: 320px;
    display: inline-block !important;
    vertical-align: bottom;
    margin: 0 -10px -5px 0;
  }
}
#item_photo_area ul li {
  width: 54px;
  height: 54px;
  display: inline-block;
  border: 1px solid #ddd;
  border-radius: 2px;
  vertical-align: top;
  margin: 0 10px 10px 0;
  line-height: 50px;
}
#item_photo_area ul li button {
  background: none;
  border: none;
  outline: none;
  border-image-width: 0;
  width: 100%;
  height: auto;
}
#item_photo_area ul li button::before {
  display: none;
}
#item_photo_area ul li button img {
  width: 100%;
  height: auto;
}

.result_list #detail_cart_box__cart_quantity {
  margin-bottom: 15px;
  font-size: 14px;
}
.result_list #detail_cart_box__cart_quantity .errormsg {
  color: red;
  display: inline-block;
  position: relative;
  font-size: 12px;
  font-weight: bold;
  padding-left: 18px;
}
.result_list #detail_cart_box__cart_quantity .errormsg::before {
  position: absolute;
  content: "\f06a";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  left: 0;
  top: -3px;
}
.result_list #detail_cart_box__cart_quantity input[type=number],
.result_list #detail_cart_box__cart_quantity input[type=button] {
  display: inline-block;
}
.result_list #detail_cart_box__cart_quantity input[type=number]:focus,
.result_list #detail_cart_box__cart_quantity input[type=button]:focus {
  box-shadow: none;
}
.result_list #detail_cart_box__button_area button {
  vertical-align: top;
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .result_list #detail_cart_box {
    text-align: right;
    margin-right: 0px;
  }
}
@media only screen and (min-width: 767px) {
  .result_list #detail_cart_box {
    text-align: right;
    margin-right: -20px;
  }
}
.result_list #detail_cart_box .item_price {
  padding-bottom: 7px;
}
.result_list #detail_cart_box input[type=number]::-webkit-outer-spin-button,
.result_list #detail_cart_box input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.result_list #detail_cart_box input[type=number] {
  appearance: textfield;
  -moz-appearance: textfield;
}
.result_list #detail_cart_box input[type=number]:focus {
  outline: none;
}
.result_list #detail_cart_box input[type=number]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
}
.result_list #detail_cart_box input[type=button]:focus {
  outline: none;
}
.result_list #detail_cart_box input[type=button]:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
}
.result_list #detail_cart_box .form-control {
  width: 38px;
  height: 38px;
  text-align: center;
  border: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.result_list #detail_cart_box .plus {
  width: 38px;
  height: 38px;
  vertical-align: top;
  border: 1px solid #ddd;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #f2f2f2;
  color: #999;
}
.result_list #detail_cart_box .minus {
  width: 38px;
  height: 38px;
  vertical-align: top;
  border: 1px solid #ddd;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  background-color: #f2f2f2;
  color: #999;
}

@media only screen and (max-width: 767px) {
  #related_product_area {
    border-top: none !important;
    margin: 0 -8px !important;
  }
  #related_product_area .flexbox {
    display: block;
  }
  #related_product_area .tag_area {
    margin-top: 10px;
  }
  #related_product_area .main_btn {
    width: 100% !important;
  }
  #related_product_area .item_name {
    overflow: hidden;
    text-overflow: unset !important;
    white-space: unset !important;
  }
}
#detail_free_box__freearea {
  line-height: 2em;
  font-size: 14px;
  word-break: break-all;
}

#cboxLoadedContent #content {
  padding: 20px;
}

/* --------------------------------
                    シミレーションフォーム
                    -------------------------------- */
@media only screen and (max-width: 767px) {
  .font_sim_tabox {
    background-color: #4d4d4d;
    padding: 0px 28px 35px;
    margin: 0 -8px 40px;
  }
  .font_sim_tabox h2 {
    margin: 0;
    color: #fff;
    padding: 0.83em 0;
    border-bottom: 1px solid #717171;
    font-size: 18px;
  }
}
@media only screen and (min-width: 767px) {
  .font_sim_tabox {
    background-color: #4d4d4d;
    padding: 0px 20px 35px;
    margin: 0 0 70px;
  }
  .font_sim_tabox h2 {
    margin: 0;
    color: #fff;
    padding: 0.83em 0;
    border-bottom: 1px solid #717171;
    font-size: 18px;
  }
}

#searchform .s_form_title {
  margin-bottom: 30px;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  #searchform .s_form_title {
    font-size: 14px;
    color: #fff;
    margin: 18px 0 18px;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .s_form_title {
    font-size: 16px;
    margin: 35px 0 18px;
    color: #fff;
    text-align: center;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_t_c_box {
    margin: 0 auto;
    width: 521px;
  }
}
@media only screen and (max-width: 767px) {
  #searchform .sim_text {
    width: 100%;
    height: 50px;
    background: #fff;
    border-radius: 4px 4px 0 0;
    display: inline-block;
    position: relative;
  }
  #searchform .sim_text:before {
    width: 40px;
    height: 17px;
    background-image: url(../img/common/serchform/text.gif);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 17px;
    left: 20px;
  }
  #searchform .sim_text input[type=text] {
    border-radius: 4px 4px 0 0;
    width: 100%;
    height: 50px;
    background: #fff;
    border: 0;
    padding: 0 0 0 46px;
    font-size: 14px;
    vertical-align: top;
  }
  #searchform .sim_text input[type=text]:focus {
    outline: none;
    box-shadow: none;
  }
  #searchform .sim_text input[type=text]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_text {
    width: 334px;
    height: 50px;
    background: #fff;
    border-radius: 4px 0 0 4px;
    display: inline-block;
    position: relative;
  }
  #searchform .sim_text:before {
    width: 40px;
    height: 17px;
    background-image: url(../img/common/serchform/text.gif);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 17px;
    left: 20px;
  }
  #searchform .sim_text input[type=text] {
    border-radius: 0;
    width: 280px;
    height: 50px;
    background: #fff;
    border: 0;
    margin: 0 0 0 46px;
    padding: 0 2px;
    font-size: 14px;
    vertical-align: top;
  }
  #searchform .sim_text input[type=text]:focus {
    outline: none;
    box-shadow: none;
  }
  #searchform .sim_text input[type=text]:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px rgb(242, 242, 242) inset;
  }
}
@media only screen and (max-width: 767px) {
  #searchform .sim_check {
    display: block;
    height: 50px;
    line-height: 50px;
    vertical-align: top;
    position: relative;
    background-color: #fff;
    border-top: 1px solid #b3b3b3;
    border-radius: 0 0 4px 4px;
    padding-left: 14px;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_check {
    display: inline-block;
    width: 187px;
    height: 50px;
    margin-left: 0;
    line-height: 50px;
    vertical-align: top;
    position: relative;
    background-color: #fff;
    border-left: 1px solid #b3b3b3;
    padding: 0 0 0 19px;
    border-radius: 0 4px 4px 0;
  }
}
#searchform .sim_check .form-check input[type=checkbox] {
  display: none;
}
#searchform .sim_check .form-check label {
  position: relative;
  padding-left: 32px;
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: bold;
  cursor: pointer;
}
#searchform .sim_check .form-check label input[type=checkbox] {
  display: none;
}
#searchform .sim_check .form-check label:before {
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 14px;
  left: 0;
  content: "";
  border: 2px solid #ddd;
  border-radius: 4px;
}
#searchform .sim_check .form-check label:after {
  display: block;
  position: absolute;
  top: -1px;
  left: 4px;
  content: "\f00c";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  color: #999;
  opacity: 0;
}
#searchform .sim_check .form-check .active {
  color: #444;
}
#searchform .sim_check .form-check .active:after {
  color: #444;
  opacity: 1;
}
@media only screen and (max-width: 767px) {
  #searchform .sim_menu_outer {
    width: calc(100% + 32px);
    overflow-x: scroll;
    overflow-y: hidden;
    display: block;
    margin-right: -5px;
    margin-top: 30px;
    margin: 0 -16px;
    padding-left: 16px;
  }
}
@media only screen and (max-width: 767px) {
  #searchform .sim_menu {
    width: 840px;
    display: block;
    margin-right: -5px;
    margin-top: 30px;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_menu {
    display: block;
    margin-right: -5px;
    margin-top: 30px;
    text-align: center;
  }
}
#searchform .sim_menu .active {
  background: #606060;
  border-top: 1px solid #606060;
  border-right: 1px solid #606060;
  border-left: 1px solid #606060;
  color: #444;
}
#searchform .sim_menu .active .col-form-label {
  color: #fff;
}
#searchform .sim_menu .active i:before {
  content: "\f107";
  color: #fff;
}
#searchform .sim_menu li {
  display: inline-block;
  width: 164px;
  height: 40px;
  background: #4d4d4d;
  color: #b2b2b2;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  border-top: 1px solid #4d4d4d;
  border-right: 1px solid #606060;
  border-left: 1px solid #4d4d4d;
  vertical-align: top;
}
#searchform .sim_menu li:hover {
  color: #fff;
}
#searchform .sim_menu li:last-child {
  border-right: 1px solid #4d4d4d;
}
#searchform .sim_menu li i {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #818181;
  cursor: pointer;
  font-size: 18px;
}
#searchform .sim_menu li span {
  display: none;
  line-height: 40px;
  padding: 0 23px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  color: #fff;
}
#searchform .sim_menu li .col-form-label {
  line-height: 40px;
  padding: 0 23px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  border-bottom: none;
}
#searchform .sim_menu_detail .active {
  display: block;
}
@media only screen and (max-width: 767px) {
  #searchform .sim_menu_detail li {
    display: none;
    border: 1px solid #606060;
    background-color: #606060;
    margin-top: -1px;
    font-size: 12px;
    margin-left: -16px;
    margin-right: -16px;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_menu_detail li {
    display: none;
    font-size: 12px;
    background-color: #606060;
  }
}
#searchform .sim_menu_detail li div .form-check:first-child {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  #searchform .sim_menu_detail li #font_category {
    padding: 40px 0 0;
    margin: 0px 20px;
    display: block;
    overflow: hidden;
    height: 270px;
    overflow-y: scroll;
    text-align: center;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_menu_detail li #font_category {
    padding: 40px 13px 0;
    display: block;
  }
}
#searchform .sim_menu_detail li #font_category .form-check {
  display: inline-block;
  margin: 0 6.6px;
  vertical-align: top;
  position: relative;
}
#searchform .sim_menu_detail li #font_category .form-check input[type=radio] {
  width: 40px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  visibility: hidden;
}
#searchform .sim_menu_detail li #font_category .form-check .active {
  color: #fff;
}
#searchform .sim_menu_detail li #font_category .form-check .active:after {
  background-color: #fff;
  box-shadow: 0 0 0 1px #fff inset;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(0) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img0.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(0) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img0_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(0) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img0_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(1) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img1.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(1) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img1_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(1) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img1_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(2) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img2.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(2) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img2_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(2) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img2_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(3) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img3.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(3) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img3_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(3) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img3_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(4) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img4.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(4) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img4_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(4) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img4_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(5) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img5.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(5) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img5_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(5) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img5_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(6) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img6.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(6) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img6_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(6) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img6_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(7) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img7.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(7) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img7_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(7) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img7_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(8) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img8.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(8) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img8_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(8) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img8_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(9) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img9.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(9) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img9_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(9) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img9_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(10) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img10.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(10) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img10_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(10) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img10_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(11) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img11.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(11) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img11_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(11) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img11_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(12) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img12.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(12) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img12_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(12) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img12_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(13) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img13.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(13) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img13_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(13) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img13_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(14) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img14.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(14) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img14_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(14) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img14_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(15) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img15.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(15) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img15_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(15) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img15_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(16) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img16.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(16) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img16_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(16) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img16_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(17) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img17.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(17) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img17_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(17) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img17_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(18) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img18.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(18) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img18_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(18) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img18_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(19) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img19.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(19) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img19_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(19) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img19_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(20) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img20.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(20) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img20_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(20) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img20_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(21) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img21.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(21) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img21_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(21) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img21_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(22) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img22.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(22) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img22_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(22) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img22_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(23) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img23.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(23) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img23_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(23) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img23_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(24) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img24.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(24) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img24_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(24) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img24_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(25) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img25.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(25) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img25_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(25) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img25_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(26) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img26.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(26) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img26_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(26) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img26_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(27) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img27.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(27) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img27_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(27) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img27_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(28) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img28.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(28) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img28_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(28) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img28_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(29) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img29.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(29) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img29_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(29) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img29_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(30) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img30.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(30) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img30_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(30) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img30_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(31) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img31.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(31) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img31_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(31) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img31_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(32) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img32.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(32) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img32_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(32) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img32_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(33) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img33.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(33) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img33_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(33) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img33_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(34) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img34.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(34) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img34_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(34) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img34_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(35) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img35.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(35) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img35_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(35) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img35_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(36) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img36.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(36) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img36_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(36) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img36_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(37) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img37.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(37) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img37_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(37) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img37_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(38) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img38.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(38) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img38_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(38) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img38_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(39) label::before {
  content: "";
  width: 40px;
  height: 40px;
  background-image: url("../img/parts/font_cat/font_cat_img39.png");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: 13px;
  left: 13px;
  z-index: 2;
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(39) label:hover::before {
  background-image: url("../img/parts/font_cat/font_cat_img39_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check:nth-of-type(39) .active::before {
  background-image: url("../img/parts/font_cat/font_cat_img39_on.png");
}
#searchform .sim_menu_detail li #font_category .form-check label {
  display: block;
  width: 66px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-top: 76px;
  font-size: 10px;
  text-align: center;
  color: #b2b2b2;
  margin-bottom: 30px;
  cursor: pointer;
}
#searchform .sim_menu_detail li #font_category .form-check label:after {
  content: "";
  width: 66px;
  height: 66px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #b2b2b2 inset;
}
#searchform .sim_menu_detail li #font_category .form-check label:hover {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#searchform .sim_menu_detail li #font_category .form-check label:hover:after {
  background-color: #fff;
  box-shadow: 0 0 0 1px #fff inset;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767px) {
  #searchform .sim_menu_detail li #weight,
  #searchform .sim_menu_detail li #os,
  #searchform .sim_menu_detail li #type,
  #searchform .sim_menu_detail li #maker {
    padding: 40px 17.5px 5px 17.5px;
    overflow: hidden;
    height: 270px;
    overflow-y: scroll;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .sim_menu_detail li #weight,
  #searchform .sim_menu_detail li #os,
  #searchform .sim_menu_detail li #type,
  #searchform .sim_menu_detail li #maker {
    padding: 40px 17.5px 5px 17.5px;
  }
}
#searchform .sim_menu_detail li #weight .form-check,
#searchform .sim_menu_detail li #os .form-check,
#searchform .sim_menu_detail li #type .form-check,
#searchform .sim_menu_detail li #maker .form-check {
  display: inline-block;
  width: 236px;
  margin: 0 12.5px 25px;
  vertical-align: middle;
  line-height: 1.2em;
  position: relative;
  padding-left: 32px;
  height: 28px;
}
#searchform .sim_menu_detail li #weight .form-check input[type=radio],
#searchform .sim_menu_detail li #os .form-check input[type=radio],
#searchform .sim_menu_detail li #type .form-check input[type=radio],
#searchform .sim_menu_detail li #maker .form-check input[type=radio] {
  display: none;
}
#searchform .sim_menu_detail li #weight .form-check label,
#searchform .sim_menu_detail li #os .form-check label,
#searchform .sim_menu_detail li #type .form-check label,
#searchform .sim_menu_detail li #maker .form-check label {
  min-height: 28px;
  font-size: 14px;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -ms-flexbox;
  display: -o-flex;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  color: #999;
  cursor: pointer;
  height: 28px;
}
#searchform .sim_menu_detail li #weight .form-check label:before,
#searchform .sim_menu_detail li #os .form-check label:before,
#searchform .sim_menu_detail li #type .form-check label:before,
#searchform .sim_menu_detail li #maker .form-check label:before {
  display: block;
  position: absolute;
  width: 22px;
  height: 22px;
  top: 3px;
  left: 0;
  content: "";
  border: 1px solid #b2b2b2;
  border-radius: 4px;
}
#searchform .sim_menu_detail li #weight .form-check label:after,
#searchform .sim_menu_detail li #os .form-check label:after,
#searchform .sim_menu_detail li #type .form-check label:after,
#searchform .sim_menu_detail li #maker .form-check label:after {
  display: block;
  position: absolute;
  top: 7px;
  left: 4px;
  content: "\f00c";
  font-size: 16px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  color: #999;
  opacity: 0;
}
#searchform .sim_menu_detail li #weight .form-check label:hover,
#searchform .sim_menu_detail li #os .form-check label:hover,
#searchform .sim_menu_detail li #type .form-check label:hover,
#searchform .sim_menu_detail li #maker .form-check label:hover {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#searchform .sim_menu_detail li #weight .form-check .active,
#searchform .sim_menu_detail li #os .form-check .active,
#searchform .sim_menu_detail li #type .form-check .active,
#searchform .sim_menu_detail li #maker .form-check .active {
  color: #fff;
}
#searchform .sim_menu_detail li #weight .form-check .active:after,
#searchform .sim_menu_detail li #os .form-check .active:after,
#searchform .sim_menu_detail li #type .form-check .active:after,
#searchform .sim_menu_detail li #maker .form-check .active:after {
  color: #fff;
  opacity: 1;
}
#searchform .sim_menu_detail li #weight .form-check .active:before,
#searchform .sim_menu_detail li #os .form-check .active:before,
#searchform .sim_menu_detail li #type .form-check .active:before,
#searchform .sim_menu_detail li #maker .form-check .active:before {
  border: 1px solid #fff;
}
#searchform .btn_area {
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  #searchform .btn_area .search_form_btn_reset {
    width: 100%;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    line-height: 50px;
    font-size: 14px;
    color: #b2b2b2;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 3px;
    margin-bottom: 20px;
    border: 2px solid #606060;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  #searchform .btn_area .search_form_btn_reset i {
    margin-right: 0.3em;
  }
  #searchform .btn_area .search_form_btn_reset:hover {
    background: #818181;
    border: 2px solid #818181;
    color: #fff;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .btn_area .search_form_btn_reset {
    width: 260px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    line-height: 50px;
    font-size: 14px;
    color: #b2b2b2;
    margin: 0;
    padding: 0;
    position: relative;
    border-radius: 3px;
    border: 2px solid #606060;
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  #searchform .btn_area .search_form_btn_reset i {
    margin-right: 0.3em;
  }
  #searchform .btn_area .search_form_btn_reset:hover {
    background: #818181;
    border: 2px solid #818181;
    color: #fff;
  }
}
@media only screen and (max-width: 767px) {
  #searchform .btn_area .search_form_btn {
    width: 100%;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0;
    margin-left: 0;
    border: 0;
    background-color: #606060;
    font-size: 14px;
    color: #b2b2b2;
    position: relative;
    border-radius: 3px;
    box-shadow: none;
    -webkit-animation-delay: none;
  }
  #searchform .btn_area .search_form_btn i {
    margin-right: 0.3em;
  }
}
@media only screen and (min-width: 767px) {
  #searchform .btn_area .search_form_btn {
    width: 260px;
    height: 50px;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    padding: 0;
    margin-left: 20px;
    border: 0;
    background-color: #606060;
    font-size: 14px;
    color: #b2b2b2;
    position: relative;
    border-radius: 3px;
  }
  #searchform .btn_area .search_form_btn i {
    margin-right: 0.3em;
  }
}
#searchform .btn_area .search_form_btn:hover {
  background-color: #818181;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}
#searchform .btn_area .search_form_btn:active {
  box-shadow: none;
  -webkit-box-shadow: none;
}

/* --------------------------------
                        まとめ割問い合わせフォーム
                        -------------------------------- */
.matomewari input {
  padding: 0 14px;
}
.matomewari .form_coment {
  font-weight: bold;
  text-decoration: underline;
  color: #444;
  line-height: 3em;
  font-size: 16px;
  margin-bottom: 110px;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner {
    width: 100%;
    padding: 0 10px;
  }
  .matomewari .inner p {
    font-size: 16px;
    line-height: 2em;
    margin: 0 0 10px;
    width: 100%;
    vertical-align: top;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner {
    width: 860px;
  }
  .matomewari .inner p {
    font-size: 16px;
    line-height: 2em;
  }
}
.matomewari .inner h1 {
  margin: 70px 0 26px;
  font-size: 38px;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner h1 {
    margin: 26px 0 26px;
    font-size: 26px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner h1 {
    margin: 70px 0 26px;
    font-size: 38px;
  }
}
.matomewari .inner #top_wrap {
  padding: 0px;
}
.matomewari .inner form h2 span {
  display: none;
}
.matomewari .inner form h2 .coment {
  display: inline;
  font-size: 14px;
  font-weight: normal;
  margin-left: 16px;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form .form-group {
    font-size: 11px;
    line-height: 2em;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form .form-group {
    font-size: 14px;
    line-height: 2em;
    margin-left: 38px;
    padding: 20px 0;
  }
}
.matomewari .inner form .form-group .form-check {
  line-height: 3em;
}
.matomewari .inner form .form-group .form-check input[type=checkbox],
.matomewari .inner form .form-group .form-check input[type=radio] {
  display: none;
}
.matomewari .inner form .form-group .form-check label {
  position: relative;
  padding-left: 34px;
  cursor: pointer;
}
.matomewari .inner form .form-group .form-check label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #999;
  border-radius: 50%;
}
.matomewari .inner form .form-group .form-check label.active:before {
  content: "";
  display: block;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 24px;
  height: 24px;
  border: 3px solid #a1a1a1;
  border-radius: 50%;
}
.matomewari .inner form .form-group .form-check label.active:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #ffffff;
  border-radius: 50%;
  border: 6px solid #444;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form .form-group .form-check .cam_label {
    display: inline-block;
    margin-left: 15px;
    font-size: 11px;
    padding: 0px 3px;
    background: red;
    height: 16px;
    line-height: 16px;
    border-radius: 3px;
    color: #fff;
    position: relative;
    margin-top: -3px;
    width: 130px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form .form-group .form-check .cam_label {
    display: inline-block;
    margin-left: 22px;
    font-size: 12px;
    padding: 0px 10px;
    background: red;
    height: 24px;
    line-height: 24px;
    border-radius: 3px;
    color: #fff;
    position: relative;
    margin-top: -3px;
  }
  .matomewari .inner form .form-group .form-check .cam_label:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: -11px;
    border: 1px solid #999;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12px 12px 0;
    border-color: transparent #ff0000 transparent transparent;
  }
}
.matomewari .inner form .form-group .form-check input[type=checkbox] {
  margin-right: 14px;
  display: none;
}
.matomewari .inner form .form-group .selected_detail {
  display: none;
  border-top: 1px solid #ddd;
  margin-bottom: 70px;
  margin-top: 13px;
}
.matomewari .inner form .form-group .selected_detail dl {
  border: none !important;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form .form-group .selected_detail dl dt {
    padding-left: 14px;
    display: block;
    height: 50px;
    line-height: 50px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form .form-group .selected_detail dl dt {
    width: 224px;
    padding-left: 14px;
  }
}
.matomewari .inner form .form-group .selected_detail dl dt label {
  font-size: 14px;
  font-weight: normal;
}
.matomewari .inner form .form-group .selected_detail dl dd {
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form .form-group .selected_detail dl dd select {
    margin: 0 8px 0 4px;
    height: 38px;
    padding: 0 14px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form .form-group .selected_detail dl dd select {
    margin: 0 18px 0 14px;
    height: 38px;
    padding: 0 14px;
  }
}
.matomewari .inner form .form-group .selected_detail dl dd select:first-of-type {
  margin-left: 0;
}
.matomewari .inner form .form-group .errormsg {
  color: red;
  font-weight: bold;
  margin: 0;
}
.matomewari .inner form .form-group .subtitle {
  display: inline-block;
  color: #444;
  font-size: 16px;
  font-weight: bold;
  margin-right: 20px;
}
.matomewari .inner form .form-group .form_coment2 {
  margin-top: 20px;
  color: #999;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form .form-group .form_coment3 {
    display: block;
    margin-left: 20px !important;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form .form-group .form_coment3 {
    display: block;
    margin-left: 280px;
  }
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form dl {
    border-bottom: 1px solid #ddd;
    display: block;
    margin-left: 20px;
    width: 100%;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form dl {
    height: 80px;
    border-bottom: 1px solid #ddd;
    display: table;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form dl dt {
    display: block;
    vertical-align: middle;
    width: 105%;
    padding-left: 18px;
    width: calc(100% - 20px);
    padding-top: 10px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form dl dt {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
    width: 300px;
    padding-left: 18px;
  }
}
.matomewari .inner form dl dt label {
  font-size: 16px;
  font-weight: bold;
}
.matomewari .inner form dl dt span.required {
  margin-left: 14px;
  display: inline-block;
  height: 24px;
  background-color: #f2f2f2;
  color: #777;
  font-size: 10px;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  vertical-align: middle;
  margin-bottom: 4px;
}
@media only screen and (max-width: 767px) {
  .matomewari .inner form dl dd {
    display: block;
    vertical-align: middle;
    width: calc(100% - 20px);
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .inner form dl dd {
    display: table-cell;
    vertical-align: middle;
    height: 80px;
    width: 520px;
  }
}
.matomewari .inner form dl dd .form-group {
  margin-left: 0px;
}
.matomewari .inner form dl dd .input_tel input {
  margin-right: 0;
  margin-left: 0;
  width: calc(33.3% - 10.8px);
  height: 38px;
}
.matomewari .inner form dl dd .input_tel input:last-of-type {
  margin-right: 0;
  width: 33.3%;
}
.matomewari .inner form dl dd .input_tel input:first-of-type {
  margin-left: 0;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .matomewari .inner form dl dd .input_tel input {
    width: 31%;
  }
  .matomewari .inner form dl dd .input_tel input:last-of-type {
    width: 33%;
  }
}
@media only screen and (max-width: 767px) {
  .matomewari button {
    width: 100%;
    height: 50px;
    background: #f2f2f2;
    color: #999;
    font-weight: bold;
    border-radius: 3px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari button {
    width: 220px;
    height: 50px;
    background: #f2f2f2;
    color: #999;
    font-weight: bold;
    border-radius: 3px;
  }
}
.matomewari button:hover {
  color: #999;
  background: #ddd;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.matomewari button:active {
  box-shadow: none;
}
.matomewari button.next {
  background: #4f4f4f;
  color: #f2f2f2;
}
.matomewari button.next:hover {
  background-color: #818181;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .matomewari .matomewari_button {
    width: 100%;
    height: 50px;
    background: #f2f2f2;
    color: #777;
    font-weight: bold;
    border-radius: 3px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 767px) {
  .matomewari .matomewari_button {
    width: 220px;
    height: 50px;
    background: #f2f2f2;
    color: #777;
    font-weight: bold;
    border-radius: 3px;
    line-height: 36px;
  }
}
.matomewari .matomewari_button:hover {
  color: #777;
  background: #ddd;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.matomewari .matomewari_button:active {
  box-shadow: none;
}
.matomewari .matomewari_button__next {
  width: 220px;
  height: 50px;
  background: #f2f2f2;
  color: #999;
  font-weight: bold;
  border-radius: 2px;
  line-height: 36px;
  background: #4f4f4f;
  color: #f2f2f2;
}
.matomewari .matomewari_button__next:hover {
  background-color: #818181;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media only screen and (max-width: 767px) {
  .matomewari #confirm_box__body dt {
    padding-left: 0;
  }
  .matomewari #contactmatomewari_email {
    width: calc(100% - 7px) !important;
  }
  .matomewari #top_box__name dt,
  .matomewari #top_box__kana dt,
  .matomewari #top_box__tel dt,
  .matomewari #top_box__email dt {
    padding-left: 0 !important;
  }
  .matomewari #top_box__name .input_tel,
  .matomewari #top_box__kana .input_tel,
  .matomewari #top_box__tel .input_tel,
  .matomewari #top_box__email .input_tel {
    padding: 10px 0;
  }
  .matomewari #top_box__name .input_name,
  .matomewari #top_box__kana .input_name,
  .matomewari #top_box__tel .input_name,
  .matomewari #top_box__email .input_name {
    padding: 10px 0;
  }
  .matomewari #top_box__name .input_name input,
  .matomewari #top_box__kana .input_name input,
  .matomewari #top_box__tel .input_name input,
  .matomewari #top_box__email .input_name input {
    display: inline-block !important;
    width: 49% !important;
    margin-right: 1% !important;
  }
}
.matomewari #confirm_box__button_menu {
  text-align: center;
}
.matomewari #confirm_box__back_button {
  display: inline-block;
  margin-right: 18px;
}
.matomewari #confirm_box__confirm_button {
  display: inline-block;
}
.matomewari #confirm_box__matome_select_detail dd ul li {
  border-bottom: dotted 1px #ddd;
  padding: 10px 0;
}
.matomewari #confirm_box__matome_select_detail dd ul li:last-of-type {
  border-bottom: none;
}

/* --------------------------------
 カテゴリ独自ページ
-------------------------------- */
.lnewslist {
  border: 4px solid #ddd;
  margin: 53px 30px 0;
  position: relative;
  padding: 25px;
}
.lnewslist .title {
  display: block;
  background-color: #ffcc00;
  padding: 6px;
  position: absolute;
  top: -17px;
  left: -4px;
  height: 26px;
  width: 70px;
  text-align: center;
  font-weight: bold;
}
.lnewslist .date-time {
  color: #595959;
  display: inline-block;
  vertical-align: top;
  font-size: 1.4rem;
}
.lnewslist .dec {
  display: inline-block;
  margin-left: 20px;
  vertical-align: top;
  font-size: 1.4rem;
}

.catpage_def {
  color: #111;
}
.catpage_def .lets_video {
  width: calc(100% - 30px);
  height: auto;
  margin: 0 15px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .lets_video {
    width: 100%;
    margin: 0;
  }
}
.catpage_def .logo_moripass {
  display: block;
  width: 100%;
  height: 35px;
  margin: 20px 0 0;
  border: 1px solid #ddd;
  border-bottom: none;
  text-align: right;
}
.catpage_def .logo_moripass img {
  padding: 10px 22px 10px 0;
}
.catpage_def .marker_y {
  display: inline-block;
  background-image: -webkit-linear-gradient(left, transparent 55%, #ffed2d 55%);
  background-image: -moz-linear-gradient(left, transparent 55%, #ffed2d 55%);
  background-image: -ms-linear-gradient(left, transparent 55%, #ffed2d 55%);
  background-image: -o-linear-gradient(left, transparent 55%, #ffed2d 55%);
  background-image: linear-gradient(left, transparent 55%, #ffed2d 55%);
}
.catpage_def .logo_cate183 {
  display: block;
  width: 100%;
  height: 35px;
  margin: 20px 0 0;
  border: 1px solid #ddd;
  text-align: right;
}
.catpage_def .logo_cate183 img {
  padding: 10px 22px 10px 0;
}
.catpage_def .logo_cate192 {
  display: block;
  width: 100%;
  height: 35px;
  margin: 20px 0 0;
  background-color: #444;
  clear: both;
  position: relative;
}
.catpage_def .logo_cate192 img {
  padding: 10px 16px 10px 0;
  display: inline-block;
  float: right;
}
.catpage_def .obi_new {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #d6000f;
  color: #fff;
  font-weight: bold;
  border-radius: 40px;
  line-height: 80px;
  text-align: center;
  font-size: 21px;
  top: -23px;
  left: -5px;
}
.catpage_def .cam_dec {
  display: block;
  background: #e54b4b;
  color: #fff;
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
}
.catpage_def .cam_dec .row {
  padding-bottom: 0 !important;
}
.catpage_def .cam_dec .camline {
  border-right: 2px solid #fff;
}
@media only screen and (max-width: 767px) {
  .catpage_def .cam_dec .camline {
    margin-bottom: 10px;
  }
}
.catpage_def .cam_dec .disp01 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 16px;
  line-height: 16px;
  width: 150px;
  margin: 0 auto;
}
.catpage_def .cam_dec .disp01::before, .catpage_def .cam_dec .disp01::after {
  border-top: 1px solid;
  content: "";
  display: inline; /* for IE */
  flex-grow: 1;
}
.catpage_def .cam_dec .disp01::before {
  margin-right: 1em;
}
.catpage_def .cam_dec .disp01::after {
  margin-left: 1em;
}
.catpage_def .cam_dec .disp02 {
  height: 44px;
  line-height: 44px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -moz-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  justify-content: center;
  position: relative;
}
.catpage_def .cam_dec .disp02 .font36 {
  font-size: 36px;
  font-weight: bold;
}
.catpage_def .cam_dec .disp02 .font23 {
  font-size: 23px;
  font-weight: bold;
}
.catpage_def .old_stay_update_desc {
  background-color: #f5f5f5;
  position: relative;
  padding: 27px;
  width: 392px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .old_stay_update_desc {
    width: 100%;
  }
}
.catpage_def .old_stay_update_desc .obi {
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  text-align: center;
}
.catpage_def .old_stay_update_desc .inner {
  text-align: center;
}
.catpage_def .old_stay_update_desc .inner a {
  background: #d70c19;
  margin-left: 27px;
  height: 42px;
  width: 194px;
  display: inline-block;
  vertical-align: middle;
  color: #fff;
  line-height: 42px;
  font-size: 12px;
  border-radius: 3px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .old_stay_update_desc .inner a img {
    margin-bottom: 20px;
  }
}
.catpage_def .old_stay_update_desc .inner a:hover {
  background-color: #e15d66;
}
.catpage_def .old_stay_update_desc .inner a i {
  margin-left: 11px;
  font-size: 18px;
  vertical-align: bottom;
}
.catpage_def h1 {
  font-size: 38px;
  text-align: center;
  font-weight: bold;
  margin: 60px 0 0;
}
@media only screen and (max-width: 767px) {
  .catpage_def h1 {
    font-size: 24px;
  }
}
.catpage_def .catpage_detail192 {
  padding: 0 65px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail192 {
    margin: 0 10px;
  }
}
.catpage_def .catpage_detail192 .main_txt {
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 80px;
}
.catpage_def .catpage_detail {
  margin: 0 20px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail {
    margin: 0;
  }
}
.catpage_def .catpage_detail .main_txt {
  font-size: 18px;
  line-height: 1.7em;
  margin-bottom: 80px;
}
.catpage_def .catpage_detail .sc_navi {
  margin: 0 -10px !important;
}
.catpage_def .catpage_detail .sc_navi ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.catpage_def .catpage_detail .sc_navi ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #f2f2f2;
  color: #444;
  margin: 0 10px 13px;
  width: 100%;
  font-size: 12px;
  position: relative;
  font-weight: bold;
}
.catpage_def .catpage_detail .sc_navi ul li a:after {
  position: absolute;
  content: "\f107";
  font-size: 21px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  color: #111;
  right: 16px;
  top: 10px;
}
.catpage_def .catpage_detail .sc_navi ul li a:hover {
  color: #35aae7;
}
.catpage_def .catpage_detail .lineup_navi {
  margin: 0 -10px !important;
  padding-bottom: 122px !important;
}
.catpage_def .catpage_detail .lineup_navi ul .col-sm-6 a {
  height: 120px;
}
.catpage_def .catpage_detail .lineup_navi ul li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}
.catpage_def .catpage_detail .lineup_navi ul li a {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #444;
  margin: 0 10px 30px;
  width: 100%;
  font-size: 14px;
  position: relative;
  font-weight: bold;
  line-height: 1.8em;
  text-align: center;
  box-shadow: 0 0 0 3px #f2f2f2 inset;
}
.catpage_def .catpage_detail .lineup_navi ul li a i {
  position: absolute;
  font-size: 21px;
  font-weight: 100;
  color: #111;
  right: 16px;
  top: 39px;
}
.catpage_def .catpage_detail .lineup_navi ul li a img {
  width: 230px;
  height: auto;
}
.catpage_def .catpage_detail .lineup_navi ul li a:hover {
  color: #35aae7;
  box-shadow: 0 0 0 5px #ddd inset;
}
.catpage_def .catpage_detail .lineup_navi ul li a .cam_obi {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 9px;
  background: #df3c3c;
  color: #fff;
  text-align: center;
  line-height: 14px;
  padding: 4px;
  display: block;
  z-index: 1;
}
.catpage_def .catpage_detail .lineup_navi ul li a .new_obi {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  font-size: 9px;
  background: #df3c3c;
  color: #fff;
  text-align: center;
  line-height: 33px;
  padding: 4px;
  display: block;
  z-index: 1;
}
.catpage_def .catpage_detail02 {
  margin-left: 30px;
}
.catpage_def .catpage_detail02 h3 {
  font-size: 20px;
  color: #444444;
}
.catpage_def .catpage_detail02 .s-icon {
  width: 12px;
  height: 14px;
  position: relative;
}
.catpage_def .catpage_detail02 .s-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
}
.catpage_def .catpage_detail02 .plus_mark {
  display: block;
  position: relative;
}
.catpage_def .catpage_detail02 .plus_mark::after {
  content: "";
  width: 68px;
  height: 68px;
  position: absolute;
  bottom: calc(50% - 34px);
  left: calc(50% - 34px);
  background-color: #444;
  border-radius: 68px;
  z-index: 1;
}
.catpage_def .catpage_detail02 .plus_mark::before {
  content: "\f067";
  font-family: "Font Awesome 6 Pro";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: calc(50% - 24px);
  left: calc(50% - 24px);
  border-radius: 48px;
  z-index: 2;
  text-align: center;
  line-height: 48px;
  font-size: 46px;
  color: #fff;
}
.catpage_def .catpage_detail02 .haku_navi_btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  height: 80px;
  background: #444;
  color: #f2f2f2;
  margin: 0 10px 13px;
  width: 420px;
  max-width: 100%;
  font-size: 24px;
  position: relative;
  margin: 0 auto;
}
.catpage_def .catpage_detail02 .haku_navi_btn::after {
  position: absolute;
  content: "\f107";
  font-size: 40px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  right: 16px;
  top: 10px;
  color: #777;
}
.catpage_def .catpage_detail02 .haku_navi_btn:hover {
  background-color: #818181;
}
.catpage_def .catpage_detail02 .box_line3 {
  display: block;
  border-bottom: 2px solid #dddddd;
  margin-bottom: 20px;
  padding-bottom: 15px;
}
.catpage_def .catpage_detail02 .procedure_table {
  vertical-align: top;
  margin-bottom: 40px;
  width: 100%;
}
.catpage_def .catpage_detail02 .procedure_table tbody {
  vertical-align: top;
}
.catpage_def .catpage_detail02 .procedure_table tr th {
  position: relative;
}
.catpage_def .catpage_detail02 .procedure_table tr th .num {
  width: 68px;
  height: 68px;
  background-color: #f2cc0c;
  border-radius: 68px;
  display: block;
  padding: 10px;
  margin: 0 15px;
  text-align: center;
  z-index: 1;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr th .num {
    margin-left: 0;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr th .num span {
  display: block;
  font-size: 36px;
  margin-top: -9px;
}
.catpage_def .catpage_detail02 .procedure_table tr th .num_line {
  width: 1px;
  background-color: #ddd;
  height: calc(100% + 50px);
  position: absolute;
  top: -50px;
  left: 49px;
  z-index: 0;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr th .num_line {
    left: 35px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .num_title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .num_title {
    font-size: 14px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc {
  padding: 15px 0 30px 50px;
  font-size: 14px;
  line-height: 1.8em;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc {
    padding: 15px 0 30px 10px;
    font-size: 12px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .fa-envelope {
  position: absolute;
  font-size: 22px;
  left: -28px;
  top: -10px;
  color: #444;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dt {
  position: relative;
  padding: 25px 40px;
  border-bottom: 1px solid #ddd;
  color: #444;
  cursor: pointer;
  font-weight: bold;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dt {
    padding: 25px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dt .question {
  position: absolute;
  left: 0;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dt i {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 25px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dt i {
    right: 8px;
    top: 40%;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p .active i::before {
  content: "\f106";
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dd {
  position: relative;
  display: none;
  padding: 25px 0 40px 40px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dd {
    padding: 25px 0 40px 25px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .qanda__p dd .answer {
  position: absolute;
  font-weight: bold;
  left: 0;
  color: #e54b4b;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .fa-fax {
  position: absolute;
  font-size: 24px;
  left: -5px;
  top: -2px;
  color: #444;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .fa-fax {
    left: -1px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc a {
  position: relative;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc a .fa-file-pdf {
  color: #ff5454;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box {
  border: 4px solid #ddd;
  padding: 25px !important;
  width: 285px;
  height: 172px !important;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box .obi01 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 100px solid #f2cc0c;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box .obi02 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 100px solid #f2880e;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box .obititle {
  color: #111;
  font-weight: bold;
  font-size: 14px;
  position: absolute;
  top: 0px;
  left: 0px;
  line-height: 32px;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box .obititle .font32 {
  font-size: 32px !important;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .dl_line_box .obititle .font15 {
  font-size: 15px !important;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .list {
  position: relative;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  padding: 0 30px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .list {
    margin-left: 0 !important;
    padding: 0 10px 0 30px;
    font-size: 12px;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .list .dlbtn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background: #4d4d4d;
  color: #fff;
  border-radius: 3px;
  padding: 0 15px 0 40px;
  margin: 0;
  position: relative;
  margin-top: 25px !important;
  overflow: hidden;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .list .dlbtn:hover {
  background-color: #818181;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .list .dlbtn::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 5s ease-in-out infinite;
}
@-webkit-keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .list .dlbtn i {
  font-size: 22px;
  position: absolute;
  left: 15px;
  top: 0;
}
.catpage_def .catpage_detail02 .procedure_table tr td .desc .border_r {
  border-right: 1px solid #ddd;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .procedure_table tr td .desc .border_r {
    border-right: none;
  }
}
.catpage_def .catpage_detail02 .procedure_text {
  margin-left: 15px;
  font-size: 14px;
  line-height: 1.8em;
  margin-bottom: 80px;
}
.catpage_def .catpage_detail02 .cam_procedure_title {
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  padding: 0 25px;
  background-color: #858585;
  color: #fff;
}
.catpage_def .catpage_detail02 .cam_procedure {
  padding: 40px 30px;
}
.catpage_def .catpage_detail02 .b_info {
  width: 100%;
}
.catpage_def .catpage_detail02 .b_info tbody {
  vertical-align: top;
}
.catpage_def .catpage_detail02 .b_info tbody tr:last-of-type td {
  padding-bottom: 0;
}
.catpage_def .catpage_detail02 .b_info tbody tr th {
  font-weight: normal;
  width: 160px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}
.catpage_def .catpage_detail02 .b_info tbody tr td {
  padding-bottom: 20px;
  padding-right: 20px;
}
.catpage_def .catpage_detail02 .b_info tbody tr td span {
  font-weight: bold;
}
.catpage_def .catpage_detail02 .b_info2 {
  width: 100%;
  margin-bottom: 60px;
}
.catpage_def .catpage_detail02 .b_info2 tbody {
  line-height: 3em;
}
.catpage_def .catpage_detail02 .b_info2 tbody tr th {
  font-weight: normal;
  width: 20%;
  text-align: center;
}
.catpage_def .catpage_detail02 .b_info2 tbody tr th span {
  font-size: 18px;
}
.catpage_def .catpage_detail02 .b_info2 tbody tr .border_r {
  border-right: 1px solid #ddd;
}
.catpage_def .catpage_detail02 .b_info3 {
  width: 100%;
}
.catpage_def .catpage_detail02 .b_info3 caption {
  text-align: left;
  padding: 0 20px;
  margin-bottom: 20px;
  caption-side: top;
}
.catpage_def .catpage_detail02 .b_info3 tbody {
  vertical-align: top;
}
.catpage_def .catpage_detail02 .b_info3 tbody tr:last-of-type td {
  padding-bottom: 0;
}
.catpage_def .catpage_detail02 .b_info3 tbody tr th {
  font-weight: normal;
  width: 160px;
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
}
.catpage_def .catpage_detail02 .b_info3 tbody tr td {
  padding-bottom: 20px;
  padding-right: 20px;
}
.catpage_def .catpage_detail02 .b_info3 tbody tr td span {
  font-weight: bold;
}
.catpage_def .catpage_detail02 .newlets_info {
  width: 100%;
  font-size: 20px;
  margin-bottom: 0;
}
.catpage_def .catpage_detail02 .newlets_info tbody {
  vertical-align: top;
}
.catpage_def .catpage_detail02 .newlets_info tbody tr:last-of-type td {
  padding-bottom: 0;
}
.catpage_def .catpage_detail02 .newlets_info tbody tr th {
  font-weight: bold;
  text-align: left;
  padding: 25px 3px 0 40px;
  vertical-align: middle;
}
.catpage_def .catpage_detail02 .newlets_info tbody tr td {
  padding: 25px 40px 0 0;
  vertical-align: middle;
}
.catpage_def .catpage_detail02 .newlets_info tbody tr td span {
  font-weight: bold;
  font-size: 30px;
}
.catpage_def .catpage_detail02 .f_more {
  display: none;
}
.catpage_def .catpage_detail02 .f_more__btn {
  display: block;
  text-align: center;
  height: 45px;
  line-height: 45px;
  border: 1px solid #dddddd;
  cursor: pointer;
  margin-top: 20px;
  color: #35aae7;
}
.catpage_def .catpage_detail02 .f_type {
  display: inline-block;
  background: #f2f2f2;
  padding: 3px 10px;
  color: #666;
}
.catpage_def .catpage_detail02 .f_type__std {
  border-left: 5px solid #fff700;
}
.catpage_def .catpage_detail02 .f_type__pro {
  border-left: 5px solid #ff4d00;
}
.catpage_def .catpage_detail02 .f_type__w1g {
  border-left: 5px solid #ff00c8;
}
.catpage_def .catpage_detail02 .f_type__w2g {
  border-left: 5px solid #a900ff;
}
.catpage_def .catpage_detail02 .f_type__com {
  border-left: 5px solid #00b2ff;
}
.catpage_def .catpage_detail02 .qanda dt {
  position: relative;
  padding: 25px 60px;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  color: #444;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .qanda dt {
    padding: 25px 45px;
  }
}
.catpage_def .catpage_detail02 .qanda dt .question {
  position: absolute;
  left: 0;
}
.catpage_def .catpage_detail02 .qanda dt i {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 40%;
}
.catpage_def .catpage_detail02 .qanda .active i::before {
  content: "\f106";
}
.catpage_def .catpage_detail02 .qanda dd {
  position: relative;
  display: none;
  padding: 25px 0 60px 60px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .qanda dd {
    padding: 25px 0 60px 45px;
  }
}
.catpage_def .catpage_detail02 .qanda dd .answer {
  position: absolute;
  left: 0;
  font-size: 20px;
  font-weight: bold;
  color: #e54b4b;
}
.catpage_def .catpage_detail02 .qanda dd table {
  border-collapse: collapse;
  margin: 0 auto;
  padding: 0;
  width: 650px;
  table-layout: fixed;
}
.catpage_def .catpage_detail02 .qanda dd table tr {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0.35em;
}
.catpage_def .catpage_detail02 .qanda dd table th,
.catpage_def .catpage_detail02 .qanda dd table td {
  padding: 1em 10px 1em 1em;
  border-right: 1px solid #ddd;
}
.catpage_def .catpage_detail02 .qanda dd table th {
  font-size: 0.85em;
}
.catpage_def .catpage_detail02 .qanda dd table thead tr {
  background-color: #eee;
}
.catpage_def .catpage_detail02 .qanda dd .txt {
  text-align: left;
  font-size: 0.85em;
}
.catpage_def .catpage_detail02 .qanda dd .price {
  text-align: right;
}
@media screen and (max-width: 600px) {
  .catpage_def .catpage_detail02 .qanda dd table {
    border: 0;
    width: 100%;
  }
  .catpage_def .catpage_detail02 .qanda dd table th {
    background-color: #eee;
    display: block;
    border-right: none;
  }
  .catpage_def .catpage_detail02 .qanda dd table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .catpage_def .catpage_detail02 .qanda dd table tr {
    display: block;
    margin-bottom: 0.625em;
  }
  .catpage_def .catpage_detail02 .qanda dd table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    position: relative;
    padding: 0.625em 0.625em 0.625em 4em;
    border-right: none;
  }
  .catpage_def .catpage_detail02 .qanda dd table td::before {
    content: attr(data-label);
    font-weight: bold;
    position: absolute;
    left: 10px;
  }
  .catpage_def .catpage_detail02 .qanda dd table td:last-child {
    border-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 {
    margin-left: 0;
  }
}
.catpage_def .catpage_detail02 .r_30 {
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .r_30 {
    margin-left: 0;
  }
}
.catpage_def .catpage_detail02 .main_txt {
  font-size: 14px;
  line-height: 1.7em;
  margin-bottom: 100px;
}
.catpage_def .catpage_detail02 .main_txt .font_list {
  margin-top: 50px;
  font-weight: bold;
  padding-bottom: 0 !important;
  padding-left: 30px;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .main_txt .font_list div {
    margin-bottom: 20px;
  }
}
.catpage_def .catpage_detail02 .main_txt .font_list a {
  position: relative;
}
.catpage_def .catpage_detail02 .main_txt .font_list a i {
  color: #ec2606;
  position: absolute;
  font-size: 24px;
  left: -28px;
  top: -8px;
}
.catpage_def .catpage_detail02 .main_txt .font_list .dlbtn {
  display: inline-block;
  height: 40px;
  line-height: 40px;
  background: #4d4d4d;
  color: #fff;
  border-radius: 3px;
  padding: 0 15px 0 40px;
  margin: 0;
  position: relative;
  margin-top: 0 !important;
  margin-left: -40px;
  overflow: hidden;
}
.catpage_def .catpage_detail02 .main_txt .font_list .dlbtn:hover {
  background-color: #818181;
}
.catpage_def .catpage_detail02 .main_txt .font_list .dlbtn::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: shiny-btn1 5s ease-in-out infinite;
}
@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
.catpage_def .catpage_detail02 .main_txt .font_list .dlbtn i {
  font-size: 22px;
  position: absolute;
  left: 15px;
}
.catpage_def .catpage_detail02 .main_txt .renew {
  display: block;
  margin: 60px auto 0;
}
.catpage_def .catpage_detail02 .osusume_text {
  margin-bottom: 100px;
}
.catpage_def .catpage_detail02 .osusume_text ._txt {
  background-color: #f2cc0c;
  border-radius: 240px;
  width: 240px;
  height: 240px;
  margin-right: 40px;
  margin-bottom: 30px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .osusume_text ._txt {
    margin: 0 auto 30px;
  }
}
.catpage_def .catpage_detail02 .osusume_text ._txt h3 {
  font-size: 18px;
  text-align: center;
  margin-top: 62px;
  margin-bottom: 0;
  color: #111111;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .osusume_text ._txt h3 {
    margin-top: 0;
    padding-top: 57px;
    margin-bottom: 0;
  }
}
.catpage_def .catpage_detail02 .osusume_text ._txt div {
  padding: 25px;
}
.catpage_def .catpage_detail02 .fee_text {
  margin-bottom: 150px;
}
.catpage_def .catpage_detail02 .fee_text2 {
  margin-bottom: 150px;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
}
.catpage_def .catpage_detail02 .fee_text2 .t_p {
  font-size: 18px;
  color: #444444;
  margin-top: 0;
}
.catpage_def .catpage_detail02 .fee_text2 .color_mojipass01 {
  color: #a72227 !important;
}
.catpage_def .catpage_detail02 .fee_text2 .color_mojipass02 {
  color: #cf6385 !important;
}
.catpage_def .catpage_detail02 .fee_text2 .color_mojipass03 {
  color: #012167 !important;
}
.catpage_def .catpage_detail02 .fee_text2 .color_license_def {
  color: #f2cc0c !important;
}
.catpage_def .catpage_detail02 .fee_text2 .font26 {
  font-size: 26px;
}
.catpage_def .catpage_detail02 .fee_text2 .title-border {
  display: flex;
  align-items: center;
}
.catpage_def .catpage_detail02 .fee_text2 .title-border:before,
.catpage_def .catpage_detail02 .fee_text2 .title-border:after {
  border-top: 2px solid;
  content: "";
  width: 20px;
  margin: 0 auto;
}
.catpage_def .catpage_detail02 .fee_text2 .title-border:before {
  margin-right: 1rem;
}
.catpage_def .catpage_detail02 .fee_text2 .title-border:after {
  margin-left: 1rem;
}
.catpage_def .catpage_detail02 .fee_text2 .title-border {
  display: flex;
  align-items: center;
}
.catpage_def .catpage_detail02 .pricebox_showalets {
  display: block;
  margin: 0 auto;
  border: 4px solid #ddd;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .pricebox_showalets {
    width: 100%;
    max-width: 360px;
    margin-bottom: 20px;
  }
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  height: 50%;
  border-bottom: 1px solid #ddd;
  padding: 19px 20px 17px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top::after {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #ddd;
  bottom: -12px;
  z-index: 1;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top::before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  bottom: -10px;
  z-index: 2;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top .obi01 {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #e54b4b;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top .obititle {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: 5px;
  left: 4px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top .obititle .font28 {
  font-size: 28px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_top .obititle .font14 {
  font-size: 14px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .pricebox_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  position: relative;
  padding: 20px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price_title {
  display: block;
  width: 83px;
  height: 38px;
  text-align: center;
  color: #111;
  font-size: 22px;
  font-weight: bold;
  line-height: 38px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price {
  display: block;
  font-size: 14px;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price span {
  display: inline;
  font-size: 34px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price_desc {
  font-size: 12px;
  line-height: 1.8em;
  text-align: center;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price_desc__red {
  color: #e54b4b;
}
.catpage_def .catpage_detail02 .pricebox_showalets .price_desc__red__bold {
  color: #e54b4b;
  font-weight: bold;
  font-size: 14px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .navi_btn {
  display: block;
  width: 240px;
  height: 40px;
  background: #4d4d4d;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.catpage_def .catpage_detail02 .pricebox_showalets .navi_btn:hover {
  background-color: #818181;
}
.catpage_def .catpage_detail02 .pricebox_showalets .navi_btn i {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  font-size: 24px;
  color: #777;
}
.catpage_def .catpage_detail02 .pricebox_showalets p {
  width: 210px;
  display: block;
}
.catpage_def .catpage_detail02 .pricebox {
  display: block;
  width: 340px;
  height: 440px;
  margin: 0 auto;
  border: 4px solid #ddd;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .pricebox {
    width: 100%;
    max-width: 340px;
    margin-bottom: 20px;
  }
}
.catpage_def .catpage_detail02 .pricebox::after {
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: calc(50% - 24px);
  left: calc(50% - 24px);
  background-color: #ddd;
  border-radius: 48px;
  z-index: 1;
}
.catpage_def .catpage_detail02 .pricebox::before {
  content: "\f067";
  font-family: "Font Awesome 6 Pro";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: calc(50% - 24px);
  left: calc(50% - 24px);
  border-radius: 48px;
  z-index: 2;
  text-align: center;
  line-height: 48px;
  font-size: 33px;
  color: #818181;
}
.catpage_def .catpage_detail02 .pricebox .cam_price_title {
  display: inline-block;
  background-color: #e54b4b;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 15px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  height: 50%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 48px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top .obi01 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 111px solid #f2cc0c;
  border-top: 0 solid transparent;
  border-bottom: 135px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top .obi03 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 111px solid #f2880c;
  border-top: 0 solid transparent;
  border-bottom: 135px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top .obititle {
  color: #111;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: -5px;
  left: 7px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top .obititle .font40 {
  font-size: 40px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_top .obititle .font22 {
  font-size: 22px;
}
.catpage_def .catpage_detail02 .pricebox .pricebox_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  position: relative;
  padding-top: 48px;
}
.catpage_def .catpage_detail02 .pricebox .price_title {
  display: block;
  background-color: #818181;
  width: 83px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .pricebox .price {
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .pricebox .price span {
  display: inline;
  font-size: 40px;
}
.catpage_def .catpage_detail02 .pricebox .price_desc {
  font-size: 14px;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .pricebox .price_desc__red {
  color: #e54b4b;
}
.catpage_def .catpage_detail02 .pricebox p {
  width: 210px;
  display: block;
}
.catpage_def .catpage_detail02 .pricebox2_head {
  height: 50px;
  width: 340px;
  background-color: #666;
  color: #fff;
  font-size: 18px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.catpage_def .catpage_detail02 .pricebox2 {
  display: block;
  width: 340px;
  height: 300px;
  margin: 0 auto 50px;
  border: 4px solid #ddd;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .pricebox2 {
    width: 100%;
    max-width: 340px;
    margin-bottom: 20px;
  }
}
.catpage_def .catpage_detail02 .pricebox2__dammy {
  display: block;
  width: 340px;
  height: 300px;
  margin: 0 auto 50px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .pricebox2__dammy {
    width: 0;
    height: 0;
    margin: 0;
  }
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 100%;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_mojipass {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #cf6385;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_mojipassb {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #012167;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_nis01 {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #f2cc0c;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_nis02 {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #818181;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_dynav {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 125px solid #ec6d1e;
  border-top: 0 solid transparent;
  border-bottom: 125px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_dynat {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 125px solid #01a0eb;
  border-top: 0 solid transparent;
  border-bottom: 125px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi_dynas {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 125px solid #86529d;
  border-top: 0 solid transparent;
  border-bottom: 125px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi01 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 111px solid #f2cc0c;
  border-top: 0 solid transparent;
  border-bottom: 135px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obi03 {
  color: #111;
  width: 0;
  height: 0;
  border-left: 111px solid #f2880c;
  border-top: 0 solid transparent;
  border-bottom: 135px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: -5px;
  left: 7px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle .font40 {
  font-size: 40px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle .font22 {
  font-size: 22px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle .font20 {
  font-size: 20px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle .font26 {
  font-size: 26px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle2 {
  color: #111;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: -5px;
  left: 7px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle2 .font40 {
  font-size: 40px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle2 .font22 {
  font-size: 22px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle2 .font20 {
  font-size: 20px;
}
.catpage_def .catpage_detail02 .pricebox2 .pricebox_inner .obititle2 .font26 {
  font-size: 26px;
}
.catpage_def .catpage_detail02 .pricebox2 .price_title {
  display: inline-block;
  background-color: #818181;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 15px;
}
.catpage_def .catpage_detail02 .pricebox2 .cam_price_title {
  display: inline-block;
  background-color: #e54b4b;
  height: 38px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  padding: 0 15px;
}
.catpage_def .catpage_detail02 .pricebox2 .price {
  display: block;
  font-size: 20px;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .pricebox2 .price span {
  display: inline;
  font-size: 40px;
}
.catpage_def .catpage_detail02 .pricebox2 .camlb {
  background-color: #e54b4b;
  color: #fff;
  padding: 0.5em;
  margin-bottom: 0.5em;
}
.catpage_def .catpage_detail02 .pricebox2 .price_desc {
  font-size: 14px;
  font-weight: bold;
  color: #a5a5a5;
}
.catpage_def .catpage_detail02 .pricebox2 .price_desc__red {
  color: #e54b4b;
  position: relative;
}
.catpage_def .catpage_detail02 .pricebox2 .price_desc__red .tagnew {
  font-size: 12px;
  position: absolute;
  background: #f2cc0c;
  color: #ffffff;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  line-height: 36px;
  top: 0;
  left: -36px;
  display: inline-block;
  text-align: center;
}
.catpage_def .catpage_detail02 .pricebox2 .price_desc__red .tagupdate {
  font-size: 12px;
  position: absolute;
  background: #818181;
  color: #ffffff;
  border-radius: 30px;
  width: 35px;
  height: 35px;
  line-height: 36px;
  top: 0;
  left: -36px;
  display: inline-block;
  text-align: center;
}
.catpage_def .catpage_detail02 .pricebox2 .navi_btn {
  display: block;
  width: 180px;
  height: 50px;
  background: #4d4d4d;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 50px;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.catpage_def .catpage_detail02 .pricebox2 .navi_btn:hover {
  background-color: #818181;
}
.catpage_def .catpage_detail02 .pricebox2 .navi_btn i {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  font-size: 24px;
  color: #777;
}
.catpage_def .catpage_detail02 .pricebox2 p {
  width: 210px;
  display: block;
}
.catpage_def .catpage_detail02 .cate105 {
  margin: 0 auto 130px !important;
  display: block;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .table_outer {
    overflow: scroll;
  }
}
.catpage_def .catpage_detail02 .hikaku,
.catpage_def .catpage_detail02 .hikaku2 {
  width: 830px;
  font-size: 14px;
  line-height: 2em;
  font-weight: bold;
}
.catpage_def .catpage_detail02 .hikaku .menu01,
.catpage_def .catpage_detail02 .hikaku2 .menu01 {
  background-color: #999;
  vertical-align: bottom;
  color: #fff;
  height: 65px;
}
.catpage_def .catpage_detail02 .hikaku .menu01 td,
.catpage_def .catpage_detail02 .hikaku2 .menu01 td {
  padding: 15px 10px;
  border: 1px solid #ddd;
  border-top: none;
}
.catpage_def .catpage_detail02 .hikaku .menu01 td span,
.catpage_def .catpage_detail02 .hikaku2 .menu01 td span {
  font-weight: normal;
}
.catpage_def .catpage_detail02 .hikaku .menu02,
.catpage_def .catpage_detail02 .hikaku2 .menu02 {
  background-color: #999;
  vertical-align: top;
  color: #fff;
  height: 43px;
}
.catpage_def .catpage_detail02 .hikaku .menu02 td,
.catpage_def .catpage_detail02 .hikaku2 .menu02 td {
  padding: 0px 10px;
  border: 1px solid #ddd;
  border-top: none;
}
.catpage_def .catpage_detail02 .hikaku .menu03,
.catpage_def .catpage_detail02 .hikaku2 .menu03 {
  background-color: #999;
  vertical-align: middle;
  color: #fff;
  height: 43px;
}
.catpage_def .catpage_detail02 .hikaku .menu03 th,
.catpage_def .catpage_detail02 .hikaku2 .menu03 th {
  border-right: 1px solid #ddd;
}
.catpage_def .catpage_detail02 .hikaku .menu03 td,
.catpage_def .catpage_detail02 .hikaku2 .menu03 td {
  border-right: 1px solid #ddd;
}
.catpage_def .catpage_detail02 .hikaku .menu03 td:last-of-type,
.catpage_def .catpage_detail02 .hikaku2 .menu03 td:last-of-type {
  border-right: none;
}
.catpage_def .catpage_detail02 .hikaku .list,
.catpage_def .catpage_detail02 .hikaku2 .list {
  color: #444;
  font-weight: normal;
}
.catpage_def .catpage_detail02 .hikaku .list:nth-of-type(even),
.catpage_def .catpage_detail02 .hikaku2 .list:nth-of-type(even) {
  background: #f2f2f2;
}
.catpage_def .catpage_detail02 .hikaku .list span,
.catpage_def .catpage_detail02 .hikaku2 .list span {
  font-size: 12px;
}
.catpage_def .catpage_detail02 .hikaku .list th,
.catpage_def .catpage_detail02 .hikaku2 .list th {
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff !important;
  vertical-align: top;
  padding: 28px 25px 25px;
  font-weight: normal;
  text-align: left;
  background: #fff;
}
.catpage_def .catpage_detail02 .hikaku .list th a,
.catpage_def .catpage_detail02 .hikaku2 .list th a {
  display: block;
}
.catpage_def .catpage_detail02 .hikaku .list th a img,
.catpage_def .catpage_detail02 .hikaku2 .list th a img {
  vertical-align: top;
}
.catpage_def .catpage_detail02 .hikaku .list th a:hover,
.catpage_def .catpage_detail02 .hikaku2 .list th a:hover {
  opacity: 0.777;
}
.catpage_def .catpage_detail02 .hikaku .list td,
.catpage_def .catpage_detail02 .hikaku2 .list td {
  padding: 20px 10px;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  vertical-align: top;
}
.catpage_def .catpage_detail02 .hikaku .list td:last-of-type,
.catpage_def .catpage_detail02 .hikaku2 .list td:last-of-type {
  border-right: none;
}
.catpage_def .catpage_detail02 .hikaku2 .list td {
  vertical-align: middle !important;
}
.catpage_def .catpage_detail02 .hikaku_note {
  margin: 30px 0 130px;
  color: #444;
  line-height: 2em;
}
.catpage_def .catpage_detail02 .color_dynav {
  background-color: rgb(236, 109, 30);
}
.catpage_def .catpage_detail02 .color_dynat {
  background-color: rgb(1, 160, 235);
}
.catpage_def .catpage_detail02 .color_dynas {
  background-color: rgb(134, 82, 157);
}
.catpage_def .catpage_detail02 .dyna_h3 {
  font-size: 18px;
  color: #fff;
  padding: 6px 1em;
  border-radius: 6px;
}
.catpage_def .catpage_detail02 .dyna_pbr {
  border-right: 4px solid #ddd;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .dyna_pbr {
    border-right: none;
  }
}
.catpage_def .catpage_detail02 .dyna_pbb {
  border-bottom: 4px solid #ddd;
}
@media only screen and (max-width: 767px) {
  .catpage_def .catpage_detail02 .dyna_pbb {
    border-bottom: none;
  }
}
@media only screen and (max-width: 767px) {
  .catpage_def h2 {
    font-size: 24px;
    text-align: left;
    font-weight: bold;
    margin: 50px 0 0;
  }
  .catpage_def .catpage_detail .main_txt {
    font-size: 18px;
    line-height: 1.7em;
    margin-bottom: 50px;
  }
  .catpage_def .catpage_detail .sc_navi {
    margin: 0 8px !important;
  }
  .catpage_def .catpage_detail .sc_navi ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }
  .catpage_def .catpage_detail .sc_navi ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    background: #f2f2f2;
    color: #444;
    margin: 0 0 14px;
    width: 100%;
    font-size: 14px;
    position: relative;
    height: 70px;
  }
  .catpage_def .catpage_detail .sc_navi ul li a:after {
    position: absolute;
    content: "\f107";
    font-size: 21px;
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
    color: #111;
    right: 16px;
    top: 22px;
  }
  .catpage_def .catpage_detail .sc_navi ul li a:hover {
    color: #35aae7;
  }
  .catpage_def .catpage_detail .lineup_navi {
    margin: 0 8px !important;
    padding-bottom: 122px !important;
  }
  .catpage_def .catpage_detail .lineup_navi ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    height: 120px;
    color: #444;
    margin: 0 0 28px;
    width: 100%;
    font-size: 14px;
    position: relative;
    font-weight: bold;
    line-height: 1.8em;
    box-shadow: 0 0 0 3px #f2f2f2 inset;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a:after {
    display: block;
    position: absolute;
    content: "\f105";
    font-size: 21px;
    font-family: "Font Awesome 6 Pro";
    font-weight: 100;
    color: #111;
    right: 16px;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a i {
    display: none;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a img {
    width: 230px;
    height: auto;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a:hover {
    color: #35aae7;
    box-shadow: 0 0 0 5px #ddd inset;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a .cam_obi {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 9px;
    background: #df3c3c;
    color: #fff;
    text-align: center;
    line-height: 14px;
    padding: 6px 4px;
    display: block;
    z-index: 1;
  }
  .catpage_def .catpage_detail .lineup_navi ul li a .p_obi {
    position: absolute;
    top: -10px;
    left: -10px;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    font-size: 9px;
    background: #ffcc00;
    color: #444;
    text-align: center;
    line-height: 32px;
    padding: 4px;
    display: block;
    z-index: 1;
  }
}

.open_cambackground {
  cursor: pointer;
}

.cambackground {
  background-color: rgba(68, 68, 68, 0.76);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: scroll;
  padding: 60px;
  overflow-x: hidden;
  transform: translateZ(1px);
}
@media only screen and (max-width: 767px) {
  .cambackground {
    padding: 30px;
  }
}
.cambackground .cammain {
  width: 910px;
  max-width: 100%;
  background-color: #308fcc;
  position: relative;
}
.cambackground .cammain .price_box {
  max-width: 740px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding-top: 25px;
  background-color: #fff;
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .cambackground .cammain .price_box {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}
.cambackground .cammain .letscam {
  padding: 40px;
}
@media only screen and (max-width: 767px) {
  .cambackground .cammain .letscam {
    padding: 20px;
  }
}
.cambackground .cammain .camclose {
  display: block;
  width: 60px;
  height: 60px;
  position: absolute;
  top: -30px;
  right: -30px;
  z-index: 20000;
  background: #444;
  border-radius: 60px;
  cursor: pointer;
  text-align: center;
  line-height: 64px;
  color: #fff;
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .cambackground .cammain .camclose {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    top: -15px;
    right: -15px;
    line-height: 32px;
    font-size: 12px;
  }
}

.haku_navi_btn2 {
  display: block;
  height: 58px;
  background: #444;
  color: #f2f2f2;
  margin: 0 10px 13px;
  width: 252px;
  max-width: 100%;
  font-size: 18px;
  position: relative;
  margin: 0 auto;
  line-height: 58px;
}
.haku_navi_btn2::after {
  position: absolute;
  content: "\f105";
  font-size: 24px;
  font-family: "Font Awesome 6 Pro";
  font-weight: 100;
  right: 16px;
  top: -1px;
  color: #777;
}
.haku_navi_btn2:hover {
  background-color: #818181;
  color: #f2f2f2;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.morifonts 　.tbl-m01 {
  border-collapse: separate;
  border-spacing: 5px;
  font-size: 14px;
  text-align: center;
}
.morifonts .tbl-m01 {
  border-spacing: 2px;
  width: 100%;
}
.morifonts .tbl-m01 th {
  border: none;
  background-color: #828c97;
  color: #fff;
  padding: 14px;
  text-align: center;
}
.morifonts .tbl-m01 td {
  background: #eff0f4;
  color: #444;
  border: none;
  padding: 14px;
  text-align: center;
}
.morifonts .tbl-m01 td span {
  color: #818181;
  font-size: 10px;
  display: inline-block;
}
@media only screen and (max-width: 767px) {
  .morifonts .tbl-m01 th {
    width: 20%;
  }
  .morifonts .tbl-m01 td {
    width: 25%;
  }
}

.morifonts_price {
  display: block;
}
.morifonts_price .right30 {
  margin-right: 30px;
}
@media only screen and (max-width: 767px) {
  .morifonts_price .right30 {
    margin-right: unset;
  }
}
.morifonts_price .tbl-m03 {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  border-spacing: 2px;
  width: calc(100% - 80px);
  margin: 0 40px;
}
.morifonts_price .tbl-m03 th {
  border: none;
  background-color: #828c97;
  color: #fff;
  padding: 14px;
  text-align: center;
}
.morifonts_price .tbl-m03 td {
  background: #eff0f4;
  color: #444;
  border: none;
  padding: 14px;
  text-align: center;
  font-weight: bold;
}
.morifonts_price .tbl-m03 td .price_batu {
  color: #818181;
  font-weight: unset;
  display: inline-block;
  text-decoration: line-through;
  text-decoration-color: #4d4d4d;
}
.morifonts_price .camcontent {
  border: 1px solid #2876c8;
  font-size: 14px;
}
.morifonts_price .camcontent .c_red {
  color: #e54b4b;
}
.morifonts_price .camcontent .con {
  padding: 55px;
}
@media only screen and (max-width: 767px) {
  .morifonts_price .camcontent .con {
    padding: 2em;
  }
}
.morifonts_price .camcontent .con .title_table01 {
  font-size: 20px;
  font-weight: bold;
}
.morifonts_price .camcontent .con .title_table01 th {
  width: 56px;
  height: 56px;
  background-color: #2876c8;
  border-radius: 56px;
  color: #fff;
  text-align: center;
}
.morifonts_price .camcontent .con .title_table01 td {
  color: #2876c8;
}
.morifonts_price .camcontent .con .title_table02 {
  font-size: 20px;
  font-weight: bold;
}
.morifonts_price .camcontent .con .title_table02 th .cer {
  width: 56px;
  height: 56px;
  background-color: #fff;
  border-radius: 56px;
  color: #2876c8;
  text-align: center;
  border: 2px solid #2876c8;
  line-height: 52px;
}
.morifonts_price .camcontent .con .title_table02 td {
  color: #2876c8;
}
.morifonts_price .camcontent .con .dec {
  font-size: 18px;
  margin: 1em 0;
}
.morifonts_price .camcontent .con .tbl-m02 {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 5px;
  font-size: 14px;
  text-align: center;
  border-spacing: 2px;
  width: 100%;
}
.morifonts_price .camcontent .con .tbl-m02 th {
  border: none;
  background-color: #828c97;
  color: #fff;
  padding: 14px;
  text-align: center;
}
.morifonts_price .camcontent .con .tbl-m02 td {
  background: #eff0f4;
  color: #444;
  border: none;
  font-size: 18px;
  padding: 14px;
  text-align: center;
  font-weight: bold;
}
.morifonts_price .camcontent .con .tbl-m02 td .price_batu {
  color: #818181;
  font-size: 14px;
  font-weight: unset;
  display: inline-block;
  text-decoration: line-through;
  text-decoration-color: #4d4d4d;
}
.morifonts_price .camcontent .con .link_dec {
  min-height: 228px;
  background-color: #eff0f4;
}
.morifonts_price .camcontent .con .link_dec h3 {
  font-size: 24px;
  color: #3271b9;
  text-align: center;
  font-weight: bold;
}
.morifonts_price .camcontent .con .link_dec p {
  font-size: 16px;
  max-width: 500px;
}
.morifonts_price .camcontent .con .link_dec a:hover {
  opacity: 0.8;
}
.morifonts_price .camcontent .con .link_dec .linkbtn01 {
  display: block;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  background-color: #2876c8;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
}
.morifonts_price .camcontent .con .link_dec .linkbtn02 {
  display: block;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  background-color: #fff;
  color: #2876c8;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 58px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #2876c8;
}

.morifonts_n01 {
  padding: 35px;
  background-color: #eff0f4;
  background-color: #fff7f7;
  margin: 0 10px;
  color: #e54b4b;
  font-size: 16px;
}
.morifonts_n01 a:hover {
  opacity: 0.8;
}
@media (min-width: 768px) {
  .morifonts_n01 a[href^="tel:"] {
    pointer-events: none;
  }
}
.morifonts_n01 p {
  line-height: 1.7em;
}
.morifonts_n01 hr {
  margin: 30px 0;
  border-top: 1px solid #e54b4b;
}
.morifonts_n01 .linkbtn02 {
  display: block;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  background-color: #e54b4b;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
}

.morifonts_n02 {
  padding: 35px;
  background-color: #2876c8;
  margin: 0 10px;
  color: #fff;
  font-size: 16px;
}
.morifonts_n02 a:hover {
  opacity: 0.8;
}
.morifonts_n02 p {
  line-height: 1.7em;
}
.morifonts_n02 hr {
  margin: 30px 0;
  border-top: 1px solid #e54b4b;
}
.morifonts_n02 .linkbtn02 {
  display: block;
  width: 250px;
  height: 60px;
  border-radius: 30px;
  background-color: #ffe43e;
  color: #2876c8;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 60px;
  margin-left: auto;
  margin-right: auto;
}

.morih3sub {
  color: #3271b9;
  font-size: 14px;
}

.moridesch3 {
  font-size: 18px;
  color: #3271b9;
  font-weight: bold;
}

.imover:hover {
  opacity: 0.8;
}

.fcolor_red {
  color: #e54b4b;
}

.cambanatop {
  display: block;
  height: 78px;
  background: #ffe303;
  color: #2876c8;
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  line-height: 78px;
  position: relative;
}
.cambanatop::after {
  position: absolute;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 30px solid #ffe43e;
  opacity: 1;
  content: "";
  bottom: -30px;
  left: calc(50% - 15px);
}

.morisawa-dec {
  margin: 0;
  background-color: color(srgb 0.1826 0.5591 0.798);
  min-height: 408px;
  color: #fff;
}
.morisawa-dec .inner {
  max-width: 593px;
  margin: 30px;
}
.morisawa-dec .inner h2 {
  font-size: 2.5rem;
  text-align: center;
}
.morisawa-dec .inner p {
  font-size: 18px;
  line-height: 1.75em;
  font-weight: 100;
}
.morisawa-dec .inner a {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: #fff;
}

/* --------------------------------
会員登録
-------------------------------- */
.agree_space {
  background-color: #f4f4f4;
  padding: 10px;
  display: block;
  width: 100%;
  text-align: center;
}
.agree_space iframe {
  background-color: #fff;
}
.agree_space .agree_block {
  display: inline-block;
  text-align: center;
  width: 280px;
  padding: 5px;
  background: #fff;
}
.agree_space .agree_block label {
  line-height: 28px;
  margin-bottom: 0;
}
.agree_space .agree_block .ec-errorMessage {
  margin-bottom: 5px;
}
.agree_space .member_agree_text {
  display: block;
  background-color: #fff;
  width: 100%;
  height: 250px;
  text-align: left;
  overflow: auto;
  font-size: 13px;
  padding: 8px;
  margin-bottom: 10px;
}

.youtube {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.youtube iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

/***********************************************
モバイルのブレイクポイント
************************************************ */
/*
共通コンポーネント

Styleguide 11.0
*/
/*
トップページ

Styleguide 12.0
*/
/*
商品一覧

Styleguide 13.0
*/
.product_page .h_maker_link {
  background-color: #eff2f0;
  padding: 10px;
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .product_page .h_maker_link {
    margin-bottom: 20px;
  }
}
.product_page .h_maker_link a {
  color: #35aae7;
  font-size: 14px;
  display: table;
  min-width: 100%;
}
.product_page .h_maker_link a .to_maker_link {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.product_page .h_maker_link a .angle_maker_link {
  display: table-cell;
  vertical-align: middle;
  margin-left: 8px;
  font-size: 20px;
}
.product_page .h_maker_link a:hover, .product_page .h_maker_link a:focus {
  color: #0099e8;
  text-decoration: underline;
}

/*
商品詳細

Styleguide 14.0
*/
/*
注文

Styleguide 15.0
*/
/*
注文履歴

Styleguide 16.0
*/
/*
住所一覧

Styleguide 17.0
*/
/*
パスワード再発行

Styleguide 18.0
*/
/*
フォーム

Styleguide 19.0
*/
/*
404ページ

Styleguide 20.0
*/
/*
退会手続き

Styleguide 21.0
*/
/*
会員情報編集完了

Styleguide 22.0
*/
.pricebox_jfont {
  display: block;
  margin: 0 auto;
  border: 1px solid #ddd;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .pricebox_jfont {
    width: 100%;
    max-width: 360px;
    margin-bottom: 20px;
  }
}
.pricebox_jfont .pricebox_top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  position: relative;
}
.pricebox_jfont .pricebox_top .obi01 {
  color: #fff;
  width: 0;
  height: 0;
  border-left: 100px solid #e54b4b;
  border-top: 0 solid transparent;
  border-bottom: 100px solid transparent;
  position: absolute;
  left: -4px;
  top: -4px;
}
.pricebox_jfont .pricebox_top .obititle {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
  position: absolute;
  top: 5px;
  left: 4px;
}
.pricebox_jfont .pricebox_top .obititle .font28 {
  font-size: 28px;
}
.pricebox_jfont .pricebox_top .obititle .font14 {
  font-size: 14px;
}
.pricebox_jfont .pricebox_bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 50%;
  position: relative;
  padding: 30px;
}
.pricebox_jfont .price_title {
  display: block;
  width: 83px;
  height: 38px;
  text-align: center;
  color: #111;
  font-size: 22px;
  font-weight: bold;
  line-height: 38px;
}
.pricebox_jfont .price {
  display: block;
  font-size: 18px;
  font-weight: normal;
  text-align: center;
  line-height: 1.7;
}
.pricebox_jfont .price span {
  display: inline;
  font-size: 34px;
}
.pricebox_jfont .price_desc {
  font-size: 12px;
  line-height: 1.8em;
  text-align: center;
}
.pricebox_jfont .price_desc__red {
  color: #e54b4b;
}
.pricebox_jfont .price_desc__red__bold {
  color: #e54b4b;
  font-weight: bold;
  font-size: 14px;
}
.pricebox_jfont .navi_btn {
  display: block;
  width: 240px;
  height: 40px;
  background: #4d4d4d;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.pricebox_jfont .navi_btn:hover {
  background-color: #818181;
}
.pricebox_jfont .navi_btn i {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  font-size: 24px;
  color: #777;
}
.pricebox_jfont p {
  width: 280px;
  display: block;
}

.jdont_bg_c_price_01 {
  background-color: #E7F6FE;
}

.jdont_bg_c_price_02 {
  background-color: #080404;
}

.jdont_bg_c_price_03 {
  background-color: #ECECEC;
}

.jdont_bg_c_price_04 {
  background-color: #FFF0E6;
}

.jfont_op {
  background-color: #F5F5F5;
  padding: 30px;
}
.jfont_op .navi_btn {
  display: block;
  width: 240px;
  height: 40px;
  background: #4d4d4d;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.jfont_op .navi_btn:hover {
  background-color: #818181;
}
.jfont_op .navi_btn i {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  font-size: 24px;
  color: #777;
}

.maar .maar_line {
  border: 1px solid #ddd;
}
.maar .price2 {
  font-size: 28px;
  display: block;
}
.maar .price2 span {
  font-size: 18px;
}
.maar .navi_btn {
  display: block;
  width: 240px;
  height: 40px;
  background: #4d4d4d;
  color: #fff;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.maar .navi_btn:hover {
  background-color: #818181;
}
.maar .navi_btn i {
  position: absolute;
  display: block;
  top: 0;
  right: 10px;
  font-size: 24px;
  color: #777;
}
.maar hr {
  margin: 2em 0;
}